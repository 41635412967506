import React from "react";

// Customizable Area Start
import {
    Button,
    Box,
    styled,
    TablePagination,
    AppBar,
    Tabs,
    Tab,
    Typography,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import AddCashUp from "./AddCashUp.web";
import { colors } from "../../utilities/src/Colors";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import FilterIcon from '@material-ui/icons/FilterListRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import {
    TableRow,
    TableCell,
    OrderTable,
    RowContainer,
} from "../../../components/src/OrderTable/src";
import IncomingPayments from "./IncomingPayments.web";
import FilterPopover from "../../../components/src/FilterPopover";
import { sortCondition, renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { handleMomentDateFormat } from "./utils";

const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
// Customizable Area End

// Customizable Area Start
import StoreKeeperController, { Props, configJSON, TransferRequest, EditField } from "./StoreKeeperController.web";
import { getPeriodDate } from "./utils";
// Customizable Area End

export default class StoreKeeper extends StoreKeeperController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
    storeTableHeaderRender() {
        const { sortingData } = this.state;
        return (
            <RowContainer header>
                <TableRow
                    className='table-cols'
                    columns={this.getTableRowColumns()}
                >
                    {[
                        { "label": "Stations", "sortingProp": "station" },
                        { "label": "Date", "sortingProp": "date" },
                        { "label": "Storekeeper", "sortingProp": "created_by.full_name" },
                        { "label": "Opening Cash Date", "sortingProp": "opening_cash_date" },
                        { "label": "Driver", "sortingProp": "driver" },
                        { "label": "Opening Cash", "sortingProp": "opening_cash" },
                        { "label": "Petty Cash", "sortingProp": "petty_cash" },
                        { "label": "Cash from Orders", "sortingProp": "cash_from_orders" },
                        { "label": "Cash In", "sortingProp": "cash_in" },
                        { "label": "Cash Out", "sortingProp": "cash_out" },
                        { "label": "Declared Cash", "sortingProp": "amount" },
                        { "label": "Variance", "sortingProp": "variance" }
                    ].map((data) => (
                            <TableCell
                                header
                                contentFontSize="13px"
                                sortingData={sortingData}
                                content={data.label}
                                className='align-item-center p5'
                                sortingIcons={[
                                    IconSortingNormal,
                                    IconSortingUp,
                                    IconSortingDown,
                                ]}
                                sortingKey={data.sortingProp}
                                sortingDisabled={false}
                                {...this.sortingProps}
                            />))}
                </TableRow>
            </RowContainer>
        )
    }

  renderStoreTableBody() {
    const { listData, permissionStatus, editID, isListingDataLoading } =
      this.state;
    if (isListingDataLoading) {
      return (
        <RowContainer>
          <Box style={{ textAlign: "center", height: "50px" }}>
            <CircularProgress />
          </Box>
        </RowContainer>
      );
    } else {
        return (
            <RowContainer>
                {this.state.listData.length === 0 && (
                    <TableRow
                        key={"NoDataTableRow"}
                        columns='1fr'
                        className='table-rows-norecord'
                    >
                        <TableCell
                            className='w-100'
                            type='none'
                            content={
                                <Box className='no_records'>
                                    {configJSON.stringsListPage_noRecordsFound}
                                </Box>
                            }
                        />
                    </TableRow>
                )}

                {listData.map((data) => (
                 <Box className='mainrow'>
                    <TableRow
                        key={data.id}
                        className='table-rows'
                        columns={this.getTableRowColumns()}
                    >
                        {[
                            data.attributes.station,
                            handleMomentDateFormat(data.attributes.created_at, "DD-MM-yyyy h:mm A"),
                            data.attributes.created_by.full_name,
                            handleMomentDateFormat(data.attributes.created_at, "DD-MM-yyyy h:mm A"),
                            data.attributes.driver?.full_name,
                                `${data.attributes.currency} ${data.attributes.opening_cash}`,
                                `${data.attributes.currency} ${data.attributes.petty_cash}`,
                                `${data.attributes.currency} ${data.attributes.cash_from_orders}`,
                                `${data.attributes.currency} ${data.attributes.cash_in}`,
                                `${data.attributes.currency} ${data.attributes.cash_out}`,
                            this.renderEditableInput(data,"amount"),
                            (
                                <Box>
                                    <Typography className="lable-14-400 colorRed">{`${data.attributes.currency} ${data.attributes.variance}`}</Typography>
                                    <Box className="varActionContainer">
                                        {<IconButton onClick={() => this.handleEditStoreKeeper(data)} data-test-id={`editDeclaredAmountBtn${data.id}`} className={`tableBtn p0 ${sortCondition(permissionStatus.CashReceivePermission as boolean && editID != data.id && data.attributes.status == "pending","visibleNone","visibleNone")}`}><EditOutlinedIcon /></IconButton>}
                                        {editID == data.id && <Button data-test-id="doneBtn" onClick={()=>this.editCashUp(data.id)} className="btnDone">Done</Button>}
                                        <IconButton className="tableBtn p0" data-test-id={"print-summary-" + data.id} onClick={() => this.handlePrintInvoice(data.attributes.invoice_pdf)}><PrintOutlinedIcon /></IconButton>
                                    </Box>
                                </Box>
                            )
                        ].map((value, index, array) => (<TableCell
                            className="p5"
                            style={{ background: sortCondition(data.attributes.status === "completed", "#f7f4e7", "") as string}}
                            contentFontSize="14px"
                            type={this.getType(index, array.length)}
                            content={value}
                            handleClick={() =>
                                this.handleRedirectDetailFromCashup(data.attributes, index)
                              }
                              dataid={data.id}
                        />))}
                    </TableRow>
                    </Box>
                ))}
            </RowContainer>
        )
    }
  }

    renderEditableInput = (data: TransferRequest, inputName: keyof EditField) => {
        const { editID, editedData } = this.state;
        if (editID != data.id) return `${data.attributes.currency} ${data.attributes[inputName]}`
        return (
            <input data-test-id="editInputDA" value={editedData[inputName]} onChange={this.handleChangeEditField} type="number" name={inputName} className="confAmountInput" />
        )
    }
  // Customizable Area End

  render() {
    // Customizable Area Start
      const { formFlag, selectedTab, page, pageSize, listMetaData, permissionStatus } = this.state
      return (
              <StyledWrapper>
                  <Box>
                      <StyledAppBar position='static' style={{ margin: "0" }}>
                          <Tabs
                              variant="standard"
                              data-test-id={configJSON.tabTypeID}
                              value={selectedTab}
                              onChange={this.handleTabSeletion}
                              className="typeTabs"
                          >
                              {["Cash up", "Incoming Payments"].map(
                                  (data) => {
                                      return (
                                          <CustomTypeTab
                                              label={data}
                                              value={data}
                                              key={data}
                                          />
                                      );
                                  }
                              )}
                          </Tabs>
                      </StyledAppBar>

                      {!formFlag && <Box className="headingContainer">
                          <Box>
                              <Typography gutterBottom className="lable-16-700">{selectedTab}</Typography>
                              <Typography className="lable-16-700">
                                  Period : <span className="lable-16-400">{getPeriodDate(
                                            this.handleDateFilterParam().startDateText,
                                            this.handleDateFilterParam().endDateText
                                        )}</span>
                              </Typography>
                          </Box>

                          <Box className="actionBtnContainer">
                              <div data-test-id="filterButton" onClick={(event) => { this.handleFilterOpen(event) }}>
                              <IconButton className={sortStringCondition(
                                  this.state.isAppliedFilter,
                                  "filterBtn active-border",
                                  "filterBtn"
                              )}>
                                  <FilterIcon color={this.handleReturnColorType()} />
                                  {renderBaseOnConditions(
                                      this.state.isAppliedFilter,
                                      <div className="filterActiveIcon"></div>,
                                      <></>
                                  )}
                              </IconButton>
                              </div>
                              <Button data-test-id="exportAllBtn" onClick={this.exportAll} className="exportAllBtn" variant="contained">Export all</Button>
                              {selectedTab == "Cash up" && permissionStatus.createPermission &&<Button data-test-id="cashupBtn" onClick={this.handleAddCashUpFlag} className="cashUpBtn" variant="contained" startIcon={<AddRoundedIcon />}>Cash up</Button>}
                          </Box>
                        
                          <FilterPopover
                              data-test-id="filterPopover"
                              onClose={() => { this.handleCloseFilterPopover() }}
                              anchor={this.state.filterAnchor}
                              onFilterChange={this.handleFilterChangeAccount}
                              filters={this.state.filters}
                          />

                      </Box>}

                      {selectedTab == "Cash up" &&
                          <>
                              {!formFlag && (<>

                                  <Box className="tableContainer">
                                      <OrderTable minWidth={1280} minWidth1024={887} minWidth1280={1109} minWidth1366={1184}>
                                          {this.storeTableHeaderRender()}
                                          {this.renderStoreTableBody()}
                                      </OrderTable>
                                  </Box>
                                  {(
                                  <TablePagination
                                      data-test-id='pagination'
                                      rowsPerPageOptions={[10, 20, 30]}
                                      component='div'
                                      count={listMetaData.total_count}
                                      rowsPerPage={pageSize}
                                      page={page - 1}
                                      onRowsPerPageChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                          this.handleTableRowsPerPage(event)
                                      }}
                                      onPageChange={(event, page) => {
                                          this.handlePageChange(page + 1);
                                      }}
                                  />
                                  )}
                              </>)}

                              {formFlag && <AddCashUp navigation={undefined} id={"AddCashUp"} handleCancel={this.handleAddCashUpFlag} userName={this.state.userName} />}
                          </>
                      }

                      {selectedTab == "Incoming Payments" &&
                        <IncomingPayments permissionStatus={permissionStatus} navigation={this.props.navigation} id={""} listType="incoming" searchFilter={this.state.listData} />
                      }

                  </Box>
              </StyledWrapper>
      );
    // Customizable Area End
  }
}

// Customizable Area Start
export const StyledWrapper = styled(Box)({
    fontFamily: "Montserrat",
    "& .no_records": {
        width: "100%",
        fontSize: 16,
        fontWeight: 700,
        textAlign: "center",
    },
    "& .table-rows-norecord > div": {
        fontSize: "12px",
        textAlign: "left",
        minHeight: "50px",
        textTransform: "capitalize",
        fontWeight: "500 !important",
    },
    "& .w-100": {
        width: "100%",
    },
    "& .visibleNone":{
        visibility: "hidden"
    },
    "& .typeTabs": {
        width: "488px",
        borderBottom: '1px solid #ECECEC',
        "& .MuiTabs-indicator": {
            "@media only screen and (max-width: 1024px)": {
                height: "1px"
            }
        },
        "@media only screen and (max-width: 1024px)": {
            minHeight: "0"
        }
    },
    "& .headingContainer": {
        marginTop: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    "& .lable-16-700": {
        fontSize: "16px",
        color: colors().darkjunglegreen,
        fontFamily: "Montserrat",
        fontWeight: 700
    },
    "& .lable-16-400": {
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 400,
        color: colors().darkjunglegreen
    },
    "& .lable-14-400": {
        fontSize: "14px",
        fontFamily: "Montserrat",
        fontWeight: 400
    },
    "& .actionBtnContainer": {
        display: "flex",
        gap: "24px"
    },
    "& .cashUpBtn": {
        boxShadow: "none",
        backgroundColor: "#204B9C",
        textTransform: "none",
        height: "44px",
        fontSize: "16px",
        fontWeight: 700,
        borderRadius: "8px",
        color: "#fff",
        "& .MuiButton-iconSizeMedium > :first-child": {
            fontSize: "24px"
        }
    },
    "& .tableBtn": {
        boxShadow: "none",
        background: "rgba(32, 75, 156, 0.1)",
        width: "32px",
        height: "32px",
        borderRadius: "8px",
        "& .MuiSvgIcon-root": {
            color: "#4D4D4D"
        }
    },
    "& .exportAllBtn": {
        boxShadow: "none",
        backgroundColor: "#E2E8F0",
        textTransform: "none",
        height: "44px",
        fontSize: "16px",
        fontWeight: 600,
        borderRadius: "8px",
        color: "#4D4D4D"
    },
    "& .filterBtn": {
        position: "relative",
        height: "44px",
        borderRadius: "8px",
        backgroundColor: "#fff",
        border: "1px solid #ECECEC",
        "& .MuiSvgIcon-root": {
            color: "black"
        },
        "&.active-border": {
            border: "1px solid #204B9C",
        },
        "& .filterActiveIcon": {
            position: "absolute",
            top: "-5px",
            right: "-5px",
            minWidth: "15px",
            background: "#204B9C",
            minHeight: "15px",
            borderRadius: "50%"
        }
    },
    "& .align-item-center": {
        alignItems: "center",
    },
    "& .tableContainer": {
        marginTop: "20px"
    },
    "& .varActionContainer": {
        marginTop: "10px",
        display: "flex",
        gap: "10px"
    },
    "& .confAmountInput": {
        border: "1px solid #ECECEC",
        width: "100%",
        height: "32px",
        padding: "10px 8px",
        boxSizing: "border-box",
        borderRadius: "8px",
        fontSize: "14px",
        fontFamily: "Montserrat",
        fontWeight: 500,
        "-moz-appearance": "textfield",
        '&:focus': {
            outline: "none"
        }
    },
    "& .p5": {
        padding: "5px"
    },
    "& .colorRed":{
        color:"red"
    },
    "& .btnDone":{
        boxShadow: "none",
        borderRadius:"8px",
        background: "rgba(32, 75, 156, 0.1)",
        color:"#204B9C",
        fontSize: "14px",
        fontFamily: "Montserrat",
        fontWeight: 700,
        textTransform:"none",
        width:"60px",
        height:"32px",
    },
    "& .p0": {
        padding: "0"
    },
    "& .mainrow": {
        cursor: "pointer"
    }
})

const StyledAppBar = styled(AppBar)({
    color: colors().darkliver,
    marginTop: "10px",
    backgroundColor: colors().white,
    borderBottom: `1px solid ${colors().lightborder}`,
    boxShadow: "none",
    "& .Mui-selected": {
        color: colors().cyancobaltblue,
    },
    "& .MuiTabs-indicator": {
        backgroundColor: `${colors().cyancobaltblue} !important`,
    },
    "& .MuiPaper-elevation": {
        boxShadow: "none",
    }
});

const CustomTypeTab = styled(Tab)({
    textTransform: "none",
    fontFamily: "Montserrat",
    fontSize: "16px",
    minWidth: "0",
    "@media only screen and (max-width: 1024px)": {
        fontSize: "14px",
        minHeight: "0px"
    }
});
// Customizable Area End
