Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.contentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getMethod = "GET";
exports.putMethod = "PUT";
exports.postMethod = "POST";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LoyaltySystem2";
exports.labelBodyText = "LoyaltySystem2 Body";
exports.number = "Number";
exports.section = "Section";
exports.expiryDate = "Expiry Date";
exports.status = "Status";
exports.loyaltyList = "Loyalty Schema";
exports.createLoyalty = "Create Loyalty Schema";
exports.addLoyalty = "Add Loyalty Schema";
exports.editLoyalty = "Edit Loyalty Schema";
exports.loyaltyListApi = "bx_block_loyaltysystem/rewards";
exports.expiryListApi = "bx_block_loyaltysystem/reward_expiries";
exports.tokenKey = "token";
exports.cancel = "Cancel";
exports.submit = "Submit";
exports.active = "Active";
exports.testing = "testing";
exports.backToListing = "Back To Listing";
exports.section = "Section";
exports.spendAmount = "Spend Amount";
exports.creditAmount = "Credit Amount";
exports.earnedPoint = "Earned Point";
exports.usedPoint = "Used Point";
exports.minimumPointsToRedeem = "Minimum Points To Redeem";
exports.deactivated = "Deactivated";
exports.warningMessage =
  "Automatic enroll your customers in a loyalty points scheme. They build up points in accordance with their spending history, which can then be converted into customer credit at a future date.";
exports.sectionListApi = "bx_block_categories/categories?dropdown=true";
exports.loyaltySystemFilterStorageKey = "loyalty_system_filter_value"

exports.placeHolderTextSection = "Select Section";
exports.emptySectionDataMessage = "No Section Found";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
