import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Paper,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  styled,
  Snackbar,
  CircularProgress
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { isArray } from "lodash";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalendarTodayRounded }  from '@material-ui/icons';
import Alert from "@material-ui/lab/Alert";
import { colors } from "../../../blocks/utilities/src/Colors";
// Customizable Area End

import SalesReportingController, {
  Props,
  configJSON,
  // Customizable Area Start
  ReportItem,
  SalesAndRevenueReport,
  DropdownType,
  RevenueReport
  // Customizable Area End
} from "./SalesReportingController";

export default class SalesReporting extends SalesReportingController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderTaskbar(){
    const { reportType } = this.state;
    return (
      <StyledWrapper>
          <Box sx={webStyle.taskbar}>
        <div className="reportSelectContainer">
          <AutocompleteSelect
            data-test-id='reportDropdown'
            placeholder="Select Report"
            fieldName="report"
            inputStyle={webStyle.autoCompleteInputStyle}
            options={this.state.reportList}
            value={reportType?.id || ''}
            disableClear={true}
            listBoxStyle={{ maxHeight: 310 }}
            onChange={(_blank: React.ChangeEvent<HTMLInputElement>, value: DropdownType | null) => {
              this.handleReportChange(value)
            }}
          />
        </div>
        <Box sx={webStyle.headerDropdown}>
          <Box sx={webStyle.datePicker}>
            <DatePicker
              showIcon
              dateFormat="dd/MM/yyyy"
              data-test-id="fromDate"
              toggleCalendarOnIconClick
              placeholderText={configJSON.fromDataPlaceholder}
              maxDate={new Date()}
              selected={this.state.selectedFromDate ? new Date(this.state.selectedFromDate) : null}
              onChange={(date) => this.handleFromDateInput(date)}
              icon={<CalendarTodayRounded/>}
            />
          </Box>
          <Box sx={webStyle.datePicker}>
            <DatePicker
              showIcon
              data-test-id="toDate"
              dateFormat="dd/MM/yyyy"
              toggleCalendarOnIconClick
              placeholderText={configJSON.toDatePlaceholder}
              minDate={new Date(this.state.selectedFromDate)}
              maxDate={new Date()}
              selected={this.state.selectedToDate ? new Date(this.state.selectedToDate) : null}
              onChange={(date) => this.handleToDateInput(date)}
              icon={<CalendarTodayRounded/>}
            />
          </Box>
          <Button
            data-test-id="PageHeaderButton"
            disabled={!this.validateFilters()}
            onClick={this.handleExport}
            style={webStyle.buttonStyle}
          >
            {
              this.state.showLoading ?
                (
                  <CircularProgress
                    disableShrink
                    style={webStyle.loader}
                    size={20}
                    thickness={4}
                    value={100}
                  />
                ) : configJSON.exportText
            }

          </Button>
        </Box>
      </Box>
      </StyledWrapper>
    )
  }

  renderFields(fields: any){
    return (
      <Paper>
      <Box sx={webStyle.checkBoxFieldWrapper}>
      <FormGroup>
        <FormControlLabel control={<Checkbox checked={this.getAllTheCheckedColumns()?.length === this.state.filterFields.length} onChange={(e) => this.updateCheckbox(fields, null, true, 'simpleFilter')} />} label={configJSON.selectAll} />
        <Grid
          container
          spacing={2}
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {fields.map((elem: ReportItem) => (
            <Grid item xs={12} sm={6} md={3} key={elem.id}>
              <FormControlLabel control={<Checkbox checked={elem.checked} onChange={(e) => this.updateCheckbox(fields, elem, false, 'simpleFilter')} />} label={this.capitalizeFirstCharacter(elem.label)} />
            </Grid>
          ))}
        </Grid>
      </FormGroup>
    </Box>
    </Paper>
    )
  }

  renderRevenueFields(filterField: RevenueReport) {
    return (
      <Paper>
        <Table style={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}><FormControlLabel control={<Checkbox checked={this.checkIfAllFieldsChecked()} onChange={(event) => { this.selectAllRevenueFields(filterField, event.target.checked, false)}} />} label={configJSON.selectAll} /></TableCell>
            </TableRow>
            {
              Object.keys(filterField).map((key) => {
                if(key !== 'total_for_each_column') {
                  return (
                    <TableRow
                    key={key}
                  >
                    <TableCell component="th" scope="row">
                      <Typography style={{ wordWrap: 'break-word', width: '8rem' }}><strong>{key === 'other' ? '' : this.capitalizeFirstCharacter(key)}</strong></Typography>
                    </TableCell>
                    <TableCell align="left">
                      <FormGroup>
                        <Grid
                          container
                          spacing={2}
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
  
                          {
                            (filterField[key] as ReportItem[]).map((field: ReportItem, index: number) => (
                              <Grid item xs={4} key={index}>
                                <FormControlLabel control={<Checkbox data-test-d={`option-${index}-${key}`} checked={field.checked} onChange={() => this.handleFilter(field, key, '')} />} label={this.capitalizeFirstCharacter(field.label)} />
                              </Grid>
                            ))
                          }
  
                        </Grid>
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                  )
                } else {
                  return (
                    <TableRow>
                        <TableCell colSpan={2}><FormControlLabel control={<Checkbox  checked={(filterField[key] as boolean)} onChange={() => this.handleFilter(filterField[key], key, '')}/>} label="Total for each Column" /></TableCell>
                    </TableRow>
                  )
                }
              })
            }
          </TableBody>
        </Table>
      </Paper>
    )
  }
  renderFieldsCategoriesWise() {
    const { reportType, filterFields } = this.state;
    return(
      <Box>
        { configJSON.revenueFields.indexOf(reportType ? reportType.option : '') !== -1 && this.renderRevenueFields(filterFields as SalesAndRevenueReport)}
        { configJSON.directFields.indexOf(reportType ? reportType.option : '') !== -1 && this.renderFields(filterFields)}
    </Box>
    )
  }
  getMultiSelectRenderValue(items: unknown) {
    let updatedLabels = this.handleUpdatedLabels(items);
    updatedLabels = updatedLabels || configJSON.stringsListPage_select_store
    return isArray(items) && items.length === this.state.storeList.length ? (
      <span className='color_4D4D4D'>
        {configJSON.stringsListPage_all_stores}
      </span>
    ) : (
      updatedLabels
    );
  }
 
  // Customizable Area End

  render() {
    const {selectedGroup, selectedRegion, selectedFromDate, selectedToDate, reportType, selectedStore, storeList} = this.state
    return (
      // Customizable Area Start
      <>    
              <Box>
                {this.renderTaskbar()}
                <Box sx={webStyle.contentWrapper}>
                <Box sx={{...webStyle.taskbar, marginRight: 50}}>
                  <Box sx={{ maxWidth: 365, minWidth: 365 }}>
                    <Typography data-test-id="report-text"><strong>Report Name :</strong> {reportType?.option}</Typography>
                    <Typography><strong>Period : </strong>{`${this.formatDate(selectedFromDate)} _ ${this.formatDate(selectedToDate)}`}</Typography>
                  </Box>
                    <Box sx={webStyle.regionStoreDiv}>
                      <Typography><strong>Region :</strong> {'Selected Regions'}({selectedRegion.length})</Typography>
                      <Typography><strong>Stores :</strong>{`${selectedStore.length === storeList.length ? ' All Stores' : ' Selected Stores'}(${selectedStore.length})`}</Typography>
                      <Typography><strong>Groups :</strong>{'Selected Groups'}({selectedGroup.length})</Typography>
                    </Box>
                </Box>
                  <Box>
                    <Button
                      data-test-id="show-options"
                      disabled={!this.state.reportType}
                      onClick={this.handleShowFilter}
                      style={{...webStyle.buttonStyle, ...webStyle.customizeButtonStyle}}
                    >
                      {configJSON.customizeText}
                    </Button>
                  </Box>
                {
                  this.state.showFilterOptions && this.renderFieldsCategoriesWise()
                }
                </Box>
              </Box>
          
        <Snackbar
          anchorOrigin={{
            vertical: configJSON.top,
            horizontal: configJSON.center,
          }}
          autoHideDuration={2000}
          open={this.state.message.name !== ''}
          data-test-id="snack-bar"
          onClose={this.handleCloseSnackbar}
        >
          <Alert severity={this.state.message?.type}>{this.state.message?.name}</Alert>
        </Snackbar>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled("div")({
  "& .bordered-select": {
    minWidth: "260px",
    margin: "0 12px",
  },

  "& .reportSelectContainer": {
    margin: "0 12px",
    width: "28%",
    "@media only screen and (max-width: 1024px)": {
      minWidth:"32%",
     },
     "@media only screen and (max-width: 1280px)": {
      minWidth:"40%",
     },
     "@media only screen and (max-width: 1366px)": {
      minWidth:"40%",
     }
  }
})

const webStyle: any = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: colors().white,
  },
  datePicker: {
    marginRight: 20,
    '& .react-datepicker__calendar-icon': {
      right: 0,
      padding: '0.4rem',
      fontSize: 18
    },
    '& .react-datepicker__view-calendar-icon input': {
      padding: '10px 12px 10px 12px',
      width: '132px',
      borderRadius: '6px',
      border: `1px solid ${colors().lightborder}`,
      height: '37px'
    }
  },
  headerDropdown: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 2
  },
  regionStoreDiv : {
    marginLeft: '150px'
  },
  taskbar: {
    display: 'flex',
    paddingTop: 2
  },
  contentWrapper: {
    marginTop: '10px',
    marginLeft: '12px',
    lineheight: 18
  },
  checkBoxFieldWrapper: {
    padding: '10px'
  },
  branchDropdown: {
    minWidth: 200,
    margin: "0 12px",
  },
  autoCompleteInputStyle: {
    padding: '0px 10px 0px 6px',
    width: 'auto',
    maxWidth: '350px'
  },
  buttonStyle: {
    height: "37px",
    padding: '6px 10px 6px 10px',
    fontWeight: 'bolder',
    border: "none",
    borderRadius: '6px',
    textTransform: 'none',
    backgroundColor: colors().lightgray,
  },
  customizeButtonStyle: {
    color: colors().cyancobaltblue,
    fontWeight: 600,
    marginBottom: 16,
    marginTop: 16
  },
  loader: {
    color: colors().cyancobaltblue,
    animationDuration: '550ms',
  }
};
// Customizable Area End
