import React from "react";
// Customizable Area Start

import {
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TablePagination,
  TableCell,
  TableBody,
  Table,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Snackbar,
  styled
} from "@material-ui/core";
import FilterIcon from '@material-ui/icons/FilterListRounded';
import MoreVertIcon from "@material-ui/icons/MoreVert";

import LoyaltyListController, { Props } from "./LoyaltyListController.web";
import { FilterBox } from "../../../components/src/customComponents/CustomTags.web"
import TablePopover from "../../../components/src/TablePopover.web";
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCell from "../../../components/src/SortingTableCell";
import PageHeader from "../../../components/src/PageHeader";
import FilterPopover from "../../../components/src/FilterPopover";
import { configJSON } from "./AddLoyaltyController.web";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
import Alert from "@material-ui/lab/Alert";
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";
import { renderBaseOnConditions } from "../../utilities/src/CustomBlockHelpers";
import { colors } from "../../utilities/src/Colors"
// Customizable Area End

export default class LoyaltyList extends LoyaltyListController {
  constructor(props: Props) {
    super(props);
  }

  renderDisableDialog() {
    return (
      <Dialog
        open={!!this.state.disableDialogVisible}
        onClose={this.handleDisableDialogClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <h2 style={{fontSize:'24px',padding:'20px',fontWeight:600}} id='alert-dialog-title'>
          All Sections will be disabled
        </h2>
        <DialogContent>
          <p style={{ fontSize: '18px'}} id='alert-dialog-description'>
            Are you sure you want to disable all sections?
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              textTransform: "none",
            }}
            onClick={this.handleDisableDialogClose}
          >
            Cancel
          </Button>
          <Button
            style={{
              backgroundColor: "#204B9C",
              color: "white",
              textTransform: "none",
            }}
            onClick={this.handleDisableDialogConfirm}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  // Customizable Area Start
  render() {
    const rows: {
      selid: string;
      number: string;
      status: string;
      section: string;
      expiryDate: string;
    }[] = this.state.loyalties.map((loyalty) => {
      const isSelectAll = loyalty.attributes.all_section_enabled;
      const number = loyalty.id;
      const section = isSelectAll
        ? "All Sections"
        : loyalty.attributes.sections.map((section) => section.name).join(", ");
      const expiryDate = loyalty.attributes.expiry_date;
      const status = loyalty.attributes.active ? "Active" : "Deactivated";
      const id = number;

      return this.createData(number, section, expiryDate, status, id);
    });

    const filteredRowsLoyalty = rows.slice(
      this.state.page * this.state.pageSize,
      (this.state.page + 1) * this.state.pageSize
    );

    const emptyRowsLoyalty = this.state.pageSize - filteredRowsLoyalty.length;
    const sortingData = this.state.sortingData;

    return (
      <>
        <Box style={webStyle.mainWrapperLoyalty}>
          <>
            <Box>
            <Box>
              <PageHeader
                isLoadingPermission={this.state.isLoadingPermission}
                data-test-id='loyaltyListHeader'
                header={configJSON.loyaltyList}
                buttonText={configJSON.addLoyalty}
                onButtonClick={this.handleAddLoyalty}
                permissionStatus={this.state.permissionStatus.createPermission}
                buttonStyle={{ width: 200 }}
                filterComponent={
                  <div style={{ display: "flex" }}>
                    <FilterBox
                      data-test-id='filterBtn'
                      onClick={(event: { currentTarget: {} }) =>
                        this.handleFilterPopverOpen(event)
                      }
                      style={webStyle.filterButtonLoyalty}
                    >
                      <FilterIcon color={this.handleReturnColorType()}/>
                      {renderBaseOnConditions(
                        this.state.isFilterApplied,
                        <StyledActiveDot data-test-id="filter-active-dot"/>,
                        <></>
                      )}
                    </FilterBox>
                    <FilterPopover
                      anchor={this.state.filterAnchor as HTMLDivElement | undefined}
                      onClose={() => {
                        this.setState({ filterAnchor: undefined });
                      }}
                      filters={this.state.filters}
                      onFilterChange={this.handleFilterChange}
                    />
                  </div>
                }
              />
              <TableContainer component={Paper}>
                <Table
                  style={webStyle.tableLoyalty}
                  aria-label='customized table'
                >
                  <TableHead>
                    <TableRow
                      style={{ background: "#204B9C", color: "#FFFFFF" }}
                    >
                      <SortingTableHeader
                        sortingData={sortingData}
                        title={configJSON.number}
                        sortingKey='number'
                        {...this.sortingProps}
                        type='left'
                      />
                      <SortingTableHeader
                        sortingData={sortingData}
                        title={configJSON.section}
                        sortingKey='sections'
                        {...this.sortingProps}
                      />
                      <SortingTableHeader
                        sortingData={sortingData}
                        title={configJSON.expiryDate}
                        sortingKey='expiry_date'
                        {...this.sortingProps}
                      />
                      <SortingTableHeader
                        sortingData={sortingData}
                        title={configJSON.status}
                        sortingKey=''
                        {...this.sortingProps}
                        width='10%'
                        sortingDisabled
                      />
                      <SortingTableHeader
                        sortingData={sortingData}
                        title=''
                        sortingKey=''
                        {...this.sortingProps}
                        type='action'
                        sortingDisabled
                      />
                    </TableRow>
                  </TableHead>
                  {filteredRowsLoyalty.length > 0 && (
                    <TableBody>
                      {filteredRowsLoyalty.map((rowData, index) => (
                        <TableRow key={index + "TableRow"}>
                          <SortingTableCell
                            width='25vw'
                            type='left'
                            content={rowData.number}
                          />
                          <SortingTableCell
                            width='25vw'
                            content={rowData.section}
                          />
                          <SortingTableCell
                            width='25vw'
                            content={rowData.expiryDate}
                          />
                          <SortingTableCell
                            data-test-id='customswitchCell'
                            width='10vw'
                            type='right'
                            style={{
                              marginTop: 0,
                              marginBottom: 0,
                              paddingTop: 8,
                              paddingBottom: 7,
                            }}
                            content={
                              <CustomSwitch
                                data-test-id='field-status-switch'
                                checked={rowData.status === "Active"}
                                onChange={(checked) => {
                                  this.handleStatusChange(rowData.selid, checked);
                                }}
                              />
                            }
                          />

                          <SortingTableCell
                            width='15vw'
                            type='action'
                            data-test-id='moreCell'
                            content={
                              <IconButton
                                data-test-id='moreButton'
                                id={"IconButton" + index}
                                onClick={(event) => {
                                  const position =
                                    event.currentTarget.getBoundingClientRect();
                                  this.setState({
                                    popOverOpened: true,
                                    popOverItemId: rowData.selid,
                                    popOverItemStatus: rowData.status,
                                    popOverLeft: position.left + window.scrollX,
                                    popOverTop: position.top + window.scrollY,
                                  });
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                            }
                          />
                        </TableRow>
                      ))}
                      {emptyRowsLoyalty > 0 && (
                        <TableRow style={{ height: 53 * emptyRowsLoyalty }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                    {this.state.isLoading &&
                      <>{Array(5)
                        .fill(1)
                        .map((_, index) => (
                          <TableBody key={index} data-test-id='skeletonTable'>
                            <TableRow>
                              {[...Array(4)].map((_, cellIndex) => (
                                <TableRowsSkeleton key={cellIndex} />
                              ))}
                            </TableRow>
                          </TableBody>
                        ))}</>
                    }
                </Table>
                {filteredRowsLoyalty.length === 0 && (
                  <div
                    style={{ ...webStyle.noRecordsFound, textAlign: "center" }}
                  >
                    No records found!!
                  </div>
                )}
                <TablePopover
                  data-test-id={"popover"}
                  permissionStatus={this.state.permissionStatus}
                  open={this.state.popOverOpened}
                  onClose={() => this.setState({ popOverOpened: false })}
                  top={this.state.popOverTop}
                  left={this.state.popOverLeft}
                  onView={() =>
                    this.handleViewLoyalty(this.state.popOverItemId)
                  }
                  onEdit={() =>
                    this.handleEditLoyalty(this.state.popOverItemId)
                  }
                  deactivateTitle={configJSON.addLoyaltyHeader}
                  isActive={
                    this.state.popOverItemStatus === "Active" ? true : false
                  }
                />
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10]}
                data-test-id='pagination'
                component='div'
                rowsPerPage={this.state.pageSize}
                onPageChange={this.handlePageChange}
                count={rows.length}
                page={this.state.page}
              />
            </Box>
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              data-test-id='snackbar'
              open={!!this.state.snackBarMessage}
              autoHideDuration={3000}
              onClose={this.closeSnackBar}
            >
            <Alert severity={this.state.snackBarMessageType}>{this.state.snackBarMessage}</Alert>
          </Snackbar>
            </Box>
            {this.renderDisableDialog()}
          
          </>
        </Box>
      </>
    );
  }
}

const webStyle = {
  tableLoyalty: {
    minWidth: 700,
  },
  mainWrapperLoyalty: {
    marginTop: "32px",
    fontFamily: "Montserrat",
  },
  pageHeaderLoyalty: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "29px",
  },
  headerWrapper: {
    justifyContent: "space-between",
    display: "flex",
    marginBottom: 32,
    alignItems: "center",
  },
  addButtonLoyalty: {
    background: "#204B9C",
    fontWeight: 600,
    fontSize: "16px",
    color: "#FFFFFF",
    lineHeight: "24px",
    height: "56px",
    width: "184px",
    borderRadius: "8px",
  },
  filterButtonLoyalty: {
    cursor: "pointer",
    padding: "16px",
    border: "solid 1px rgba(32, 75, 156, 1)",
    borderRadius: "15px",
  },
  contentWrapper: {
    marginTop: 56,
    marginRight: 32,
  },
  noRecordsFound: {
    fontSize: 16,
    fontWeight: 600,
    width: "100%",
  },
};

const StyledActiveDot = styled("div")({
  position: "absolute",
    background: colors().cyancobaltblue,
    top: "-5px",
    right: "-5px",
    minWidth: "15px",
    minHeight: "15px",
    borderRadius: "50%"
})

// Customizable Area End
