import React from "react";

// Customizable Area Start
import { Box, styled, TablePagination, Button } from "@material-ui/core";
import OutGoingTransactionController, {
  Props,
} from "./OutGoingTransactionController.web";
import { colors } from "../../utilities/src/Colors";
import { IFilter } from "../../../components/src/FilterPopover";
import {
  TableRow,
  TableCell,
  OrderTable,
  RowContainer,
} from "../../../components/src/OrderTable/src";
const configJSON = require("./config");
const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
import { editIcon } from "./assets";
const Strings = configJSON.OutGoingTransaction.Strings;
import CustomCashierTab from "../../../components/src/commonComponents/CustomCashierTab";
import CashierHeader from "../../../components/src/CashierHeader";
import { renderBaseOnConditions, sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { getPeriodDate, handleMomentDateFormat } from "./utils";
// Customizable Area End

class OutGoingTransaction extends OutGoingTransactionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  getRenderEditInput = () => {
    return (
      <Box display={"flex"} className='editbox'>
        <input
          value={this.state.editAmt}
          id='confAmountInput'
          className='confAmountInput'
          onChange={this.handleAmountChange}
          data-test-id='confirm-amount-input'
        />
        <Button
          id='confAmountSubmitBtn'
          className='confAmountSubmitBtn lable-16-700'
          onClick={this.handleUpdateConfirmAmt}
          data-test-id='confirm-amount-submit'
        >
          {configJSON.btnSubmit}
        </Button>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { permissionStatus } = this.state
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <StyledWrapper>
          <Box>
            <CustomCashierTab
              id={configJSON.outgoingId}
              value={this.state.selectedTab}
              handleTabSeletion={this.handleTabSeletion}
              tabList={[configJSON.Tabs.Strings.overview,configJSON.Tabs.Strings.incomingTransaction, configJSON.Tabs.Strings.outgoingTransaction]}
              data-test-id='outgoing-transaction-tab'
            />
          </Box>
          <MainBodyBox>
            <CashierHeader
              isAppliedFilter={this.state.isAppliedFilter}
              title='Outgoing transaction'
              handleFilterButton={this.handleFilterButton}
              handleCloseFilterButton={this.handleCloseFilterButton}
              handleFilterChange={this.handleFilterChange}
              handleNavigateTransfterAmt={this.handleNavigateTransfterAmt}
              filterAnchor={
                this.state.filterAnchor as HTMLDivElement | undefined
              }
              filters={this.state.filters as IFilter[]}
              isTransferAmount={this.state.permissionStatus.createPermission}
              periodDateRange={getPeriodDate(
                this.handleDateFilterParam().startDateText,
                this.handleDateFilterParam().endDateText
              )}
              handleExport={this.exportAll}
            />

            <OrderTable
              minWidth={1280}
              minWidth1024={887}
              minWidth1280={1109}
              minWidth1366={1184}
            >
              <RowContainer header>
                <TableRow className='table-cols' columns={"repeat(9, 1fr)"}>
                  {[
                    {
                      title: Strings.transactionID,
                      sortingKey: "transfer_id",
                    },
                    {
                      title: Strings.store,
                      sortingKey: "to_store",
                    },
                    {
                      title: Strings.date,
                      sortingKey: "date",
                    },
                    {
                      title: Strings.from,
                      sortingKey: "from",
                    },
                    {
                      title: Strings.driver,
                      sortingKey: "driver",
                    },
                    {
                      title: Strings.amount,
                      sortingKey: "amount",
                    },
                    {
                      title: Strings.reasons,
                      sortingKey: "reason",
                    },
                    {
                      title: Strings.variance,
                      sortingKey: "variance",
                    },
                    {
                      title: Strings.status,
                      sortingKey: "status",
                    },
                  ].map((item: { title: string; sortingKey: string }) => {
                    return (
                      <TableCell
                        header
                        key={item.title}
                        content={item.title}
                        className='align-item-center'
                        sortingIcons={[
                          IconSortingNormal,
                          IconSortingUp,
                          IconSortingDown,
                        ]}
                        sortingKey={item.sortingKey}
                        sortingDisabled={!Boolean(item.sortingKey)}
                        {...this.getSortingProps()}
                      />
                    );
                  })}
                </TableRow>
              </RowContainer>
              <RowContainer>
                {this.state.transactionList.length === 0 && (
                  <TableRow
                    key={"NoDataTableRow"}
                    className='table-rows-norecord'
                    columns='1fr'
                  >
                    <TableCell
                      type='none'
                      className='w-100'
                      content={
                        <Box className='no_records'>
                          {configJSON.stringsListPage_noRecordsFound}
                        </Box>
                      }
                    />
                  </TableRow>
                )}
                {this.state.transactionList.map((item, index) => {
                  const {
                    transfer_id,
                    transfer_to,
                    transfer_from,
                    driver,
                    amount,
                    status,
                    created_at,
                    reason,
                    variance_amount
                  } = item?.attributes || {};
                  return (
                    <Box className='mainrow'>
                      <TableRow
                        className='table-rows'
                        columns={"repeat(9, 1fr)"}
                      >
                        {[
                          <StyleContent>{transfer_id}</StyleContent>,
                          <StyleContent>
                            {transfer_to?.store_name}
                          </StyleContent>,
                          <StyleContent>
                            {handleMomentDateFormat(
                              created_at,
                              "DD-MM-yyyy h:mm A"
                            )}
                          </StyleContent>,
                          <StyleContent>
                            {transfer_from.full_name}
                          </StyleContent>,
                          <StyleContent>{driver?.full_name}</StyleContent>,
                          <StyleContent>SAR {amount}</StyleContent>,
                          <StyleContent>{reason}</StyleContent>,
                          <StyleContentVariance>
                            {`SAR ${variance_amount}`}
                          </StyleContentVariance>,
                          <StyleContent>{status}</StyleContent>,
                        ].map((heading: unknown, index: number) => (
                          <TableCell
                            content={
                              (
                                <>
                                  {heading}
                                  {index === 8 && (
                                    <div>
                                      {status === "pending" && permissionStatus.CashReceivePermission  && renderBaseOnConditions(
                                        this.state.isOpenAmtInput &&
                                          this.state.editAmtId === item.id,
                                        this.getRenderEditInput(),
                                        <img
                                          alt={"edit"}
                                          src={editIcon}
                                          className='edit-icon'
                                          data-test-id={`btn-edit-${item.id}`}
                                          onClick={() =>
                                            this.handleOpenAmtInput(item)
                                          }
                                        />
                                      )}
                                    </div>
                                  )}
                                </>
                              ) as React.ReactNode
                            }
                            style={{ background: sortCondition(status === "completed", "#f7f4e7", "") as string}}
                            type={this.getTableCellType(index)}
                            handleClick={() =>
                              this.handleRedirectDetail(item, index)
                            }
                          />
                        ))}
                      </TableRow>
                    </Box>
                  );
                })}
              </RowContainer>
            </OrderTable>
            {this.state.transactionList?.length > 0 && (
              <TablePagination
                count={this.state.totalCount}
                className='tablePagination'
                page={this.state.page - 1}
                data-test-id='pagination'
                rowsPerPageOptions={[10]}
                rowsPerPage={this.state.pageSize}
                component='div'
                onPageChange={(
                  _event: React.MouseEvent<HTMLElement> | null,
                  page: number
                ) => this.handlePageChange(page)}
              />
            )}
          </MainBodyBox>
        </StyledWrapper>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const StyledWrapper = styled("div")({
  "& .declaredAmtCont":{
    display:"flex",
    gap:"10px",
    flexDirection:"column",
    justifyContent:"space-between",
    height:"100%",
    width:"100%"
  },
  "& .confirmBtn":{
    width:"fit-content",
    background: colors().cyancobaltblue,
    border:"none",
    outline:"none",
    color: colors().background,
    borderRadius:"8px",
    padding: "3px 6px",
    fontSize:"13px",
    fontFamily: "Montserrat"
  },
  "& .tableContainer": {
    flex: "1 1 0",
  },
  "& .headerWrapper": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 12,
    alignItems: "center",
  },
  "& .PageHeading": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().darkjunglegreen,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    },
  },
  "& .datePeriod": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .d-flex": {
    display: "flex",
  },
  "& .filterButtonAccount": {
    position: "relative",
    marginRight: "24px",
    cursor: "pointer",
    background: colors().white,
    height: "44px",
    color: colors().darkliver,
    width: "44px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
    borderColor: colors().lightborder,
    "@media only screen and (max-width: 1024px)": {
      height: 44,
      width: 44,
    },
    "&.active-border": {
      border: "1px solid #204B9C",
    },
    "& .filterActiveIcon": {
      position: "absolute",
      top: "-5px",
      right: "-5px",
      minWidth: "15px",
      background: "#204B9C",
      minHeight: "15px",
      borderRadius: "50%"
    }
  },
  "& .addButton": {
    textTransform: "unset",
    fontFamily: "Montserrat",
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "44px",
    width: "194px",
    borderRadius: "8px",
    "&:hover": {
      background: colors().cyancobaltblue,
      color: colors().white,
    },
    "@media only screen and (max-width: 1024px)": {
      height: 44,
    },
  },
  "& .exportAll": {
    marginRight: "24px",
    textTransform: "unset",
    fontFamily: "Montserrat",
    background: "#E2E8F0",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "20px",
    color: colors().darkliver,
    height: "44px",
    width: "111px",
    borderRadius: "8px",
    "&:hover": {
      background: "#E2E8F0",
      color: colors().darkliver,
    },
    "@media only screen and (max-width: 1024px)": {
      height: 44,
    },
  },
  "& .table": {
    minWidth: 700,
  },
  "& .noRecordFound": {
    textAlign: "center",
    fontSize: "16px",
    fontWeight: 600,
  },
  "& .tableRow": {
    background: colors().cyancobaltblue,
    color: colors().white,
  },
  "& .edit-icon": {
    right: 0,
    bottom: 0,
    width: 26,
    margin: 16,
    cursor: "pointer",
    textAlign: "right",
    position: "absolute",
  },
  "& .editbox": {
    right: "50px",
    bottom: 10,
    cursor: "pointer",
    textAlign: "right",
    position: "absolute",
  },
  "& .table-cols": {
    marginTop: "20px",
  },
  "& .table-rows > div:last-child": {
    position: "relative",
  },
  "& .table-rows > div:nth-last-child(2)": {
    position: "relative",
  },
  "& .table-rows > div": {
    fontSize: "12px",
    textAlign: "left",
    minHeight: "100px",
    textTransform: "capitalize",
    fontWeight: "500 !important",
  },
  "& .confAmountInput": {
    border: "1px solid #ECECEC",
    height: "44px",
    width: "101px",
    padding: "11px 8px",
    boxSizing: "border-box",
    borderRadius: "8px 0px 0px 8px",
    "@media (max-width: 1280px)": {
      fontSize: "14px",
      width: "70px",
      height: "36px"
    },
    "@media (max-width: 1024px)": {
      fontSize: "12px",
      width: "65px",
      height: "32px"
    }
  },
  "& .confAmountSubmitBtn": {
    height: "44px",
    padding: "10px 16px 10px 16px",
    width: "93px",
    borderRadius: "0px 8px 8px 0px",
    background: "#204B9C",
    color: "#fff",
    textTransform: "none",
    "@media (max-width: 1280px)": {
      fontSize: "14px",
      width: "70px",
      height: "36px"
    },
    "@media (max-width: 1024px)": {
      fontSize: "12px",
      width: "65px",
      height: "32px"
    }
  },
  "& .table-rows-norecord > div": {
    fontSize: "12px",
    textAlign: "left",
    minHeight: "50px",
    textTransform: "capitalize",
    fontWeight: "500 !important",
  },
  "& .tablePagination": {
    overflow: "unset",
  },
  "& .no_records": {
    width: "100%",
    fontSize: 16,
    fontWeight: 700,
    textAlign: "center",
  },
  "& .w-100": {
    width: "100%",
  },
  "& .mainrow": {
    cursor: "pointer",
  },
});

export const MainBodyBox = styled(Box)({
  marginTop: "20px",
});

export const StyleContent = styled("span")({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: colors().viewTextColor,
  "@media only screen and (max-width: 1024px)": {
    fontSize: "12px",
  },
});

export const StyleContentVariance = styled("span")({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "16px",
  color: "red",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "12px",
  },
});

export default OutGoingTransaction;
// Customizable Area End
