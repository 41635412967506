import React from "react";
// Customizable Area Start
import {
  Box,
  Table,
  Paper,
  styled,
  Button,
  Checkbox,
  FormGroup,
  Popover,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
} from "@material-ui/core";

import { DatePicker } from "@material-ui/pickers";

import {
  FilterList as FilterIcon,
  MoreVert as MoreVertIcon,
} from "@material-ui/icons";

import DateFns from "@date-io/moment";
import { calenderIcon } from "../../CfPriceList/src/assets";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import SortingTableCell from "../../../components/src/SortingTableCell";
import SortingTableHeader from "../../../components/src/SortingTableHeader2";
import FilterPopover, { IFilter } from "../../../components/src/FilterPopover";
import { colors } from "../../../blocks/utilities/src/Colors";
import ConfirmModal from "../../../components/src/customComponents/ConfirmationModal.web";
import { LoadingComponent } from "../../../components/src/Loader.web";
import { TableRowsSkeleton } from "../../../components/src/customComponents/TableRowsSkeleton.web";
// Customizable Area End

import PromoCodeListController, {
  // Customizable Area Start
  S,
  Props,
  configJSON,
  ISection,
  IPlatform,
  IPromoItem,
  IFilterItem,
  // Customizable Area End
} from "./PromoCodeListController.web";

// Customizable Area Start
import { renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
const Strings = configJSON.Strings.promoCodeList;
// Customizable Area End

class PromoCodeList extends PromoCodeListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.state = {
      endDate: null,
      startDate: null,
      popOverTop: 0,
      popOverLeft: 0,
      sectionList: [],
      promoCodeList: [],
      sectionSelected: [],
      popOverOpened: false,
      filters: [
        {
          title: "Promo Code",
          type: "autocompolete",
          value: "",
          apiKey: "promo_code",
          options: [],
        },
        {
          title: "Section",
          type: "multipleSelect",
          value: [],
          options: []
        },
        {
          title: "Platform",
          type: "autocompolete",
          value: "",
          apiKey: "platform",
          options: [],
        },
        {
          title: "Date",
          type: "doubleDatepicker",
          value: [null, null],
        },
      ],
      filterAnchor: undefined,
      sortingData: {
        promo_code: "",
        section: "",
        platform: "",
        start_date: "",
        end_date: "",
      },
      confirmModalFlag: false,
      confirmModalType: false,
      totalCount: 10,
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      isLoadingPermission: false,
      isAppliedFilter: false,
      isLoading: false
    } as S;
    // Customizable Area End
  }
  // Customizable Area Start
  getPopoverData() {
    const { popOverTop, popOverLeft, popOverOpened, permissionStatus } = this.state;

    return (
      <Popover
        style={{
          boxShadow: "none",
        }}
        anchorReference='anchorPosition'
        open={popOverOpened}
        onClose={() => this.setState({ popOverOpened: false })}
        anchorPosition={{
          top: popOverTop + 40,
          left: popOverLeft - 80,
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {
          renderBaseOnConditions(
            permissionStatus.viewPermission ||
              permissionStatus.editPermission ||
              permissionStatus.deactivatePermission,
            <div style={webStyle.popoverContainer}>
              {
                renderBaseOnConditions(
                  permissionStatus.viewPermission,
                  <Box
                    data-test-id='viewButton'
                    onClick={() =>
                      this.props.navigation.navigate("PromoCodeView", {
                        id: this.popOverItemId,
                      })
                    }
                    sx={webStyle.popoverButton}
                  >
                    {Strings.view}
                  </Box>,
                  <></>
                )
              }
              {
                renderBaseOnConditions(
                  permissionStatus.editPermission,
                  <Box
                    data-test-id='editButton'
                    sx={webStyle.popoverButton}
                    onClick={() =>
                      this.props.navigation.navigate("PromoCodeEdit", {
                        id: this.popOverItemId,
                      })
                    }
                  >
                    {Strings.edit}
                  </Box>,
                  <></>
                )
              }
              {
                renderBaseOnConditions(
                  permissionStatus.deactivatePermission && this.popOverItemStatus === "active",
                  <Box
                    data-test-id={"inactivateButton"}
                    sx={webStyle.popoverButton}
                    onClick={() => {
                        this.setState({
                          popOverOpened: false,
                          confirmModalFlag: true,
                        });
                    }}
                  >
                    {Strings.deactivate}
                  </Box>,
                  <></>
                )
              }
              {
                renderBaseOnConditions(
                  permissionStatus.activatePermission as boolean && this.popOverItemStatus !== "active",
                  <Box
                    data-test-id={"activateButton"}
                    sx={webStyle.popoverButton}
                    onClick={() => this.onEditApi(true)}
                  >
                    {Strings.active}
                  </Box>,
                  <></>
                )
              }
            </div>,
            <div style={webStyle.popoverContainer}>
              <Box sx={webStyle.noPermissions}>No Permissions</Box>
            </div>
          )
        }
      </Popover>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      totalCount,
      sortingData,
      promoCodeList,
      confirmModalFlag,
      confirmModalType,
      permissionStatus
    } = this.state;
    // Customizable Area End

    return (
      // Customizable Area Start
      <MuiPickersUtilsProvider utils={DateFns}>
        <>
          <ConfirmModal
            open={confirmModalFlag}
            handleClose={() =>
              this.setState({
                confirmModalFlag: false,
                confirmModalType: false,
              })
            }
            handleConfirm={this.handleConfirmModal.bind(this)}
            title={
              !confirmModalType
                ? "Confirm Deactivate"
                : "Promo Code Deactivated"
            }
            displayItem={confirmModalType}
            message={{
              id: this.popOverItemId,
              title: this.confirmModalValue,
            }}
            dynamic={!confirmModalType}
            confirmText={!confirmModalType ? "Deactivate" : "Back to Listing"}
          />
        <MainBoxWrapper>
          <Box>
            <div style={webStyle.headerWrapper}>
              <PageTitle>{Strings.pageTitle}</PageTitle>
              <div style={webStyle.headerButtonPartAccount}>
                <div
                  data-test-id='filterButton'
                  onClick={(event: { currentTarget: {} } | undefined) => 
                    this.handleFilterChange(event)
                  }
                  className={sortStringCondition(
                    this.state.isAppliedFilter,
                    "filterButtonAccount active-filter-border",
                    "filterButtonAccount"
                  )}
                >
                  <FilterIcon color={this.handleReturnColorType()} />
                  {renderBaseOnConditions(
                    this.state.isAppliedFilter,
                    <div className="filterActiveIcon"></div>,
                    <></>
                  )}
                </div>
                <FilterPopover
                  onClose={() => this.setState({ filterAnchor: undefined })}
                  anchor={this.state.filterAnchor as HTMLDivElement | undefined}
                  onAutoCompleteChange={(title: string, value: string) => {
                    if (title === "Promo Code")
                      this.getPromoCodeSuggestionList(value);
                    else if (title === "Platform")
                      this.getPlatformSuggestionList(value);
                  }}
                  onFilterChange={(filters: IFilter[]) => {
                    this.setState({ filters });
                    this.onApplyFilter(filters);
                  }}
                  onClearFilter={() => {
                    let updated: IFilterItem[] = this.state.filters.map(
                      (item: IFilterItem) => {
                        if (item.title === "Date") item.value = [null, null];
                        else if (item.title === "Section") item.value = "";
                        else item.value = "";
                        return item;
                      }
                    );
                    this.setState({
                      filters: updated,
                      sectionSelected: [],
                      startDate: null,
                      endDate: null,
                    });
                    setTimeout(() => this.onApplyFilter(updated), 1000);
                  }}
                  refreshFilterState={(filters: IFilterItem[]) =>
                    this.handleSetStateFilter(filters)
                  }
                  filters={this.state.filters as IFilter[]}
                />

                  <LoadingComponent
                    isLoading={this.state.isLoadingPermission}
                    height={44}
                    width={160}
                    isAllowed={permissionStatus.createPermission}
                    children={
                      <Button
                        data-test-id='addPromoCode'
                        className={'addButton'}
                        onClick={() => this.handleRedirection("PromoCodeCreate")}
                      >
                        {Strings.addPromoCode}
                      </Button>
                    }
                  />
              </div>
            </div>
            <TableContainer component={Paper}>
              <Table style={webStyle.table} aria-label='customized table'>
                <TableHead>
                  <TableRow style={{ background: "#204B9C", color: "#FFFFFF" }}>
                    <SortingTableHeader
                      sortingData={sortingData}
                      title='Promo Code'
                      sortingKey='promo_code'
                      {...this.sortingProps}
                      type='left'
                      handleHeaderClick={() => null}
                    />
                    <SortingTableHeader
                      sortingData={sortingData}
                      title='Section'
                      sortingKey='section'
                      {...this.sortingProps}
                      handleHeaderClick={() => null}
                    />
                    <SortingTableHeader
                      sortingData={sortingData}
                      title='Platform'
                      sortingKey='platform'
                      {...this.sortingProps}
                      handleHeaderClick={() => null}
                    />
                    <SortingTableHeader
                      sortingData={sortingData}
                      title='Start Date'
                      sortingKey='start_date'
                      {...this.sortingProps}
                      handleHeaderClick={() => null}
                    />

                    <SortingTableHeader
                      sortingData={sortingData}
                      title='End Date'
                      sortingKey='end_date'
                      {...this.sortingProps}
                    />

                    <SortingTableHeader
                      sortingData={sortingData}
                      title='Status'
                      sortingKey='status'
                      {...this.sortingProps}
                      sortingDisabled
                    />

                    <SortingTableHeader
                      sortingData={sortingData}
                      title=''
                      type='action'
                      sortingKey=''
                      {...this.sortingProps}
                      sortingDisabled
                    />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {promoCodeList.map((item: IPromoItem) => {
                    return (
                      <TableRow
                        key={"table-row-" + item.id}
                        data-test-id={"table-row-" + item.id}
                      >
                        <SortingTableCell
                          width='22vw'
                          type='left'
                          content={item.attributes.promo_code}
                        />
                        <SortingTableCell
                          width='20vw'
                          type='middle'
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxHeight: "45px",
                          }}
                          content={item.attributes.sections
                            .map((item: ISection) => item.name)
                            .join(", ")}
                        />
                        <SortingTableCell
                          width='22vw'
                          type='middle'
                          content={item.attributes.platforms
                            .map((item: IPlatform) => item.name)
                            .join(", ")}
                        />
                        <SortingTableCell
                          width='15vw'
                          type='middle'
                          content={item.attributes.start_date}
                        />
                        <SortingTableCell
                          width='15vw'
                          type='middle'
                          content={item.attributes.end_date}
                        />
                        <SortingTableCell
                          width='22vw'
                          type='right'
                          content={
                            item.attributes.status === "active"
                              ? "Active"
                              : "Deactivated"
                          }
                        />
                        <SortingTableCell
                          width='22vw'
                          type='action'
                          content={
                            <IconButton
                              data-test-id={"moreButton-" + item.id}
                              onClick={(event) => {
                                const position = event.currentTarget.getBoundingClientRect();
                                this.setState({
                                  popOverOpened: true,
                                  popOverLeft: position.left + window.scrollX,
                                  popOverTop: position.top + window.scrollY,
                                });
                                this.popOverItemStatus = String(
                                  item.attributes.status
                                );
                                this.popOverItemId = item.id;
                                this.confirmModalValue =
                                  item.attributes.promo_code;
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                          }
                        />
                      </TableRow>
                    );
                  })}
                </TableBody>

                {this.state.isLoading &&
                      <>{Array(5)
                        .fill(1)
                        .map((_, index) => (
                          <TableBody key={index} data-test-id='skeletonTable'>
                            <TableRow>
                              {[...Array(6)].map((_, cellIndex) => (
                                <TableRowsSkeleton key={cellIndex} />
                              ))}
                            </TableRow>
                          </TableBody>
                        ))}</>
                    }
              </Table>

              {promoCodeList.length === 0 && (
                <NoRecordFoundText>{Strings.noRecordFounds}</NoRecordFoundText>
              )}
            </TableContainer>

            {promoCodeList.length != 0 && (
              <TablePagination
                component='div'
                count={totalCount}
                page={this.currentPage - 1}
                rowsPerPage={this.rowPerPage}
                rowsPerPageOptions={[10]}
                onRowsPerPageChange={(
                  e: React.ChangeEvent<{ value: unknown }>
                ) => {
                  this.rowPerPage = e.target.value as number;
                  this.getPromoCodesApi();
                }}
                onPageChange={(_, page: number) => this.onChangePage(page + 1)}
              />
            )}
          </Box>
        </MainBoxWrapper>
          {this.getPopoverData()}
        </>
      </MuiPickersUtilsProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const NoRecordFoundText = styled("div")({
  fontSize: 16,
  fontWeight: 600,
  textAlign: "center",
});

export const PageTitle = styled("div")({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "29px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
  },
});

const MainBoxWrapper = styled("div")({
  paddingTop:"25px",
  "& .filterButtonAccount": {
    position: "relative",
    marginRight: "24px",
    cursor: "pointer",
    background: colors().white,
    height: "56px",
    color:  colors().darkliver,
    width: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
    borderColor:colors().lightborder,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
    },
  },
  "& .active-filter-border": {
    borderColor:colors().cyancobaltblue,
  },
  "& .filterActiveIcon": {
    position: "absolute",
    top: "-5px",
    right: "-5px",
    minWidth: "15px",
    background: colors().cyancobaltblue,
    minHeight: "15px",
    borderRadius: "50%"
  },
  "& .addButton": {
    background: colors().cyancobaltblue,
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().white,
    height: "56px",
    width: "184px",
    borderRadius: "8px",
    textTransform: "unset",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
      width: "160px",
      height: "44px",
    },
  },
});


export const webStyle = {
  table: {
    minWidth: 700,
  },
  mainWrapper: {
    marginTop: "32px",
    fontFamily: "Montserrat",
  },
  noPermissions: {
    padding: "0 8px"
  },
  pageHeader: {},
  headerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 32,
    alignItems: "center",
    flexWrap: 'wrap' as 'wrap'
  },
  addButton: {
    fontFamily: "Montserrat",
    background: "#204B9C",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    height: "56px",
    width: "184px",
    borderRadius: "8px",
  },
  contentWrapper: {
    marginRight: 32,
    marginTop: 32,
    marginLeft: 48,
    paddingTop: 64,
  },

  headerButtonPartAccount: {
    display: "flex",
  },

  popoverButton: {
    cursor: "pointer",
    width: "118px",
    height: "33px",
    paddingLeft: "24px",
    backgroundColor: "#FFFFFF",
    color: "#4D4D4D",
    "&:hover": {
      backgroundColor: "#204B9C",
      color: "#FFFFFF",
    },
    paddingTop: "8px",
  },
  popoverContainer: {
    paddingTop: "16px",
    paddingBottom: "16px",
    boxShadow:
      "0px 4px 8px rgba(0, 0, 0, 0.03), 0px 8px 32px rgba(0, 0, 0, 0.06)",
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: 500,
    fontSize: "14px",
  },
  filterButtonAccount: {
    marginRight: "24px",
    cursor: "pointer",
    background: "#FFFFFF",
    height: "56px",
    color: "#4d4d4d",
    width: "56px",
    fontSize: "24px",
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #204B9C",
  },
};

// Customizable Area End

export default PromoCodeList;
