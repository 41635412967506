// Customizable Area Start
import React from "react";
import {styled, TableContainer, Table, TableHead, TableBody,TableRow,TableCell, CircularProgress} from "@material-ui/core";
import PopularTimesDashboardController, { OrderCountByDay, OrderCountByHour, Props, configJSON } from "./PopularTimesDashboardController";
import { colors } from "../../utilities/src/Colors";
import { IFilter } from "../../../components/src/FilterPopover";
import OrderCountChart from "../../../components/src/OrderCountChart.web";
import DashboardHeader from "../../../components/src/DashboardHeader";
import DashboardTabPanel from "../../../components/src/DashboardTabPanel.web";
import * as assets from "./assets";
import {getGroupNameDisplay } from "../../cfsalesdashboard3/src/utils";
import ExportModal from "../../../components/src//ExportModal";
import { storeNameBasedOnGroup } from "./utils";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class PopularTimesDashboard extends PopularTimesDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderTable = (header: string, items: Array<OrderCountByDay | OrderCountByHour>) => {
    return (
      <StyledTable className={header === "Hours" ? "pink-table" : ""}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <p>{header}</p>
              </TableCell>
              <TableCell width="27%">
                <p>Orders</p>
              </TableCell>
              <TableCell width="27%">
                <p>Pieces</p>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              items.map((item, index) => (
                <TableRow key={index}>
                  <TableCell><p>{"time" in item ? item.time : item.day}</p></TableCell>
                  <TableCell><p>{item.count}</p></TableCell>
                  <TableCell><p>{item.total_pieces}</p></TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      </StyledTable>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {groupId, regionName, storeId, ordersByDay, ordersByHour, activeType, loadingDayData, loadingHourData } = this.state
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <div>
        <DashboardTabPanel navigation={this.props.navigation} id="PopularTimesDashboard" permissionStatus={this.state.permissionStatus} />
        <StyledWrapper 
          id={this.state.isPrinting ? "print-wrapper" : undefined}
          className={this.getPrintClass()}
        >
          <DashboardHeader
            isAppliedFilter={this.state.isAppliedFilter}
            regionVal={regionName}
            storeVal={storeNameBasedOnGroup(storeId)}
            isGroup={true}
            groupVal={getGroupNameDisplay(groupId)}
            handleFilterButton={this.handleFilterButton}
            handleCloseFilterButton={this.handleCloseFilterButton}
            handleFilterChange={this.handleFilterChange}
            filterAnchor={this.state.filterAnchor}
            filters={this.state.filters as IFilter[]}
            periodDateRange={this.periodDateRange()}
            timeRange={this.timeRange()}
            elementBeforeFilter={
              <div className="switch">
                {
                  ["Store", configJSON.mobile].map((switchItem) => (
                    <button
                      key={switchItem} 
                      className={activeType === switchItem ? "active-toggle" : "inactive-toggle"}
                      onClick={() => this.switchType(switchItem as "Store" | "Mobile")}
                      type="button"
                    >
                      <img src={switchItem === "Store" ? assets.storeIcon : assets.darkMobileIcon} alt=""/>
                      {switchItem}
                    </button>
                  ))
                }
              </div>
            }
            elementAfterFilter={
              <div className="actions">
                <button
                  type="button"
                  onClick={() => this.handleOpenExportModal()}
                  disabled={loadingDayData || loadingHourData}
                >
                  {configJSON.exportAll}
                </button>
              </div>
            }
          />
          <p className="title">Popular Times</p>

          <div className="main-content" data-test-id="popular-times-main-content">
            <div className="main-content-section">
              <p className="section-title">Popular Hours</p>
              <OrderCountChart
                chartData={ordersByHour}
                barProps={{dataKey: "count"}}
                barCategoryGap="15%"
                xAxisProps={{
                  dataKey:"time", 
                  textAnchor: "end", 
                  dx: -2, 
                  angle: -70, 
                  tick: {fontSize: 10, fill:"black"}
                }}
                isPrinting={this.state.isPrinting}
                loading={loadingHourData}
              />
              <div className="section-table">
                {this.renderTable("Hours", ordersByHour.filter(item => item.count || item.total_pieces))}
              </div>
            </div>

            <div className="main-content-section">
              <p className="section-title">Popular Days</p>
              <OrderCountChart
                chartData={ordersByDay} 
                barProps={{dataKey: "count"}}
                barCategoryGap="30%"
                xAxisProps={{
                  dataKey: "day", 
                  tickMargin: 15,
                }}
                isPrinting={this.state.isPrinting}
                loading={loadingDayData}
              />
              <div className="section-table">
                {this.renderTable("Days", ordersByDay)}
              </div>
            </div>
          </div>
        </StyledWrapper>
        </div>
        {
          this.state.openExportModal && <ExportModal isOpen onCancel={this.handleOpenExportModal} onConfirm={this.handleExportConfirm} />
        }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled("div")({
  "& .custom-loading": {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1400,
  },
  "&#print-wrapper": {
    transform: "scale(1)",
    "& .d-flex": {
      "& .filterButtonAccount, & .actions": {
        display: "none"
      },
      "& .switch": {
        marginRight: 0,
        border: 0,
        "& button.active-toggle": {
          background: "white",
          border: 0
        },
        "& button.inactive-toggle": {
          display: "none"
        }
      }
    },
    "& .title, & .main-content": {
      marginTop: 10,
      marginBottom: 0
    },
    "& .main-content": {
      gridTemplateColumns: "1fr 1fr",
      gridTemplateRows: "unset"
    },
    "& .section-table": {
      paddingTop: 4, 
      "& > .MuiTableContainer-root": {
        maxHeight: "unset",
      },
    },
    "&.long-layout": {
      "& .section-table .MuiTableCell-root": {
        paddingTop: 6,
        paddingBottom: 10
      }
    },
    "&.extra-long-layout": {
      "& .title, & .main-content": {
        marginTop: 5,
      },
      "& .section-table .MuiTableCell-root": {
        padding: 4
      }
    }
  },
  "& .circular-container": {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
  "& .headerWrapper": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: 12,
    marginTop: 10,
    alignItems: "center",
  },
  "& .rangeBox": {
    display: "flex",
    gap: 40,
  },
  "& .PageHeading": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    color: colors().darkjunglegreen,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    },
  },
  "& .storeval": {
    marginLeft: "50px",
  },
  "& .datePeriod": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  "& .d-flex": {
    display: "flex",
  },
  "& .flexstyle": {
    flexWrap: "wrap",
    gap: 20
  },
  "& .filterButtonAccount": {
    "&.active-border": {
      borderColor: colors().cyancobaltblue,
    },
    cursor: "pointer",
    background: colors().white,
    height: "44px",
    width: "44px",
    fontSize: "24px",
    position: "relative",
    borderColor: colors().lightborder,
    borderRadius: "8px",
    display: "flex",
    justifyContent: "center",
    color: colors().darkliver,
    border: "1px solid",
    alignItems: "center",
    "@media only screen and (max-width: 1024px)": {
      height: 44,
      width: 44,
    },
    "& .filterActiveIcon": {
      minWidth: "15px",
      top: "-5px",
      right: "-5px",
      position: "absolute",
      background: "#204B9C",
      minHeight: "15px",
      borderRadius: "50%"
    }
  },
  "& .switch": {
    marginRight: 16,
    display: "grid",
    width: "fit-content",
    gridTemplateColumns: "auto auto",
    border: `solid 1px ${colors().lightborder}`,
    borderRadius: 8,
    "& > button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 10,
      padding: "10px 16px",
      fontSize: 16,
      lineHeight: 1.5,
      border: 0,
      background: "white",
      "&:first-of-type": {
        borderRadius: "8px 0 0 8px",
      },
      "&:last-of-type": {
        borderRadius: "0 8px 8px 0",
      },
      "&.active-toggle": {
        background: colors().brightgray,
        pointerEvents: "none"
      }
    }
    
  },
  "& .actions": {
    marginLeft: 18,
    display: "flex",
    gap: 16,
    "& > button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 10,
      padding: "10px 16px",
      lineHeight: 1.5,
      border: 0,
      borderRadius: 8,
      background: colors().lightgray,
      fontWeight: 600,
      cursor: "pointer",
      height: 44,
      fontSize: 16
    }

  },
  "& .title": {
    fontSize: 19,
    fontWeight: 600,
    lineHeight: 1.2,
    marginTop: 20
  },
  "& .main-content": {
    margin: "22px 0",
    paddingBottom: 20,
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 16,
    "@media only screen and (max-width: 1200px)": {
      gridTemplateColumns: "unset",
      gridTemplateRows: "1fr 1fr"
    }
  },
  "& .main-content-section": {
    borderRadius: 12,
    border: `solid 1px ${colors().lightborder}`
  },
  "& .section-title": {
    fontSize: 19,
    fontWeight: 500,
    lineHeight: 1.2,
    padding: 16,
  },
  "& .section-table": {
    padding: 16
  },
  "& .custom-bar-chart-container": {
    marginLeft: -8
  }
})

const StyledTable = styled(TableContainer)({
  maxHeight: 440,
  padding: "0px 16px",
  borderRadius: 12,
  background: colors().lightblue,
  "&.pink-table": {
    backgroundColor: colors().lightlavender,
    "& .MuiTableCell-root": {
      backgroundColor: colors().lightlavender,
    }
  },
  "& .MuiTableCell-root": {
    borderBottom: 0,
    "&.MuiTableCell-head": {
      fontSize: 16,
      lineHeight: 1.5,
      fontWeight: 400,
      backgroundColor: colors().lightblue,
      color:colors().coolgray
    },
    "&.MuiTableCell-body": {
      fontSize: 16,
      lineHeight: 1.4,
      fontWeight: 500,
      color: colors().darkjunglegreen
    }
  }
})
// Customizable Area End