import {
  promoCodeIcon,
  accountIcon,
  rolesIcon,
  regionIcon,
  areaIcon,
  ProductIcon,
  CompanyIcon,
  PlantIcon,
  StoreIcon,
  StoreGrpIcon,
  TaxIcon,
  SectionIcon,
  ServicesIcon,
  PreferenceIcon,
  ProductSpecificationIcon,
  UpchargeIcon,
  GalleryIcon,
  OrderCreationIcon,
  loyaltyIcon,
  GroupIcon,
  CustomerListIcon,
  OrganizationIcon,
  ProfessionIcon,
  RedeemIcon,
  PriceListIcon,
  HomeCleanOrdersIcon,
  HomeCleanProductIcon,
  HomeCleanSettingsIcon,
  ReceiptPrinterIcon,
  MetalProgettiIcon,
  GarmentTagPrinterIcon,
  businessAccountsIcon,
  customerGroupsIcon,
  customersIcon,
  productMasterIcon,
  geofenceIcon,
  companyB2BIcon
} from "./assets";

export interface ISubPermissionGroup {
  id: number;
  name: string;
  permitted: boolean;
  sub_group_key: string
}

export interface PermissionGroupArray {
  id: number;
  name: PermissionName;
  permitted: boolean;
  permission_sub_groups: Array<ISubPermissionGroup>;
  permission_group_key:
    | "company_apis"
    | "role_apis"
    | "account_apis"
    | "plant_apis"
    | "service_apis"
    | "specification_apis"
    | "product_apis"
    | "section_apis"
    | "store_management_apis"
    | "region_apis"
    | "area_apis"
    | "preference_list_apis";
}

interface StoreManagements {
	id: string;
	type: string;
	store_name: string;
	store_id: number;
}

export interface IMyUser {
  attributes: {
    first_name: string;
    email: string;
    last_name: string;
    full_name: string;
    activated: boolean;
    employee_proifle: {
      data: {
        id: string;
        attributes: {
          profile_logo: null | string;
          plant_section: null | string;
          region_manager_id: number | null;
          region_manager_name: string | null;
          store_management_id: number;
          region: {
            data: {
              attributes: {
                id: number;
                branch_name: string;
                branch_short_name: string;
              };
            };
          };
          region_ids: string[];
          store_management_ids: number[];
          store_management: {
            store_id: number;
            store_name: string;
            attributes: {
              is_process_cleaning_products: boolean;
              payment_method_screen: boolean;
              item_preference_popup: boolean;
              price_list_id: number;
              sections: {
                name: string;
                id: number;
              }[];
            };
          };
          store_managements: StoreManagements[]
          share_folders: string[]
        };
      };
    };
    role?: {
      id: 117;
      name: "SuperAdmin" | string;
      created_at: string;
      updated_at: string;
      active: boolean;
      icon_id: number;
      role_permissions_count: number;
    };
    permission_groups: [PermissionGroupArray];
  };
}

export type PermissionName =
  | "Company"
  | "Role Assignment"
  | "Account Creation"
  | "Plant Creation"
  | "Services Creation"
  | "Upcharge List Creation"
  | "Specifications Management"
  | "Products Management"
  | "Sections Management"
  | "Company Activation"
  | "Role Activation"
  | "Account Activation"
  | "Plant Activation"
  | "Store Management"
  | "Order Creation"
  | "Tax calculator"
  | "Store Management Activation"
  | "Services Activation"
  | "Specifications Management Activation"
  | "Upcharge List Activation"
  | "Region Creation"
  | "Region Activation"
  | "Area Creation"
  | "Area Activation"
  | "Preference List Creation"
  | "Preference List Activation"
  | "Products Activation"
  | "Loyalty System"
  | "Section Activation"
  | "Organisation"
  | "Customer Type Management"
  | "Promo Code"
  | "Invite Friend Module"
  | "Gallery Module"
  | "Plant Order Management"
  | "Home cleaning setting"
  | "Home Cleaning Product"
  | "Price List Management"
  | "Laundry order management"
  | "Carpet and furniture cleaning Orders"
  | "Home cleaning Orders"
  | "Transfer Request"
  | "Region"
  | "Area"
  | "Store"
  | "Tax"
  | "Invite Friend"
  | "Product Specifications"
  | "Product"
  | "Account"
  | "Preference"
  | "Customer"
  | "Service"
  | "Section"
  | "Gallery"
  | "Price List"
  | "Upcharge"
  | "Role"
  | "Customer Type"
  | "Plant"
  | "Profession"
  | "Order"
  | "Cashier"
  | "Cashup"
  | "Plant management"
  | "Other Permission"
  | "Dashboard"
  | "Report Export"
  | "Hardware"
  ;

export interface IMenuItems {
  0: React.ReactNode | string;
  1: string;
  2: string;
  3: string;
  4: PermissionName;
  5?: PermissionName[]; // additional permissions
  6?: string
}

type Key = string | number;
export interface Attributes {
  key?: Key | null;
}

export const productsModuleMenuItems: IMenuItems[] = [
  [PriceListIcon, "Price List", "PriceList", "Products", "Price List"],
  [SectionIcon, "Section", "SectionList", "Products", "Section"],
  [ServicesIcon, "Services", "ServicesList", "Products", "Service"],
  [
    UpchargeIcon,
    "Upcharge",
    "UpchargeList",
    "Products",
    "Upcharge",
  ],
  [
    ProductSpecificationIcon,
    "Product Specification",
    "SpecificationList",
    "Products",
    "Product Specifications",
  ],
  [
    PreferenceIcon,
    "Preference",
    "PreferenceList",
    "Products",
    "Preference",
  ],
  [ProductIcon, "Product", "ProductList", "Products", "Product"],
  [GalleryIcon, "Gallery", "Gallery", "Products", "Gallery"],
];

export const generalModuleMenuItems: IMenuItems[] = [
  [CompanyIcon, "Company", "CompanyList", "General", "Company"],
  [regionIcon, "Region", "RegionList", "General", "Region"],
  [areaIcon, "Area", "AreaList", "General", "Area"],
  [PlantIcon, "Plant", "PlantList", "General", "Plant"],
  [StoreIcon, "Store", "StoreList", "General", "Store"],
  [StoreGrpIcon, "Store Group", "StoreGroupList", "General", "Other Permission",[],"grouping_store"],
  [TaxIcon, "Tax", "TaxList", "General", "Tax"],
];

export const customerModuleMenuItems: IMenuItems[] = [
  [
    GroupIcon,
    "Customer Type",
    "CustomerType",
    "Customer",
    "Customer Type",
  ],
  [
    CustomerListIcon,
    "Customer List",
    "PeopleManagement2",
    "Customer",
    "Customer",
  ],
  [
    OrganizationIcon,
    "Organization",
    "OrganizationList",
    "Customer",
    "Organisation",
  ],
  [
    ProfessionIcon,
    "Profession",
    "ProfessionList",
    "Customer",
    "Profession",
  ],
  [
    RedeemIcon,
    "Invite Codes / Referral",
    "Invitefriends",
    "Customer",
    "Invite Friend",
  ],
];

export const homeCleanMenuItems: IMenuItems[] = [
  [
    HomeCleanSettingsIcon,
    "Home Cleaning Settings",
    "HomeCleaningSettingsList",
    "homeCleanProduct",
    "Home cleaning setting",
  ],
  [
    HomeCleanProductIcon,
    "Home Products",
    "HomeCleaningProductList",
    "homeCleanProduct",
    "Home Cleaning Product",
  ],
  [
    HomeCleanOrdersIcon,
    "Home Orders",
    "HomeCleaningOrders",
    "homeCleanProduct",
    "Home cleaning Orders",
  ],
];

export const settingMenuItems: IMenuItems[] = [
  [
    rolesIcon,
    "Roles and Permission",
    "RolesPermissions",
    "Setting",
    "Role",
  ],
  [accountIcon, "Account", "Account", "Setting", "Account"],
];

export const storeModuleMenuItems: IMenuItems[] = [
  [
    OrderCreationIcon,
    "Orders Creation",
    "OrderCreation",
    "StoreManage",
    "Order",
    ["Carpet and furniture cleaning Orders"],
    "create"
  ],
  [ 
    StoreIcon, 
    "Orders", 
    "Orders", 
    "StoreManage", 
    "Order", 
    ["Carpet and furniture cleaning Orders"],
    "read"
  ],
];

export const marketingModuleMenuItems: IMenuItems[] = [
  [loyaltyIcon, "Loyalty System", "LoyaltyList", "Marketing", "Loyalty System"],
  [promoCodeIcon, "Promo Code", "PromoCode", "Marketing", "Promo Code"],
];

export const hardwareModuleMenuItems: IMenuItems[] = [
  [ReceiptPrinterIcon, "Receipt Printer", "ReceiptPrinter", "Hardware",  "Hardware", [], "receipt_printer_access"],
  [GarmentTagPrinterIcon, "Garment Tag Printer", "GarmentTagPrinter", "Hardware", "Hardware", [], "garment_tag_printer_access"],
  [MetalProgettiIcon, "Metal Progetti", "MetalProgetti", "Hardware","Hardware", [], "metal_progetti_access"],
];

export const headerConfig = {
  search: [
    "/Cfplantdashboard2",
    "/Dashboard-PlantReport",
    "/cashier-IncomingTransaction",
    "/cashier-OutGoingTransaction",
    "/Products-PriceList",
    "/Products-SectionList",
    "/Products-ServicesList",
    "/Products-UpchargeList",
    "/Products-SpecificationList",
    "/Products-PreferenceList",
    "/StoreManage-Orders",
    "/Products-ProductList",
    "/Products-Gallery",
    "/Products-GalleryAddImageIcon",
    "/Marketing-LoyaltyList",
    "/PromoCode",
    "/Marketing-CustomerType",
    "/cashup",
    "/StoreManage-OrderCreation",
    "/Settings-RolesPermissions",
    "/Settings-Account",
    "/Settings-General-CompanyList",
    "/Settings-General-RegionList",
    "/Settings-General-AreaList",
    "/Settings-General-PlantList",
    "/Settings-General-StoreList",
    "/Settings-General-StoreGroupList",
    "/Settings-General-Taxlist",
    "/Marketing-Customer-PeopleManagement2",
    "/Marketing-Customer-OrganizationList",
    "/Marketing-ProfessionList"
  ],
  hideActivePassiveUsers: ["/Cfplantdashboard2","/SalesReporting"],
  printerSettings: ["/AdvancedSearch","/StoreManage-Orders"],
  multiRegionDropdown: [
    "/Dashboard-Main",
    "/Dashboard-Overview",
    "/Dashboard-SectionSales",
    "/Dashboard-PlantReport",
    "/Dashboard-PopularTimes",
    "/Dashboard-Sales",
    "/Dashboard-Revenue",
    "/Dashboard-Customer",
    "/Dashboard-DiscountCredit",
    "/CfCashier",
    "/cashier-IncomingTransaction",
    "/cashier-OutGoingTransaction",
    "/cashup",
    "/SalesReporting"
  ],
  storeAndGroupChange: [
    "/Dashboard-Main",
    "/Dashboard-Overview",
    "/Dashboard-SectionSales",
    "/Dashboard-PlantReport",
    "/Dashboard-PopularTimes",
    "/Dashboard-Sales",
    "/Dashboard-Revenue",
    "/Dashboard-Customer",
    "/Dashboard-DiscountCredit",
    "/SalesReporting"
  ],
  singleSelectStore: ["/StoreManage-Orders","/StoreManage-OrderCreation"],
  multiSelectStore: [
    "/cashier",
    "/cashier-IncomingTransaction",
    "/cashier-OutGoingTransaction",
    "/Cfplantdashboard2",
    "/cashup"
  ]
};

export const b2bSettingMenuItems: IMenuItems[] = [
  [businessAccountsIcon,"Business Accounts","RolesPermissions","Setting","Role"],
  [customersIcon, "Customers", "Account", "Setting", "Account"],
  [customerGroupsIcon, "Customer Groups", "Account", "Setting", "Account"],
  [productMasterIcon, "Product Master", "Account", "Setting", "Account"],
  [PriceListIcon, "Price List", "Account", "Setting", "Account"],
];

export const B2bGeneralModuleMenuItems: IMenuItems[] = [
  [companyB2BIcon, "Company", "CompanyList", "General", "Company"],
  [regionIcon, "Region/Business Area", "RegionList", "General", "Region"],
  [areaIcon, "Area", "AreaList", "General", "Area"],
  [PlantIcon, "Plant", "PlantList", "General", "Plant"],
  [StoreIcon, "Store", "StoreList", "General", "Store"],
  [geofenceIcon, "Geo Fence", "TaxList", "General", "Tax"],
];