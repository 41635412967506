import React from "react";
// Customizable Area Start
import { Formik, FormikProps, FormikValues } from "formik";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  styled,
  Box,
  Paper,
  Table,
  Button,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  TablePagination,
  TableCell,
  Tooltip,
  withStyles,
  Radio,
  Portal,
  MenuItem,
  DialogContent,
  CircularProgress,
  Grid,
  Typography,
  TextareaAutosize,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
} from "@material-ui/core";
import { PageTitle } from "../../promocodes/src/PromoCodeList.web";
import {
  CustomPaper,
  CustomTab,
  CustomTabs,
  StyledTextField,
} from "../../../components/src/customComponents/CustomTags.web";
import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";
import DateFnsUtils from "@date-io/moment";
import SortingTableCell from "../../../components/src/SortingTableCell";
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import CustomDateRangePicker from "../../../components/src/CustomDateRangePicker";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import OrderDetailsPage from "../../../blocks/ordermanagement/src/OrderDetails.web";
import PaymentModal from "../../Payments/src/PaymentModal.web";

import {
  IFieldOptions,
  FieldType,
  OrderItems,
  ISearchResult,
  ISearchResultList,
  configJSON,
  OrderTransactions,
} from "./AdvancedSearchController";
import { IOrder } from "../../OrderCreation/src/OrderCreationController.web"
import { IconEdit, IconPrint, IconCalender, circleIcon } from "./assets";
import { createTheme } from "@material-ui/core/styles";
import {
  getGridColumnSpan,
  checkBusinessLabel,
  checkValues,
  getComponentByCondition,
  getOrderType,
  IRenderCustomTag,
  validationSchema,
  CustomMenuProps,
  CreditSearchProps,
  CreditNoteButtonProps
} from "./utils";
import { colors } from "../../../blocks/utilities/src/Colors";
import { OrderPermissionStatus, renderBaseOnConditions } from "../../utilities/src/CustomBlockHelpers";
const Strings = configJSON.Strings;
import {
  CreditNoteInput,
  MainWrapper,
  PriceGrid,
  StyledDialogWrapper,
  StyledSelectAdjustmentReason,
  TextLabel
} from "../../../blocks/cfplantdashboard2/src/Cfplantdashboard2.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import { IconBluePrint, creditNoteIcon } from "../../../blocks/cfplantdashboard2/src/assets";
import moment from "moment";
const CheckTrue = require("../../../components/src/check_true.svg");
const CheckFalse = require("../../../components/src/check_false.svg");
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});
// Customizable Area End

import AdvancedSearchController, { Props } from "./AdvancedSearchController";

export default class AdvancedSearch extends AdvancedSearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    const {
      totalCount,
      orderData,
      searchResultList,
      selectedOrderType,
      selectedSearchType,
      totalSearchedResult,
      isCreditNoteDialogOpen,
      openCrediNoteModalLoader,
      hidden,
      isConfirmationChecked,
      creditLoader,
      creditNotePresent,
      creditNoteReceipt,
      creditNoteOrder,
      addNote
    } = this.state;

    const isOrders: boolean = selectedSearchType === "Orders";
    const dynamicFieldArray = this.getDynamicFieldArray(isOrders);
    const FieldArrayName = getOrderType(selectedSearchType, FieldArray, FieldArrayCustomer);
    return (
      <>
      <HideInputContainer>
    
          <MainContainer>
            <Container>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MainHeadingContainer>
                  <PageTitle>{Strings.advanceSearch}</PageTitle>
                  <HeadingContainer
                    style={{ justifyContent: "flex-end", flexWrap: "nowrap" }}
                  >
                    <AutocompleteSelect
                      placeholder={configJSON.selectTypeTxt}
                      fieldName="customerOrderSelect"
                      data-test-id='customerOrderSelect'
                      disableClear
                      onChange={
                        (event: React.ChangeEvent, value: { id: string; option: string; image: string }) => {
                          this.onChangeSearchType(value.id);
                        }}
                      value={selectedSearchType}
                      options={[{ id: "Orders", option: "Orders" }, { id: "Customers", option: "Customers" }]}
                    />

                    {isOrders && (
                      <AutocompleteSelect
                        inputStyle={{minWidth:"250px"}}
                        placeholder="Select Order Status"
                        fieldName="orderStatus"
                        data-test-id='orderStatus'
                        disableClear
                        onChange={
                          (event: React.ChangeEvent, value: { id: string; option: string }) => {
                            this.onChangeStatus(value.option);
                          }}
                        value={selectedOrderType}
                        options={this.convertToOptionObj(this.getOptions("all_orders"),"label")}
                      />
                    )}
                  </HeadingContainer>
                </MainHeadingContainer>
                <Formik
                  initialValues={this.getDynamicInitialValues(
                    dynamicFieldArray
                  )}
                  validationSchema={validationSchema}
                  onSubmit={this.handleSearchApiCall.bind(this)}
                  enableReinitialize
                >
                  {({
                    values,
                    resetForm,
                    handleSubmit,
                    getFieldProps,
                    setFieldValue,
                    errors
                  }: FormikProps<FormikValues>) => (
                    <form
                      onSubmit={handleSubmit}
                      onReset={() => this.onResetForm(resetForm)}
                    >
                      <CustomPaper>
                        <FieldArrayName>
                          {dynamicFieldArray.map((option: IFieldOptions) => {
                            const { filterKey, label, type, span } = option;
                            const fieldError = errors[filterKey]
                            const styleForSpan = getGridColumnSpan(span);
                            switch (type) {
                              case FieldType.textbox:
                                return (
                                  <StyledTextField
                                    variant='outlined'
                                    placeholder={label}
                                    autoComplete='off'
                                    style={{...styleForSpan, gridArea: `${filterKey}`}}
                                    data-test-id={`txt_${filterKey}`}
                                    {...getFieldProps(filterKey)}
                                  />
                                );
                              case FieldType.select:
                                return (
                                  <AutocompleteSelect
                                    placeholder={label}
                                    fieldName={`select_${filterKey}`}
                                    data-test-id={`select_${filterKey}`}
                                    disableClear
                                    onChange={
                                      (event: React.ChangeEvent, value: { id: string; option: string }) => {
                                        this.handelDateRangeChange(
                                          filterKey,
                                          setFieldValue,
                                          value.id
                                        );
                                      }}
                                    value={checkValues(values[filterKey])}
                                    options={this.convertToOptionObj(this.getOptions(filterKey), "value")}
                                  />
                                );
                              case FieldType.date:
                                return (
                                  <DateFormWrapper>
                                  <StyledDatePicker
                                    autoOk
                                    disableToolbar
                                    variant='inline'
                                    format='DD/MM/yyyy'
                                    placeholder={label}
                                    style={{...styleForSpan, gridArea: `${filterKey}`}}
                                    data-test-id={`date_${filterKey}`}
                                    value={checkValues(values[filterKey])}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                    keyboardIcon={
                                      <img src={IconCalender} width={24} height={24}/>
                                    }
                                    onChange={(event: Date) => {
                                      setFieldValue(filterKey, event);
                                      this.handelDateRangeChange(
                                        filterKey,
                                        setFieldValue
                                      );
                                    }}
                                    disabled={this.isDateFieldDisable(
                                      filterKey,
                                      values
                                    )}
                                  />
                                 {fieldError && <Box className="error">{fieldError}</Box>} 
                                  </DateFormWrapper>
                                );

                              case FieldType.dateRange:
                                return (
                                  <FullWidthDateContainer style={{...styleForSpan, gridArea: `${filterKey}`}}>
                                      <DateFormWrapper>
                                    <CustomDateRangePicker
                                      testId={`dateRange_${filterKey}`}
                                      placeholder={label}
                                      values={checkValues(values[filterKey])}
                                      onChange={(range) => {
                                        setFieldValue(filterKey, range);
                                        this.handelDateRangeChange(
                                          filterKey,
                                          setFieldValue
                                        );
                                      }}
                                    />
                                     {fieldError && fieldError === configJSON.requiredField && <Box className="error">{fieldError}</Box>} 
                                     </DateFormWrapper>
                                  </FullWidthDateContainer>
                                );
                            }
                          })}
                        </FieldArrayName>
                      </CustomPaper>

                      <BottomContainer>
                        <Button
                          data-test-id='btn-reset'
                          type='reset'
                          className="resetButton"
                        >
                          {Strings.reset}
                        </Button>
                        <Button
                          data-test-id='btn-submit'
                          type='submit'
                          className="addButtonStyle"
                          onClick={() => {
                            this.currentPage = 1;
                          }}
                        >
                          {Strings.search}
                        </Button>
                      </BottomContainer>
                    </form>
                  )}
                </Formik>
              </MuiPickersUtilsProvider>
            </Container>
            {orderData && (
              <OrderDetailsPage
                orderData={orderData}
                onClose={this.closeOrderDetails.bind(this)}
                isNewOrderDetailsModal={this.state.tabId === 0 ? false : true}
              />
            )}
            {totalSearchedResult !== null &&
              getComponentByCondition(
                isOrders,
                <OrdersTable
                  openOrderDetails={this.openOrderDetails.bind(this)}
                  searchResultList={searchResultList}
                  totalCount={totalCount}
                  currentPage={this.currentPage}
                  rowPerPage={this.rowPerPage}
                  isDrawerOpen={this.state.isDrawerOpen}
                  onChangePage={(page: number) => {
                    this.onChangePage(page);
                  }}
                  tabId={this.state.tabId}
                  handleTabChange={this.handleTabChange}
                  handleMomentDateFormat={this.handleMomentDateFormat}
                  onPrintReceipt={this.handlePrintReceipt}
                  showPaymentModal={this.showPaymentModal}
                  toggleCreditNoteDialog={this.toggleCreditNoteDialog}
                  permissionStatus={this.state.permissionStatus}
                />,
                <CustomerTable
                  openOrderDetails={this.openOrderDetails.bind(this)}
                  searchResultList={searchResultList}
                  totalCount={totalCount}
                  currentPage={this.currentPage}
                  rowPerPage={this.rowPerPage}
                  isDrawerOpen={this.state.isDrawerOpen}
                  onChangePage={(page: number) => {
                    this.onChangePage(page);
                  }}
                  tabId={this.state.tabId}
                  handleTabChange={this.handleTabChange}
                  handleMomentDateFormat={this.handleMomentDateFormat}
                  showPaymentModal={this.showPaymentModal}
                  onPrintReceipt={this.handlePrintReceipt}
                  toggleCreditNoteDialog={this.toggleCreditNoteDialog}
                  permissionStatus={this.state.permissionStatus}
                />
              )}
          </MainContainer>
          {this.state.showPaymentPopup && <PaymentModal
              fromUnpaid={true}
              data-test-id='paymentmodal'
              onPromoCodeApply={() => this.getOrder()}
              open={this.state.showPaymentPopup}
              handleClose={this.handlePaymentModalClose}
              totalItems={this.state.selectedOrder?.attributes?.order_items.length || 0}
              taxPercentage={this.formatTaxPercentage()}
              onPaymentDone={this.handlePaymentDone}
              customerId={String(this.state.selectedOrder?.attributes.account_id) || ''}
              order={this.state.selectedOrder?.attributes as unknown as IOrder}
              paymentClicked={this.state.paymentClicked}
            />}
            {
              renderBaseOnConditions(
                Boolean(this.state.errorMessage),
                <Portal>
                  <CustomSnackbar
                    data-test-id='error-snaclbar'
                    open
                    onClose={this.handleCloseSnackbar}
                    errorMessage={this.state.errorMessage}
                    severity="error"
                    autoHideDuration={3000}
                  />
                </Portal>,
                <></>
              )
            }
    
      </HideInputContainer>
      <CreditNoteDialog
        isCreditNoteDialogOpen={isCreditNoteDialogOpen}
        openCrediNoteModalLoader={openCrediNoteModalLoader}
        hidden={hidden}
        isConfirmationChecked={isConfirmationChecked}
        creditLoader={creditLoader}
        creditNotePresent={creditNotePresent}
        creditNoteReceipt={creditNoteReceipt}
        creditNoteOrder={creditNoteOrder}
        addNote={addNote}
        renderCreditOrderNumber={this.renderCreditOrderNumber}
        handleAddNote={this.handleAddNote}
        hanldeClickPrintCreditNote={this.hanldeClickPrintCreditNote}
        handleConfirmationCheckBox={this.handleConfirmationCheckBox}
        toggleCreditNoteDialog={this.toggleCreditNoteDialog}
        createCreditNoteApiCall={this.createCreditNoteApiCall}
      />
      <CustomSnackbar
        data-test-id='custom-snackbar'
        open={this.state.snackbarOpen}
        onClose={this.handleCloseCustomSnackbar}
        errorMessage={this.state.snackbarMessage}
        severity={this.state.snackbarServerity}
        autoHideDuration={3000}
      />
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
function CreditNoteDialog(props: CreditSearchProps) {
  const {
    isCreditNoteDialogOpen,
    openCrediNoteModalLoader,
    hidden,
    isConfirmationChecked,
    creditLoader,
    creditNotePresent,
    creditNoteReceipt,
    creditNoteOrder,
    addNote,
    renderCreditOrderNumber,
    handleAddNote,
    hanldeClickPrintCreditNote,
    handleConfirmationCheckBox,
    toggleCreditNoteDialog,
    createCreditNoteApiCall,
  } = props;

  const {
    adjustment_reason,
    adjustment_note,
    order_number,
    total_tax,
    sub_total,
    currency,
    total
  } = creditNoteOrder?.attributes || {};

  return (
    <StyledDialogWrapper
      fullWidth
      maxWidth={"md"}
      data-test-id='credit-note-dialog'
      className='mainDialog'
      open={isCreditNoteDialogOpen}
    >
      <Typography className='headerTitle' variant='h4' align='center'>
        {Strings.stringsCreditNotePage_CreateCreditNoteHeader}
      </Typography>
      <CustomLoader loading={openCrediNoteModalLoader} />
      <DialogContent className='dialogContent'>
        <Box className='credit-note-modal-header'>
          <Typography className='orderId'>
            {" "}
            Order ID : {renderCreditOrderNumber(order_number)}
          </Typography>
          {renderBaseOnConditions(
            hidden && !openCrediNoteModalLoader,
            <Typography className='orderId'>
              {" "}
              Reference ID : {order_number}{" "}
            </Typography>,
            <></>
          )}
        </Box>
        <TextLabel>
          {Strings.stringsCreditNotePage_AdjustMentReason}
        </TextLabel>
        <StyledSelectAdjustmentReason
          variant='outlined'
          name='adjustmentReasonId'
          id='adjustmentReasonId'
          value={adjustment_reason}
          MenuProps={CustomMenuProps}
          data-test-id='adjustmentSelectReasonId'
        >
          <MenuItem value={adjustment_reason || ""}> {adjustment_reason}</MenuItem>
        </StyledSelectAdjustmentReason>
        <br></br>
        <br></br>
        <Grid>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          >
            <Typography className='tableHeading' variant='h4'>
              {Strings.stringsCreditNotePage_table_heading}
            </Typography>
            <button
              disabled={!creditNoteReceipt}
              data-test-id='btn-print-credit-note'
              className='printBtn'
              onClick={() => hanldeClickPrintCreditNote()}
            >
              <img src={IconBluePrint} />
              {Strings.stringsListPage_print}
            </button>
          </Box>
          <br></br>
          <CreditorderDetailsTable creditNoteOrder={creditNoteOrder}/>
        </Grid>
        <br></br>
        <br></br>
        <PriceGrid
          container
          className='total_price'
          direction='column'
          justifyContent='flex-end'
          alignItems='flex-end'
        >
          <Grid>Total : {currency} {sub_total}</Grid>
          <Grid>Tax : {currency} {total_tax}
          </Grid>
          <Grid>Total inc. Tax: {currency} {total}</Grid>
        </PriceGrid>
        <Typography className='adjustmentDetails' variant='h6'>
          {Strings.stringsCreditNotePage_AdjustMentDetails}
        </Typography>
        <Typography className='addedNewUpcharge' variant='h6'>
          {adjustment_note}
        </Typography>
        <TextareaAutosize
          minRows={2}
          value={addNote}
          className='textAreaStyles'
          data-test-id='creditNotTextInput'
          onChange={handleAddNote}
          disabled={creditNotePresent}
          placeholder={Strings.stringsCreditNotePage_CreditNote_Add_Note}
        />
      </DialogContent>
      <div className='confirm-text-container'>
        <img
          alt='checkbox'
          data-test-id='confirmationCheckBox'
          onClick={handleConfirmationCheckBox}
          src={isConfirmationChecked ? CheckTrue : CheckFalse}
        />
        <span className='confirmationText'>{Strings.stringsCreditNotePage_ConfirmationText}</span>
      </div>
      <Box className='groupButton'>
        <Grid container direction='row' spacing={1}>
          <Grid item xs={12} md={6}>
            <Button
              disableRipple
              data-test-id='cancel-btn-customer-preference'
              className='cancelButton'
              onClick={() => toggleCreditNoteDialog()}
            >
              {Strings.stringsCreditNotePage_CancelButton}
            </Button>
          </Grid>
          {hidden === false && (
            <Grid item xs={12} md={6}>
              <Button
                disableRipple
                className='addButton'
                data-test-id='create-button-customer-preference'
                onClick={() => createCreditNoteApiCall()}
                disabled={!isConfirmationChecked || creditLoader}
              >
                {creditLoader ? (
                  <CircularProgress
                    style={{ width: 24, height: 24, color: "white" }}
                  />
                ) : (
                  Strings.stringsCreditNotePage_CreateCreditNoteButton
                )}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </StyledDialogWrapper>
  );
};

function CreditorderDetailsTable(props: { creditNoteOrder: ISearchResult | undefined }) {
  const {
    customer,
    currency,
    total,
    created_at,
    tax_percentage
  } = props.creditNoteOrder?.attributes || {};

  function getMomentFormat(dateStr: Date | string) {
    return moment(dateStr).format("DD/MM/YYYY");
  };

  return (
    <MainWrapper>
        <TableContainer component={Paper}>
          <Table aria-label='customized table'>
            <TableHead>
              <MuiTableRow className='tableheaderRow'
                style={{ color: colors().background, background: "#F8FAFC" }}
              >
                <MuiTableCell className="tableHeader">
                  {Strings.stringCreditNoteModalTable_CustomerName}
                </MuiTableCell>
                <MuiTableCell className="tableHeader">
                  {Strings.stringCreditNoteModalTable_OrderCreationDate}
                </MuiTableCell>
                <MuiTableCell className="tableHeader">
                  {Strings.stringCreditNoteModalTable_TotalOrderAmount}
                </MuiTableCell>
                <MuiTableCell className="tableHeader">
                  {Strings.stringCreditNoteModalTable_Tax}
                </MuiTableCell>
                <MuiTableCell className="tableHeader">
                  {Strings.stringCreditNoteModalTable_AdjustmentAmoint}
                </MuiTableCell>
                <MuiTableCell className="tableHeader">
                  {Strings.stringCreditNoteModalTable_RefundAmoun}
                </MuiTableCell>
              </MuiTableRow>
            </TableHead>
            <TableBody data-test-id='tblBody'>
              <MuiTableRow>
                <MuiTableCell>
                  {customer?.full_name}
                </MuiTableCell>
                <MuiTableCell>
                  {getMomentFormat(String(created_at))}
                </MuiTableCell>
                <MuiTableCell>{total}</MuiTableCell>
                <MuiTableCell>
                  <CreditNoteInput
                    value={tax_percentage}
                    className='tax' readOnly
                  />
                </MuiTableCell>
                <MuiTableCell>
                  <CreditNoteInput
                    value={currency + " " + total}
                    className='amount'
                    readOnly
                  />
                </MuiTableCell>
                <MuiTableCell>{currency + " " + total}</MuiTableCell>
              </MuiTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </MainWrapper>
  )
};

function GetCreditNoteButton(props: CreditNoteButtonProps) {
  const { item, toggleCreditNoteDialog, permissionStatus } = props;
  if (!item?.attributes?.credit_note) return <></>;
  const isCreditOrder = item.attributes.credit_order_at;

  return (
    <CreditNoteButton>
      {renderBaseOnConditions(
        isCreditOrder !== null,
        <>
          {permissionStatus.viewCreditOrderAccess && (
            <div className='credit-note-show-button'>
              <button
                data-test-id={"credit-note-view-button-" + item.id}
                onClick={() => toggleCreditNoteDialog(item)}
              >
                <img src={creditNoteIcon} />
                <span style={{ marginTop: "3px" }}>
                  {Strings.credit_note_view_button}
                </span>
              </button>
            </div>
          )}
        </>,
        <>
          {permissionStatus.createCreditNoteAccess && (
            <div className={"credit-note-button"}>
              <button
                onClick={() => toggleCreditNoteDialog(item)}
                data-test-id={"credit-note-create-button-" + item.id}
              >
                <img src={creditNoteIcon} />
                <span style={{ marginTop: "3px" }}> {Strings.credit_note_button}</span>
              </button>
            </div>
          )}
        </>
      )}
    </CreditNoteButton>
  );
}

function RenderCustomTag({tabId, handleTabChange}: IRenderCustomTag) {
  return(
    <TabBox>
    <TabContainer className="ml-20">
      <Paper className="tabbingSection">
        <CustomTabs
          data-test-id='tabGroup'
          value={tabId}
          onChange={(_event: React.ChangeEvent, value: number) => {
            handleTabChange(value);
          }}
          indicatorColor='primary'
          textColor='primary'
          aria-label='icon label tabs example'
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          <CustomTab 
            className={"customTab"}
            label={Strings.plantCleaningVal}
          />
        </CustomTabs>
      </Paper>
    </TabContainer>
    </TabBox>
  )
}

function renderOrderIdColumn(paid_at: Date | null, text="-") {
  return (
    <StyledWrapper><Box className="orderIdColumn">
      <Box>
        {text}
      </Box>
      <StatusDiv>
        {
          paid_at === null ? <CustomRadioUnpaid
            checked
          /> : <CustomRadioPaid
            checked
          />
        }
        <Box className="labelPaidUnpaid" style={{
          color: `${paid_at === null ? '#DC2626' : '#059669'}`
        }}>{`${paid_at === null ? 'Unpaid' : 'Paid'}`}</Box>
      </StatusDiv>
    </Box>
    </StyledWrapper>
  )
}

function CustomerTable({
  openOrderDetails,
  searchResultList,
  totalCount,
  currentPage,
  rowPerPage,
  onChangePage,
  isDrawerOpen,
  tabId,
  handleTabChange,
  onPrintReceipt,
  showPaymentModal,
  toggleCreditNoteDialog,
  permissionStatus
}: ISearchResultList) {
  return (
    <>
    <StylesTableContainer>
      <FlexContainer>
        <div className="d-flex">
          <TablePageTitle>{Strings.searchResult}</TablePageTitle>
          <RenderCustomTag tabId={tabId} handleTabChange={handleTabChange} />
        </div>
      </FlexContainer>
      <TableContainer
        component={Paper}
        className="tableCont"
      >
        <Table stickyHeader>
          <TableHead>
            <SortingTableHeader
              title='Name'
              type='left'
              sortingData={{}}
              sortingKey=''
              width='12vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Email'
              type='middle'
              sortingData={{}}
              sortingKey=''
              width='14vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Mobile No'
              type='middle'
              sortingData={{}}
              sortingKey=''
              width='10vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Address'
              type='middle'
              sortingData={{}}
              sortingKey=''
              width='10vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Notes'
              type='middle'
              sortingData={{}}
              sortingKey=''
              width='10vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Business'
              type='middle'
              sortingData={{}}
              sortingKey=''
              width='10vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Order ID'
              type='middle'
              sortingData={{}}
              sortingKey=''
              width='20vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Order'
              type='middle'
              sortingData={{}}
              sortingKey=''
              width='30vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Store'
              type='middle'
              sortingData={{}}
              sortingKey=''
              width='10vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Status'
              type='right'
              sortingData={{}}
              sortingKey=''
              width='20vw'
              sortingDisabled
            />
          </TableHead>
          <TableBody data-test-id='customerTableData'>
            {searchResultList.map((item: ISearchResult) => {
              const {
                notes,
                store_name,
                order_items,
                order_number,
                is_quick_drop,
                internal_status,
                paid_at,
              } = item.attributes;
              const { full_name, email, full_phone_number, full_address, business } =
                item.attributes.customer || {};

              let height =
                item.attributes.order_items.length > 1
                  ? item.attributes.order_items.length * 94 + 62
                  : 160 + "px";

                  const items = showOrder(
                order_items,
                () => openOrderDetails(item),
                Boolean(is_quick_drop),
                true
              );
              return (
                <StyledTableRow>
                  <SortingTableCell
                    width='9vw'
                    type='left'
                    wrap={true}
                    style={{ ...searchStyle.tabelCellStyle, height: height, minWidth:"100px" }}
                    content={renderOrderIdColumn(paid_at, full_name)}
                  />
                  <SortingTableCell
                    width='14vw'
                    type='middle'
                    wrap={true}
                    style={{ ...searchStyle.tabelCellStyle, height: height, minWidth:"100px" }}
                    content={email ? email : "-"}
                  />
                  <SortingTableCell
                    width='10vw'
                    type='middle'
                    style={{ ...searchStyle.tabelCellStyle, height: height, minWidth:"100px" }}
                    content={full_phone_number ? +full_phone_number : "-"}
                  />
                  <SortingTableCell
                    width='10vw'
                    type='middle'
                    wrap={true}
                    style={{ ...searchStyle.tabelCellStyle, height: height,minWidth:"100px" }}
                    content={showNotes(full_address)}
                  />
                  <SortingTableCell
                    width='10vw'
                    type='middle'
                    wrap={true}
                    style={{ ...searchStyle.tabelCellStyle, height: height }}
                    content={showNotes(notes)}
                  />
                  <SortingTableCell
                    width='10vw'
                    type='middle'
                    style={{ ...searchStyle.tabelCellStyle, height: height }}
                    content={business ? business : "-"}
                  />
                  <SortingTableCell
                    width='20vw'
                    type='middle'
                    wrap={true}
                    style={{ ...searchStyle.tabelCellStyle, height: height, minWidth:"120px" }}
                    content={order_number ? order_number : "-"}
                  />
                  <SortingTableCell
                    width='30vw'
                    type='middle'
                    style={{ ...searchStyle.tabelCellStyle, height: height, minWidth:"200px" }}
                    content={items}
                  />
                  <SortingTableCell
                    width='20vw'
                    type='middle'
                    style={{ ...searchStyle.tabelCellStyle, height: height }}
                    content={store_name ? store_name : "-"}
                  />
                  <SortingTableCell
                    width={paid_at === null ? '20vw' : '13vw'}
                    type='right'
                    content={
                      <TableActions
                        content={
                          checkBusinessLabel(internal_status)
                            ? checkBusinessLabel(internal_status)
                            : "-"
                        }
                        showPayBtn={tabId !== 1 && paid_at === null}
                        handleClick={() => { showPaymentModal && showPaymentModal(item)}}
                        handleClickPrint={() => onPrintReceipt(item)}
                        item={item}
                        toggleCreditNoteDialog={toggleCreditNoteDialog}
                        permissionStatus={permissionStatus}
                      />
                    }
                    wrap={true}
                    style={{
                      height: height,
                      width: "calc(100% - 16px)",
                      fontSize: "12px",
                      lineHeight: "22px",
                      fontWeight: 500,
                      padding:"0 8px"
                    }}
                  />
                </StyledTableRow>
              );
            })}
            {searchResultList.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={9}
                  style={{
                    textAlign: "center",
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  }}
                >
                  {configJSON.noDataMessageVal}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {searchResultList.length != 0 && (
        <TablePagination
          data-test-id='customerPagination'
          component='div'
          count={totalCount}
          page={currentPage - 1}
          rowsPerPage={rowPerPage}
          rowsPerPageOptions={[10]}
          onPageChange={(_event, page: number) => onChangePage(page + 1)}
        />
      )}
    </StylesTableContainer>
    </>
  );
}

function OrdersTable({
  openOrderDetails,
  searchResultList,
  totalCount,
  rowPerPage,
  currentPage,
  onChangePage,
  tabId,
  handleTabChange,
  isDrawerOpen,
  handleMomentDateFormat,
  onPrintReceipt,
  showPaymentModal,
  toggleCreditNoteDialog,
  permissionStatus
}: ISearchResultList) {
  return (
    <>
    <StylesTableContainer>
      <FlexContainer>
        <div className="d-flex">
          <TablePageTitle>{Strings.searchResult}</TablePageTitle>
          <RenderCustomTag tabId={tabId} handleTabChange={handleTabChange} />
        </div>
      </FlexContainer>
      <TableContainer 
        component={Paper}
        className="tableCont"
      >
        <Table stickyHeader>
          <TableHead>
            <SortingTableHeader
              title='Order ID'
              type='left'
              sortingData={{}}
              sortingKey=''
              width='11vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Order Created'
              type='middle'
              sortingData={{}}
              sortingKey=''
              width='6vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Customer'
              type='middle'
              sortingData={{}}
              sortingKey=''
              width='10vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Mobile No'
              type='middle'
              sortingData={{}}
              sortingKey=''
              width='15vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Order'
              type='middle'
              sortingData={{}}
              sortingKey=''
              width='30vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Notes'
              type='middle'
              sortingData={{}}
              sortingKey=''
              width='10vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Paid'
              type='middle'
              sortingData={{}}
              sortingKey=''
              width='6vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Total'
              type='middle'
              sortingData={{}}
              sortingKey=''
              width='8vw'
              sortingDisabled
            />
            <SortingTableHeader
              title='Status'
              type='right'
              sortingData={{}}
              sortingKey=''
              width='12vw'
              sortingDisabled
            />
          </TableHead>
          <TableBody data-test-id='orderTableData'>
            {searchResultList.map((order: ISearchResult) => {
              const {
                notes,
                total,
                currency,
                created_at,
                order_items,
                order_number,
                is_quick_drop,
                internal_status,
                paid_at,
              } = order.attributes;
              const { full_name, full_phone_number, customer_profile } =
                order.attributes.customer || {};
              const paid = order.attributes.order_transactions || [];
              let height =
                order.attributes.order_items.length > 1
                  ? order.attributes.order_items.length * 54 + 62
                  : 160 + "px";
              const items = showOrder(
                order_items,
                () => openOrderDetails(order),
                Boolean(is_quick_drop),
                true
              );
              return (
                <StyledTableRow>
                  <SortingTableCell
                    width='10vw'
                    isElementStyle={false}
                    wrap={true}
                    type='left'
                    style={{ ...searchStyle.tabelCellStyle, height: height, minWidth:"100px" }}
                    content={renderOrderIdColumn(paid_at, order_number)}
                  />
                  <SortingTableCell
                    width='6vw'
                    isElementStyle={false}
                    type='middle'
                    style={{ ...searchStyle.tabelCellStyle, height: height, minWidth:"100px" }}
                    content={
                      created_at && handleMomentDateFormat(created_at, "DD/MM/YYYY")
                    }
                  />
                  <SortingTableCell
                    width='10vw'
                    isElementStyle={false}
                    type='middle'
                    style={{ ...searchStyle.tabelCellStyle, height: height }}
                    content={full_name ? full_name : "-"}
                  />
                  <SortingTableCell
                    width='15vw'
                    isElementStyle={false}
                    type='middle'
                    style={{ ...searchStyle.tabelCellStyle, height: height, minWidth:"100px"}}
                    content={full_phone_number ? full_phone_number : "-"}
                  />
                  <SortingTableCell
                    width='30vw'
                    isElementStyle={false}
                    type='middle'
                    style={{ ...searchStyle.tabelCellStyle, height: height, wordWrap:"breakWord", minWidth:"200px" }}
                    content={items}
                  />
                  <SortingTableCell
                    width='10vw'
                    isElementStyle={false}
                    type='middle'
                    style={{ ...searchStyle.tabelCellStyle, height: height }}
                    content={showNotes(notes)}
                  />
                  <SortingTableCell
                    width='6vw'
                    isElementStyle={false}
                    type='middle'
                    style={{ ...searchStyle.tabelCellStyle, height: height }}
                    content={showPaidOption(paid)}
                  />
                  <SortingTableCell
                    width='8vw'
                    isElementStyle={false}
                    type='middle'
                    style={{ ...searchStyle.tabelCellStyle, height: height, minWidth:"100px" }}
                    content={currency && total ? `${currency} ${total}` : "-"}
                  />
                  <SortingTableCell
                    width='12vw'
                    isElementStyle={false}
                    type='right'
                    content={
                      <TableActions
                        content={checkBusinessLabel(internal_status)}
                        showPayBtn={tabId !== 1 && paid_at === null && !order.attributes.is_quick_drop}
                        handleClick={() => { showPaymentModal && showPaymentModal(order)}}
                        handleClickPrint={() => onPrintReceipt(order)}
                        item={order}
                        toggleCreditNoteDialog={toggleCreditNoteDialog}
                        permissionStatus={permissionStatus}
                      />
                    }
                    style={{
                      ...searchStyle.tabelCellStyle,
                      height: height,
                      width: "calc(100% - 16px)",
                      paddingLeft: 16,
                      minWidth:"150px"
                    }}
                  />
                </StyledTableRow>
              );
            })}
            {searchResultList.length === 0 && (
              <TableRow>
                <TableCell
                  colSpan={9}
                  style={{
                    textAlign: "center",
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  }}
                >
                  {configJSON.noDataMessageVal}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {searchResultList.length != 0 && (
        <TablePagination
          data-test-id='orderPagination'
          component='div'
          count={totalCount}
          page={currentPage - 1}
          rowsPerPage={rowPerPage}
          rowsPerPageOptions={[10]}
          onPageChange={(_event, page: number) => onChangePage(page + 1)}
        />
      )}
    </StylesTableContainer>
    </>
  );
}

function TableActions({ content, showPayBtn=false, handleClick, handleClickPrint, item, toggleCreditNoteDialog, permissionStatus }: 
  { 
    content: React.ReactNode,
    handleClickPrint: () => void 
    showPayBtn?: boolean,  
    handleClick?: React.MouseEventHandler<HTMLButtonElement> 
    toggleCreditNoteDialog: Function;
    item: ISearchResult;
    permissionStatus: OrderPermissionStatus;
  }
) {
  return (
    <div style={{marginTop:"0.5rem"}}>
      <div>{content}</div>
      <FloatingContainer>
        <GetCreditNoteButton
          item={item}
          toggleCreditNoteDialog={toggleCreditNoteDialog}
          permissionStatus={permissionStatus}
        />
        <div className="bottomWrapper">
        {showPayBtn &&
            <StatusButton
              onClick={handleClick}
              data-test-id="Pay"
            >
              Pay
            </StatusButton>
       }
        <img src={IconEdit} />
        <div 
          className={'print'} 
          onClick={() => handleClickPrint()}
        >
          <img src={IconPrint} />
        </div>
        </div>
      </FloatingContainer>
    </div>
  );
}

export function showNotes(notes: string, length: number = 30) {
  if (notes != null && notes != undefined && notes != "") {
    if (notes.length > length) {
      return (
        <NotesTooltip title={<p>{notes}</p>} arrow>
          <span>{notes.slice(0, length)}...</span>
        </NotesTooltip>
      );
    } else {
      return notes;
    }
  } else {
    return "---";
  }
}

export function showOrder(
  order: OrderItems[],
  openOrderDetails: Function,
  isQuickDrop: boolean = false,
  isDetailsShow: boolean = true,
) {
  console.log("Orders::", order, isQuickDrop)
  if (Boolean(isQuickDrop)) {
    return (
      <div>
        {order
          .map((item: OrderItems) => Number(item.attributes.quantity))
          .reduce((previous: number, current: number) => previous + current, 0)}
      </div>
    );
  } else if (order.length === 0) {
    return "--";
  } else {
    return (
      <div>
        {order.map((item: OrderItems, index: number) => {
          if(!item.attributes) return <></>
          return (
            <div key={item.attributes.catalogue.name + index}>
              {item.attributes.catalogue.name}{" "}
              <img style={{ margin: "0 4px" }} src={circleIcon} alt='circle' />{" "}
              {item.attributes.catalogue.product_name} ({item.attributes.catalogue.product_second_name || "--"}) x{" "}
              {item.attributes.quantity}
            </div>
          );
        })}
        {
          isDetailsShow && (
            <DetailsButton
              className="details-button"
              data-test-id='btn-orderDetails'
              onClick={() => openOrderDetails()}
              component={"div"}
            >
              {Strings.details}
            </DetailsButton>
          )
        }
      </div>
    );
  }
}

function showPaidOption(paid: OrderTransactions[]) {
  if (paid.length === 0) {
    return "---";
  } else {
    return paid[0].attributes.payment_method.name;
  }
}

export const DetailsButton = styled(Box)({
  fontSize: 16,
  paddingTop: 12,
  fontWeight: 600,
  color: "#204B9C",
  cursor: "pointer",
  fontFamily: "Montserrat",
  "&:hover": {
    textDecoration: "underline",
  },
  "@media only screen and (max-width: 1024px)": {
    fontSize: 14,
  },
});

const HideInputContainer = styled("span")({
  "& #headerSearch > div:first-child": {
    visibility: "hidden",
  },
});

const FullWidthDateContainer = styled("div")({
  "& > div": {
    width: "100%",
  },
});

const MainContainer = styled("div")({
});

const StyledTableRow = styled(TableRow)({
  "&:last-child > td > div": {
    marginBottom: "16px !important",
  },
  "& > td > div": {
    position: "relative",
    minHeight: "100px !important",
    marginTop: "16px !important",
  },
});

export const NotesTooltip = withStyles({
  tooltip: {
    color: "black",
    fontSize: "12px",
    backgroundColor: "white",
    width: "400px",
    maxWidth: "400px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
  arrow: {
    color: "white",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
})(Tooltip);

const FloatingContainer = styled("div")({
  width: "100%",
  justifyContent: "flex-end",
  alignItems: "center",
  position: "absolute",
  flexDirection: "column",
  display: "flex",
  bottom: 16,
  gap: 16,
  paddingRight:"1rem",
  "& .bottomWrapper": {
    display: "flex",
    gap: "4px",
    width: "100%",
    justifyContent: "center"
  },
  "& .print": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    backgroundColor: "rgba(32, 75, 156, 0.1)",
    width: 33,
    height: 33,
  },
});

const FlexContainer = styled("div")({
  display: "flex",
  paddingBottom: 32,
  alignItems: "center",
  justifyContent: "space-between",
  "& .customTab": {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    margin: "2px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: '12px',
      margin: "0.5px",
      fontWeight: "500",
      padding: "5px"
     },
  },
  "& .d-flex": {
    display: "flex",
    alignItems: "center"
  },
  "& .tabbingSection": {
    backgroundColor: colors().ghostwhite,
    borderRadius: "40px",
    margin: "0 auto",
    boxShadow: "none",
    "& .MuiTabs-root": {
      minWidth:"unset"
    }
  },
});

const Container = styled("div")({
  paddingBottom: 16,
  "& .MuiTextField-root": {
    paddingTop: 0,
  },
  "& .MuiInputBase-root": {
    fontSize: "14px !important",
  },
  "& .MuiSelect-iconOutlined": {
    color: "black",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ECECEC",
  },
});

const StyledDatePicker = styled(KeyboardDatePicker)({
  "& > div:hover": {
    borderColor: "rgba(0, 0, 0, 0.87)",
  },
  "& > div": {
    borderRadius: 8,
    border: "2px solid #ECECEC",
    boxShadow: "0px 0px 1px #ECECEC",
  },
  "& input, & .MuiInputBase-input::placeholder": {
    opacity: 1,
    fontSize: 14,
    color: "#4D4D4D",
    padding: "18.5px 14px",
    fontFamily: "Montserrat",
    "@media only screen and (max-width: 1024px)": {
        fontSize: '12px',
        padding:'14.9px 5px'
    },
  },
  "& .MuiInput-underline::after, & .MuiInput-underline::before, & p": {
    display: "none",
  },
  "@media only screen and (max-width: 1024px)": {
    '& .MuiInputBase-input': {
      fontSize: '12px',
      padding:'14.9px 5px'
    },
    "& div button": {
      padding:"2px 1px"
    }
  },
});

const FieldArray = styled("div")({
  display: "grid",
  gap: 16,
  alignItems: "center",
  justifyContent: "flex-start",
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: "repeat(1, 1fr)",
    gridTemplateAreas:
    `"name"
    "mobile_number"
    "email"
    "address"
    "customer_id"
    "business"
    "payment"
    "payment_type"
    "order_id"
    "notes"
    "paid"
    "paid_date"
    "paid_date_range"
    "placed"
    "placed_date"
    "placed_date_range"`
  ,
  },
  [theme.breakpoints.between('sm', 'md')]: {
    gridTemplateAreas:
    `"name mobile_number"
    "email address"
    "customer_id business"
    "payment payment_type"
    "order_id notes"
    "paid placed"
    "paid_date paid_date_range"
    "placed_date placed_date_range"`
  ,
  gridTemplateColumns: "repeat(2, 1fr)",
  },
  [theme.breakpoints.between('md', 'lg')]: {
    gridTemplateColumns: "repeat(4, 1fr)",
    gridTemplateAreas:
    `"name mobile_number email address"
    "customer_id business payment payment_type"
    "order_id paid paid_date paid_date_range"
    "notes placed placed_date placed_date_range"`
  ,
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateAreas:
      `"name mobile_number email address customer_id business"
    "payment payment_type paid paid_date paid_date_range paid_date_range"
    "order_id notes placed placed_date placed_date_range placed_date_range"`
    ,
    gridTemplateColumns: "repeat(6, 1fr)",
   
  },
});

const FieldArrayCustomer = styled("div")({
  gap: 16,
  display: "grid",
  justifyContent: "flex-start",
  alignItems: "center",
  [theme.breakpoints.down('sm')]: {
    gridTemplateAreas:
      `"name"
    "mobile_number"
    "email"
    "address"
    "customer_id"
    "city_id"
    "business"`
    ,
    gridTemplateColumns: "repeat(1, 1fr)",
  },
  [theme.breakpoints.between('sm', 'md')]: {
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateAreas:
      `"name mobile_number"
    "email address"
    "customer_id city_id"
    "business ."`
    ,
  },
  [theme.breakpoints.between('md', 'lg')]: {
    gridTemplateAreas:
      `"name mobile_number email address"
    "customer_id city_id business ."`
    ,
    gridTemplateColumns: "repeat(4, 1fr)",
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: "repeat(6, 1fr)",
    gridTemplateAreas:
      `"name mobile_number email address customer_id city_id"
      "business . . . . ."`
    ,
  },
});

const HeadingContainer = styled("div")({
  gap: 16,
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",
  "& .MuiInputBase-root": {
    minWidth: 200,
  },
});

const FilterButton = styled(Button)({
  width: 44,
  height: 44,
  padding: 0,
  minWidth: "unset",
});

const TabContainer = styled("div")({
  "& .MuiTabs-flexContainer": {
    gap: 4,
  },
  "&.ml-20": {
    margin: "0 0 0 20px"
  }
});

const searchStyle = {
  tabelCellStyle: {
    fontSize: "14px !important",
    lineHeight: "22px",
    fontWeight: "500 !important",
    wordBreak: "break-all",
    whiteSpace: "pre-wrap !important",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "12px !important",
    }
  },
};

export const BottomContainer = styled(Box)({
  gap: 32,
  display: "flex",
  marginTop: 24, 
  marginBottom: 16,
  "& .resetButton":{
    width: "125px",
    height: "44px",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    borderRadius: "8px",
    marginLeft: 10,
    textTransform: "unset",
    backgroundColor: colors().antiFlashWhite,
    color: colors().slateGray,
  },
  "& .addButtonStyle":{
    width: "128px",
    height: "44px",
    color: colors().white,
    background: colors().cyancobaltblue,
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    borderRadius: "8px",
    marginLeft: 10,
    textTransform: "unset",
  }
});

 const TablePageTitle = styled("div")({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "24px",
  lineHeight: "29px",
  minWidth:"100px",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "14px",
    width: "17%",
  },
  "@media only screen and (max-width: 1280px)": {
    width: "30%",
  },
  "@media only screen and (max-width: 1366px)": {
    width: "30%",
  },
});

const StylesTableContainer = styled(Box)({
  "& .tableCont":{
    marginBottom:"25px",
    minWidth:"800px",
    overflowX:"scroll",
    height: "calc(100vh - 210px)"
  }
})

const MainHeadingContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  gap: 16,
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: 24,
  "& .MuiInputBase-root": {
    minWidth: 200,
  },
  "@media only screen and (max-width: 1024px)": {
    paddingBottom: 16,
  },
});

const StyledWrapper = styled('div')({
  "& .orderIdColumn": {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  "& .labelPaidUnpaid": {
    cursor: "pointer",
    fontFamily: '"Montserrat", sans-serif',
    fontSize: 13,
    fontWeight: 500,
  },
})

const StatusButton = styled("button")({
  padding: "9px 10px",
  background: "rgba(32, 75, 156, 0.1)",
  color: "#204B9C",
  outline: "none",
  border: "none",
  borderRadius: "6px",
  cursor: "pointer",
  fontFamily: '"Montserrat", sans-serif',
  fontSize: 13,
  fontWeight: 600,
});

const StatusDiv = styled("div")({
  padding: "1px 0px",
  outline: "none",
  border: "none",
  borderRadius: "6px",
  marginLeft: -28,
  display: 'flex',
  alignItems: 'center',
  zIndex: 0,
  scale: 0.8
});

const CustomRadioUnpaid = styled(Radio)({
  '& .MuiIconButton-label': {
    color: '#DC2626 !important',
    padding: 0
  },
})
const CustomRadioPaid = styled(Radio)({
  '& .MuiIconButton-label': {
    color: '#059669 !important',
    padding: 0
  },
})

const TabBox = styled(Box)({
  marginRight:"23px"
})

const DateFormWrapper= styled("div")({
  position:"relative",
  display:"flex",
  flexDirection:"column",
  justifyContent:"flex-start",
  gap:5,
  "& .error": {
    position:"absolute",
    fontSize:"10px",
    color:"red",
    bottom:-12,
    left:0,
    minWidth:'145px'
  },
});

const CreditNoteButton = styled("div")({
  // position: "absolute",
  // bottom: "16px",
  // right: "14px",
  "& .credit-note-button": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& button": {
      gap: 6,
      border: 0,
      cursor: "pointer",
      display: "flex",
      padding: "6px 10px",
      textAlign: "center",
      alignItems: "center",
      borderRadius: "6px",
      color: colors().cyancobaltblue,
      backgroundColor: colors().cyancobaltblue + "1A",
      fontWeight: 600,
      "& span": {
        fontSize: "12px",
        "@media only screen and (max-width: 1024px)": {
          fontSize: "11px"
         }
      },
    },
  },
  "& .credit-note-show-button": {
    display: "flex",
    width: "100%",
    alignItems: "center",
    "& button": {
      display: "flex",
      gap: 6,
      cursor: "pointer",
      border: 0,
      padding: "6px 10px",
      textAlign: "center",
      borderRadius: "6px",
      backgroundColor: colors().cyancobaltblue + "1A",
      color: "gray",
      alignItems: "center",
      fontWeight: 600,
      "& span": {
        fontSize: "12px",
        "@media only screen and (max-width: 1024px)": {
          fontSize: "11px"
         }
      },
    },
  },
});
// Customizable Area End
