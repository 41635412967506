import React from "react";

// Customizable Area Start
import {
    Box,
    Button,
    InputLabel,
    Grid,
    MenuItem,
    FormControlLabel,
    Snackbar,
    Hidden,
    InputAdornment,
    Radio,
    styled,
    TextField
} from "@material-ui/core";

import PageContainer from "../../../blocks/navigationmenu/src/PageContainer.web";
import MultiSelectDropdownWeb from "../../../components/src/customComponents/MultiSelectDropdown.web";
import RadioDropdownWeb, {StyledRadio} from "../../../components/src/customComponents/RadioDropdown.web";
import { Formik, FieldArray,FormikTouched, FieldInputProps  } from 'formik';
import * as Yup from 'yup';
import { InputField, CustomFormControl, MultiSelectionBox, CustomMenuItem, CustomPaper } from '../../../components/src/customComponents/CustomTags.web';
import { StyledPageHeader, priceListWebStyle } from './CfPriceList.web';
import Alert from '@material-ui/lab/Alert';
import { colors } from "../../../blocks/utilities/src/Colors";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
import CfProductDetailsController, {
    IOptionList,
    Props,
    configJSON
} from "./CfProductDetailsController";
import { CustomSearchableMultiSelect } from "../../../components/src/customComponent/CustomSearchableMultiSelect.web";
import { CommonIcomSearchComponent } from "../../../components/src/customComponents/CommonIcomSearchComponent";
import { CustomAutoCompleteSearchable } from "../../../components/src/customComponents/CustomAutoCompleteSearchable.web";
import FullHeightSkeleton from "../../../components/src/FullHeightSkeleton.web"
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
interface ServicePrice {
    service_id: number;
    service_name: string;
    price: string;
    sqm: number,
    sqm_price: number,
    weight: number,
    weight_price: number,
    _destroy: boolean;
}

export interface IFormValue {
    service: number[];
    price: [{
        service_id: number;
        service_name: string;
        price: string;
        sqm: number;
        sqm_price: number;
        weight:number;
        weight_price: number;
        _destroy: boolean;
    }];
    section: number[];
    price_list: string;
    product_name: string;
    product_second_name: string;
    product_type: string;
    parent: string;
    measurement: string[];
    pieces: string;
    minimum_washing_cycle: string;
    maximum_washing_cycle: string;
    preference: string;
    conveyor_bag: string;
    conveyor_priority: string;
    slot_space: string;
    image: string;
}

type FormErrors = {
    price_list?: string;
    product_name?: string;
    product_second_name?: string;
    section?: string;
    service?: string;
    parent?: string;
    measurement?: string;
    pieces?: string;
    minimum_washing_cycle?: string;
    maximum_washing_cycle?: string;
    preference?: string;
    conveyor_bag?: string;
    conveyor_priority?: string;
    slot_space?: string;
    price:[{
        sqm: string;
        sqm_price: string;
        weight:string;
        weight_price: string;
    }]
};
type Touched = FormikTouched<IFormValue>;
type GetFieldProps = (name: string, options?: { [key: string]: any }) => FieldInputProps<any>;

// Customizable Area End

export default class CfAddProduct extends CfProductDetailsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    checkError(error: any, touch: any) {
        if (error && touch) {
            return true
        }
    }

    finalErrorMessage(error: any, touch: any) {
        if (error) {
            if (touch) {
                return <div style={priceListWebStyle.fieldError}>
                    {error}
                </div>
            }
            else {
                return null
            }
        }
        else {
            return;
        }
    }

    checkPageTitleText(isEdit:any) {
        if(isEdit.includes("Products-ProductListEdit")){
            return "Edit Product"
        }
        else {
            return "Add Product"
        }
    }

    checkProductAddOrEdit(isEdit:any, values:any) {
        if(isEdit.includes("Products-ProductListEdit")) {
            return this.handleEditProduct(values);
        }
        else {
            return this.handleAddProduct(values);
        }
    }

    checkIsError(errors:any, touched:any) {
        if(errors && touched) {
            return true
        }
    }

    checkErrorAvailability(errors: any, index: number) {
        if (errors?.price?.length) {
            return errors?.price[index]?.price
        }
    }
    checkTouchAvailability(touched: any, index: number) {
        if (touched?.price?.length) {
            return touched?.price[index]?.price
        }
    }

    dynamicFieldLabel(values: any, firstMsg: string, secondMsg: string) {
        if(values.price[0].service_id === "") {
            return firstMsg
        }
        return secondMsg
    }

    checkAddorSaveButton(isEdit: any) {
        if (isEdit.includes("Products-ProductListEdit")) {
            return "Save"
        }
        else {
            return "Add"
        }
    }

    setEmptyField(isOne: any) {
        if (isOne) {
            return this.checkEmptyField()
        }
    }

    checkEmptyField() {
        if (this.state.serviceSelectedData.length % 2 !== 0) {
            return <Hidden xsDown smDown>
                <Grid item md={6} lg={6} xl={6} >
                </Grid>
            </Hidden>
        }
        return <></>
    }

    SQMFieldRender(price:ServicePrice, index:number, values:IFormValue, errors:FormErrors, touched:Touched, getFieldProps:GetFieldProps) {
        if (!price._destroy) {
            return (<React.Fragment key={index}>
                 <Grid item xs={12} sm={12} md={6}>
                 <InputLabel style={productDetailsWebStyle.fieldLabel}>SQM*</InputLabel>

                    <InputField
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.target.addEventListener(
                            "wheel",
                            function (e: WheelEvent) {
                                e.preventDefault();
                            },
                            { passive: false }
                            );
                        }}
                        inputProps={{
                        "data-test-id": "sqmText",
                        }}
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position='end'>
                                <StyledEndText>Sqm</StyledEndText>
                            </InputAdornment>
                            ),
                        }}
                        id="sqm"
                        type="number"
                        {...getFieldProps(`price[${index}].[sqm]`)}
                        fullWidth={true}
                        variant="outlined"
                        placeholder="Type Here"
                    />
                   <div id="sqm" style={priceListWebStyle.fieldError}>
                      {errors?.price && errors?.price[index]?.sqm}
                   </div>

                </Grid>
                <Grid id="pricegrid" item xs={12} sm={12} md={6}>
                    <InputLabel id="price" style={productDetailsWebStyle.fieldLabel}>{this.dynamicFieldLabel(values, "Price", "Price for")} {price.service_name}*</InputLabel>
                    <InputField
                        type="number"
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.target.addEventListener(
                            "wheel",
                            function (e: WheelEvent) {
                                e.preventDefault();
                            },
                            { passive: false }
                            );
                        }}
                        error={this.checkError(this.checkErrorAvailability(errors, index), this.checkTouchAvailability(touched, index))}
                        id={`price[${index}].[${price.sqm_price}]`}
                        inputProps={{
                            "data-test-id": `priceText-${index}`,
                        }}
                        fullWidth={true}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start"><span style={{ color: '#000', fontSize: '14px', fontWeight: 500 }}>SAR</span></InputAdornment>
                            ),
                            sx: {
                                borderRadius: 20,
                                border: 'none',
                                outline: 'none',
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                fontWeight: 500
                            }
                        }}
                        {...getFieldProps(`price[${index}].[sqm_price]`)}
                        placeholder={`${this.dynamicFieldLabel(values, "Enter Price", "Enter Price for")} ${price.service_name}`}
                        value={price.sqm_price}
                        variant="outlined"
                    />
                   <div style={priceListWebStyle.fieldError}>
                    {errors?.price && errors?.price[index]?.sqm_price}
                   </div>
                </Grid>

            </React.Fragment>
            )
        }
    }

    WidthFieldRender(price:ServicePrice, index:number, values:IFormValue, errors:FormErrors, touched:Touched, getFieldProps:GetFieldProps) {
        if (!price._destroy) {
            return (<React.Fragment key={index}>
                 <Grid item xs={12} sm={12} md={6}>
                 <InputLabel style={productDetailsWebStyle.fieldLabel}>Weight*</InputLabel>

                    <InputField
                        inputProps={{
                        "data-test-id": "weightText",
                        }}
                        type="number"
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.target.addEventListener(
                            "wheel",
                            function (e: WheelEvent) {
                                e.preventDefault();
                            },
                            { passive: false }
                            );
                        }}
                        id="weight"
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position='end'>
                                <StyledEndText>Kg</StyledEndText>
                            </InputAdornment>
                            ),
                        }}
                        placeholder="Type Here"
                        fullWidth={true}
                        {...getFieldProps(`price[${index}].[weight]`)}
                        variant="outlined"
                    />
                     <div style={priceListWebStyle.fieldError}>
                       {errors?.price && errors?.price[index]?.weight}
                   </div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <InputLabel style={productDetailsWebStyle.fieldLabel}>{this.dynamicFieldLabel(values, "Price", "Price for")} {price.service_name}*</InputLabel>
                    <InputField
                        inputProps={{
                            "data-test-id": `priceText1`,
                        }}
                        type="number"
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.target.addEventListener(
                            "wheel",
                            function (e: WheelEvent) {
                                e.preventDefault();
                            },
                            { passive: false }
                            );
                        }}
                        error={this.checkError(this.checkErrorAvailability(errors, index), this.checkTouchAvailability(touched, index))}
                        fullWidth={true}
                        id={`price[${index}].[${price.weight_price}]`}
                        placeholder={`${this.dynamicFieldLabel(values, "Enter Price", "Enter Price for")} ${price.service_name}`}
                        variant="outlined"
                        {...getFieldProps(`price[${index}].[weight_price]`)}
                        value={price.weight_price}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start"><span style={{ color: '#000', fontSize: '14px', fontWeight: 500 }}>SAR</span></InputAdornment>
                            ),
                            sx: {
                                borderRadius: 20,
                                border: 'none',
                                outline: 'none',
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                fontWeight: 500
                            }
                        }}
                    />
                     <div style={priceListWebStyle.fieldError}>
                       {errors?.price && errors?.price[index]?.weight_price}
                   </div>
                </Grid>

            </React.Fragment>
            )
        }
    }

    renderFieldsForService(price:ServicePrice, index:number, values:IFormValue, errors:FormErrors, touched:Touched, getFieldProps:GetFieldProps) {
        return (
            <React.Fragment key={`service-${index}`}>
                {values.measurement.includes('SQM') && (
                    <Grid container spacing={2} className="measurementstyle">
                        {this.SQMFieldRender(price, index, values, errors, touched, getFieldProps)}
                    </Grid>
                )}
                {values.measurement.includes('Weight') && (
                    <Grid container spacing={2} className="measurementstyle">
                        {this.WidthFieldRender(price, index, values, errors, touched, getFieldProps)}
                    </Grid>
                )}
            </React.Fragment>
        );
    }

    checkIsDestroy(price: any, index: number, values: any, errors: any, touched: any, getFieldProps: any) {
        if (!price._destroy) {
            return (<React.Fragment key={index}>
                <Grid item xs={12} sm={12} md={6}>
                    <InputLabel style={productDetailsWebStyle.fieldLabel}>{this.dynamicFieldLabel(values, "Price", "Price for")} {price.service_name}</InputLabel>
                    <InputField
                        inputProps={{
                            "data-test-id": `priceText`,
                        }}
                        type="number"
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                            e.target.addEventListener(
                            "wheel",
                            function (e: WheelEvent) {
                                e.preventDefault();
                            },
                            { passive: false }
                            );
                        }}
                        error={this.checkError(this.checkErrorAvailability(errors, index), this.checkTouchAvailability(touched, index))}
                        id={`price[${index}].[${price.service_name}]`}
                        name={`price[${index}].[${price.service_name}]`}
                        fullWidth={true}
                        placeholder={`${this.dynamicFieldLabel(values, "Enter Price", "Enter Price for")} ${price.service_name}`}
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start"><span style={{ color: '#000', fontSize: '14px', fontWeight: 500 }}>SAR</span></InputAdornment>
                            ),
                            sx: {
                                borderRadius: 20,
                                border: 'none',
                                outline: 'none',
                                backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                fontWeight: 500
                            }
                        }}
                        {...getFieldProps(`price[${index}].[price]`)}
                        value={price.price}
                    />
                    {this.finalErrorMessage(errors?.price?.length && errors?.price[index]?.price, touched?.price?.length && touched?.price[index]?.price)}
                    <Box style={productDetailsWebStyle.radioGroup}>
                        <Radio
                            data-test-id={`priceRadio-${price.service_id}`}
                            id={`priceRadio-${price.service_id}`}
                            checked={this.state.defaultPriceService == price.service_id}
                        onClick={() => this.setState({defaultPriceService: price.service_id})}
                            value={price.service_id}
                            name="radio-buttons"
                            inputProps={{
                                'aria-label': 'price_default_radio'
                            }}
                            icon={
                                <span
                                    style={productDetailsWebStyle.radioIcon}
                                />
                            }
                            checkedIcon={
                                <span
                                    style={productDetailsWebStyle.radioCheckedIcon}
                                />
                            }
                        />
                        <p>Set as default</p>
                    </Box>
                </Grid>
                {this.setEmptyField(values.price.length - 1 === index)}
            </React.Fragment>
            )
        }
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const validationSchema = Yup.object().shape({
          product_name: Yup.string().trim().required("Product name is required."),
          section: Yup.string().required("Section is required."),
          service: Yup.string().when("product_type", {
            is: (value: number) => value !== 1,
            then: Yup.string().required("Service is required."),
          }),
          parent: Yup.string().when("product_type", {
            is: (value: number) => value == 2,
            then: Yup.string().required("Parent is required."),
          }),
          measurement: Yup.string().when("product_type", {
            is: (value: number) => value !== 1,
            then: Yup.string().required("Measurement is required."),
          }),
          pieces: Yup.number().when("measurement", {
              is: (value: string) => value && value?.split(',').includes('Quantity'),
              then: Yup.number().min(1, 'Pieces must be a positive number.').required("Pieces is required.").nullable(),
          }).nullable(),
          minimum_washing_cycle: Yup.number().when("product_type", {
            is: (value: number) => value !== 1,
            then: Yup.number()
              .min(0, "Minimum washing cycle must be a positive number.")
              .required("Minimum washing cycle is required."),
          }).nullable(),
          maximum_washing_cycle: Yup.number().when("product_type", {
            is: (value: number) => value !== 1,
            then: Yup.number()
              .required("Maximum washing cycle is required.")
              .moreThan(Yup.ref("minimum_washing_cycle"), "Maximum washing cycle must be greater than Minimum washing cycle.")
          }).nullable(),
          preference: Yup.string().when("product_type", {
            is: (value: number) => value !== 1,
            then: Yup.string().required("Preference is required.")
          }).nullable(),
            price: Yup.array().of(
                Yup.object().shape({
                  sqm: Yup.number().when('$measurement', {
                    is: (value) => value.includes('SQM'),
                    then: Yup.number().required('SQM is required').min(0, 'SQM must be a positive number'),
                    otherwise: Yup.number(),
                  }),
                  sqm_price: Yup.number().when('$measurement', {
                    is: (value) => value.includes('SQM'),
                    then: Yup.number().required('SQM Price is required').min(0, 'SQM Price must be a positive number'),
                    otherwise: Yup.number(),
                  }),
                  weight: Yup.number().when('$measurement', {
                    is: (value) => value.includes('Weight'),
                    then: Yup.number().required('Weight is required').min(0, 'Weight must be a positive number'),
                    otherwise: Yup.number(),
                  }),
                  weight_price: Yup.number().when('$measurement', {
                    is: (value) => value.includes('Weight'),
                    then: Yup.number().required('Weight Price is required').min(0, 'Weight Price must be a positive number'),
                    otherwise: Yup.number(),
                  }),
                })
              ),
        });

        let isEdit:any = this.props.navigation.history.location.pathname;

        const ITEM_HEIGHT = 80;
        const ITEM_PADDING_TOP = 8;
        const MenuProps: any = {
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 200,
                },
            },
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
            },
            transformOrigin: {
                vertical: "top",
                horizontal: "left"
            },
            getContentAnchorEl: null
        };
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                <>
                <CustomLoader loading={this.state.saveBtnLoading}/>
                    {this.state.isLoading ? <CustomLoader loading={this.state.isLoading}/> :
                    <AddWrapper>
                        <Box style={{ ...priceListWebStyle.contentWrapper }}>
                            <div style={priceListWebStyle.headerWrapper}>
                                <StyledPageHeader style={priceListWebStyle.pageHeader}>{this.checkPageTitleText(isEdit)}</StyledPageHeader>
                            </div>
                            <Formik
                                data-test-id="formikAddProduct"
                                validationSchema={validationSchema}
                                initialValues={this.state.addProductInitialvalues}
                                onSubmit={(values: any) => { this.checkProductAddOrEdit(isEdit, values) }}
                                enableReinitialize
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    setFieldValue,
                                    getFieldProps
                                }: any) => (
                                    <form onSubmit={handleSubmit} data-test-id="addProductForm">
                                        <CustomPaper>
                                            <Grid container spacing={3} id="modal-content">
                                                <Grid item xs={12} sm={12} md={6} >
                                                <InputLabel  className="label">Price List</InputLabel>
                                                    <CustomFormControl variant="outlined" style={productDetailsWebStyle.formControl}>
                                                        <MultiSelectionBox
                                                            data-test-id="priceListDropdown"
                                                            labelId={"price_list"}
                                                            id={'price_list'}
                                                            name={'price_list'}
                                                            fullWidth
                                                            displayEmpty
                                                            value={values.price_list}
                                                        inputProps={{ disableunderline:"true" }}
                                                            MenuProps={MenuProps}
                                                            disabled
                                                        >
                                                            <MenuItem style={{ color: '#4d4d4d', display: 'none' }} value="">{"Select Price List"}</MenuItem>
                                                            {this.state.dropdownOptions?.priceList?.length !== 0 && this.state.dropdownOptions?.priceList !== undefined ?
                                                                this.state.dropdownOptions?.priceList?.map((option: any, index: any) => {
                                                                    return <CustomMenuItem key={option.id} value={option.id}>
                                                                        <FormControlLabel checked={values.price_list == option.id} control={<StyledRadio />} label={""} name="option" />
                                                                        {option.option}
                                                                    </CustomMenuItem>
                                                                }) :
                                                                <CustomMenuItem disabled>
                                                                    No data
                                                                </CustomMenuItem>
                                                            }) :
                                                            <CustomMenuItem disabled>
                                                                No data
                                                            </CustomMenuItem>
                                                        
                                                    </MultiSelectionBox>
                                                </CustomFormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} >
                                              <InputLabel className="label">Product Type</InputLabel>
                                              <RadioDropdownWeb
                                                  data-test-id="productTypeDropdown"
                                                  fieldLabel={'Product Type'}
                                                  fieldName={'product_type'}
                                                  isError={this.checkIsError(errors.product_type, touched.product_type)}
                                                  options={this.state.dropdownOptions.product_type}
                                                  placeholder="Select Product Type"
                                                  errorMessage={errors.product_type}
                                                  withIcon={false}
                                                  setFieldValue={setFieldValue}
                                                  className={'product_type'}
                                                  {...getFieldProps('product_type')}
                                                  onChange={(event: React.ChangeEvent<{value: number}>) => { 
                                                    this.handleProductTypeSelect(event, setFieldValue, values)}
                                                  }
                                                  />
                                              </Grid>
                                              {renderBaseOnConditions(
                                                values.product_type !== 1,
                                                <Grid
                                                  item
                                                  xs={12}
                                                  sm={12}
                                                  md={6}
                                                  style={{ marginTop: "20px" }}
                                                >
                                                  <StyledTextField
                                                    inputProps={{
                                                      "data-test-id": "printtagTxt",
                                                    }}
                                                    error={this.checkError(
                                                      errors.print_tag,
                                                      touched.print_tag
                                                    )}
                                                    id='print_tag'
                                                    name='print_tag'
                                                    fullWidth={true}
                                                    placeholder='Print Tag'
                                                    variant='outlined'
                                                    InputProps={{
                                                      readOnly: true,
                                                      endAdornment: (
                                                        <InputAdornment position='end'>
                                                          <CustomSwitch
                                                            data-test-id='field-status-switch'
                                                            checked={this.state.printTagSwitch}
                                                            onChange={(checked) => {
                                                              this.handleChangesStatus(
                                                                checked,
                                                                setFieldValue
                                                              );
                                                            }}
                                                          />
                                                        </InputAdornment>
                                                      ),
                                                    }}
                                                  />
                                                  {this.finalErrorMessage(
                                                    errors.print_tag,
                                                    touched.print_tag
                                                  )}
                                                </Grid>,
                                                <></>
                                              )}
                                            <Grid item xs={12} sm={12} md={6} >
                                                <InputLabel className="label">Product Name*</InputLabel>
                                                <InputField
                                                    inputProps={{
                                                      "data-test-id": "productNameTxt",
                                                    }}
                                                    error={this.checkError(errors.product_name, touched.product_name)}
                                                    id="product_name"
                                                    name="product_name"
                                                    fullWidth={true}
                                                    placeholder="Enter Name"
                                                    variant="outlined"
                                                    {...getFieldProps('product_name')}
                                                />
                                                {this.finalErrorMessage(errors.product_name, touched.product_name)}
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} >
                                                <InputLabel className="label">Product Arabic Name</InputLabel>
                                                <InputField
                                                    inputProps={{
                                                      "data-test-id": "productSecondNameTxt",
                                                      dir:"rtl"
                                                    }}
                                                    error={this.checkError(errors.product_second_name, touched.product_second_name)}
                                                    id="product_second_name"
                                                    name="product_second_name"
                                                    fullWidth={true}
                                                    placeholder="Enter Arabic Name"
                                                    variant="outlined"
                                                    {...getFieldProps('product_second_name')}
                                                />
                                                {this.finalErrorMessage(errors.product_second_name, touched.product_second_name)}
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6}  >
                                                    <InputLabel className="label" style={{paddingBottom: "10px"}}>Section*</InputLabel>
                                                    <CustomSearchableMultiSelect
                                                        id="section"
                                                        name="section"
                                                        dataTestId="sectionDropdown"
                                                        checkSelectAllText={() => { }}
                                                        value={this.state.sectionSelectedData}
                                                        optionList={this.state.dropdownOptions.section}
                                                        placeHolderText="Select Section"
                                                        handleScroll={this.handleScrollSectionDropdown}
                                                        handleClickOnSelectAll={() => { }}
                                                        isOnBlurFunction={() => this.debouncedFunction("", ()=>{})}
                                                        debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, ()=>{}) }}
                                                        handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleSectionMultiSelect(value, option, setFieldValue) }}
                                                        emptyDataMessage="No Section Found"
                                                        handleEmptyAutoSelectValue={() => { }}
                                                        selectedOptionForList={this.state.selectedSectionsForList}
                                                        isError={this.checkIsError(errors.section, touched.section)}
                                                    />
                                                    {this.finalErrorMessage(errors.section, touched.section)}
                                                </Grid>
                                                { values.product_type !== 1 && 
                                                  <Grid item xs={12} sm={12} md={6} >
                                                    <InputLabel className="label" style={{paddingBottom: "10px"}}>Service*</InputLabel>
                                                    <CustomSearchableMultiSelect
                                                        id="service"
                                                        name="service"
                                                        dataTestId="serviceDropdown"
                                                        checkSelectAllText={() => { }}
                                                        value={this.state.serviceSelectedData}
                                                        optionList={this.state.dropdownOptions.service}
                                                        placeHolderText="Select Service"
                                                        handleScroll={this.handleScrollServiceDropdown}
                                                        handleClickOnSelectAll={() => { }}
                                                        isOnBlurFunction={() => this.debouncedFunction("",()=>{})}
                                                        debouncedFunction={(getValue: string) => { this.debouncedFunction(getValue, ()=>{}) }}
                                                        handleSelectOptions={(value: { id: string, option: string }[], option: { id: string, option: string }) => { this.handleServiceMultiSelect(value, option, setFieldValue, values) }}
                                                        emptyDataMessage="No Service Found"
                                                        handleEmptyAutoSelectValue={() => { }}
                                                        selectedOptionForList={this.state.selectedServiceForList}
                                                        isError={this.checkIsError(errors.service, touched.service)}
                                                    />
                                                    {this.finalErrorMessage(errors.service, touched.service)}
                                                </Grid>
                                                }
                                                {values.product_type === 2 && <Grid item xs={12} sm={12} md={6} >
                                                    <InputLabel className="label parentMarginBottom">Parent</InputLabel>
                                                    <CustomAutoCompleteSearchable 
                                                        dataTestID="parent"
                                                        value={this.state.parentValue} 
                                                        optionList={this.state.dropdownOptions.parent} 
                                                        debouncedFunction={(getValue: string) => { 
                                                          this.debouncedFunction(getValue, this.handleAutoCompleteChange)
                                                        }}
                                                        handleScroll={this.handleScrollParentDropdown} 
                                                        handleSelectOptions={(value:{ id: string | number, option: string })=>{this.handleSelectParent(value,setFieldValue)}} 
                                                        selectedItem={this.state.parentValue} 
                                                        placeholder={"Search Parent"} 
                                                        isError={this.checkIsError(errors.parent, touched.parent)}
                                                    />
                                                    {this.finalErrorMessage(errors.parent, touched.parent)}
                                            
                                                </Grid>}
                                                {renderBaseOnConditions(
                                                  values.product_type !== 1,
                                                  <Grid item xs={12} sm={12} md={6} >
                                                  <InputLabel className="label">{configJSON.measurementFiledLabel}</InputLabel>
                                                  <MultiSelectDropdownWeb
                                                    data-test-id="measurementDropdown"
                                                    data-testid="measurementDropdown"
                                                    fieldLabel={'Measurement'}
                                                    fieldName={'measurement'}
                                                    isError={this.checkIsError(errors.measurement, touched.measurement)}
                                                    options={this.state.dropdownOptions.measurement}
                                                    placeholder={"Select Measurement"}
                                                    selectOnClick={(getData: IOptionList) => {
                                                        this.handleMeasurementMultiSelect(getData)
                                                    }}
                                                    selectedOptions={this.state.measurmentSelectedData}
                                                    errorMessage={errors.measurement}
                                                    onChange={(event: React.ChangeEvent<HTMLSelectElement>)=> this.handleMeasurementSelect(event, setFieldValue)}
                                                    value={values['measurement']}
                                                  />
                                                </Grid>,
                                                  <></>
                                                )}
                                            
                                                {values.measurement.includes('Quantity') && <Grid item xs={12} sm={12} md={6} >
                                                    <InputLabel className="label">Pieces*</InputLabel>
                                                    <InputField
                                                        inputProps={{
                                                            "data-test-id": "piecesText",
                                                        }}
                                                        type="number"
                                                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                                                            e.target.addEventListener(
                                                            "wheel",
                                                            function (e: WheelEvent) {
                                                                e.preventDefault();
                                                            },
                                                            { passive: false }
                                                            );
                                                        }}
                                                        error={this.checkError(errors.pieces, touched.pieces)}
                                                        id="pieces"
                                                        name="pieces"
                                                        fullWidth={true}
                                                        placeholder="Enter Pieces"
                                                        variant="outlined"
                                                        {...getFieldProps('pieces')}
                                                    />
                                                    {this.finalErrorMessage(errors.pieces, touched.pieces)}
                                                </Grid>}

                                    
                                            {values.service.length > 0 
                                            && values.price.map((price: ServicePrice, index:number) => (
                                                <React.Fragment key={`service-${index}`}>
                                                    {this.renderFieldsForService(price, index, values, errors, touched, getFieldProps)}
                                                </React.Fragment>
                                            ))}

                                               {this.isOnlyPriceField(values)  && <FieldArray
                                                    name="price"
                                                    data-testid="dynamicField"
                                                    render={() => {
                                                        return values.price?.map((price: any, index: number) => {
                                                            return this.checkIsDestroy(price, index, values, errors, touched, getFieldProps)
                                                        })
                                                    }}
                                                /> }
                                                {renderBaseOnConditions(
                                                  values.product_type !== 1,
                                                  <Grid item xs={12} sm={12} md={6} >
                                                    <InputLabel className="label">Minimum Washing Cycle*</InputLabel>
                                                    <InputField
                                                        inputProps={{
                                                            "data-test-id": "minNumText",
                                                        }}
                                                        type="number"
                                                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                                                            e.target.addEventListener(
                                                            "wheel",
                                                            function (e: WheelEvent) {
                                                                e.preventDefault();
                                                            },
                                                            { passive: false }
                                                            );
                                                        }}
                                                        error={this.checkError(errors.minimum_washing_cycle, touched.minimum_washing_cycle)}
                                                        id="minimum_washing_cycle"
                                                        name="minimum_washing_cycle"
                                                        fullWidth={true}
                                                        placeholder="Enter Minimum Washing Cycle (in days)"
                                                        variant="outlined"
                                                        {...getFieldProps('minimum_washing_cycle')}
                                                    />
                                                    {this.finalErrorMessage(errors.minimum_washing_cycle, touched.minimum_washing_cycle)}
                                                </Grid>,
                                                  <></>
                                                )}
                                                {renderBaseOnConditions(
                                                  values.product_type !== 1,
                                                  <Grid item xs={12} sm={12} md={6} >
                                                    <InputLabel className="label">Maximum Washing Cycle*</InputLabel>
                                                    <InputField
                                                        inputProps={{
                                                            "data-test-id": "maxNumText",
                                                        }}
                                                        type="number"
                                                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                                                            e.target.addEventListener(
                                                            "wheel",
                                                            function (e: WheelEvent) {
                                                                e.preventDefault();
                                                            },
                                                            { passive: false }
                                                            );
                                                        }}
                                                        error={this.checkError(errors.maximum_washing_cycle, touched.maximum_washing_cycle)}
                                                        id="maximum_washing_cycle"
                                                        name="maximum_washing_cycle"
                                                        fullWidth={true}
                                                        placeholder="Enter Maximum Washing Cycle (in days)"
                                                        variant="outlined"
                                                        {...getFieldProps('maximum_washing_cycle')}
                                                    />
                                                    {this.finalErrorMessage(errors.maximum_washing_cycle, touched.maximum_washing_cycle)}
                                                  </Grid>,
                                                  <></>
                                                )}
                                                {renderBaseOnConditions(
                                                  values.product_type !== 1,
                                                  <Grid item xs={12} sm={12} md={6} >
                                                    <InputLabel className="label">Preference*</InputLabel>
                                                    <RadioDropdownWeb
                                                        data-test-id="preferenceDropdown"
                                                        fieldLabel={'Preference'}
                                                        fieldName={'preference'}
                                                        isError={this.checkIsError(errors.preference, touched.preference)}
                                                        options={this.state.dropdownOptions.preference}
                                                        placeholder={"Select Preference"}
                                                        errorMessage={errors.preference}
                                                        className={'preference'}
                                                        withIcon={true}
                                                        setFieldValue={setFieldValue}
                                                        {...getFieldProps('preference')}
                                                    />
                                                  </Grid>,
                                                  <></>
                                                )}
                                                {renderBaseOnConditions(
                                                  values.product_type !== 1,
                                                  <Grid item xs={12} sm={12} md={6} >
                                                    <InputLabel className="label">Conveyor Bag</InputLabel>
                                                    <RadioDropdownWeb
                                                        data-test-id="conveyorBagDropdown"
                                                        fieldLabel={'Conveyor Bag'}
                                                        fieldName={'conveyor_bag'}
                                                        isError={this.checkIsError(errors.conveyor_bag, touched.conveyor_bag)}
                                                        options={this.state.dropdownOptions.conveyor_bag}
                                                        placeholder={"Select Conveyor Bag"}
                                                        className={'coverage_boy'}
                                                        errorMessage={errors.conveyor_bag}
                                                        withIcon={false}
                                                        setFieldValue={setFieldValue}
                                                        {...getFieldProps('conveyor_bag')}
                                                    />
                                                  </Grid>,
                                                  <></>
                                                )}
                                                {renderBaseOnConditions(
                                                  values.product_type !== 1,
                                                    <Grid item xs={12} sm={12} md={6} >
                                                    <InputLabel className="label">Conveyor Priority</InputLabel>
                                                    <RadioDropdownWeb
                                                        data-test-id="conveyorPriorityDropdown"
                                                        fieldLabel={'Conveyor Priority'}
                                                        fieldName={'conveyor_priority'}
                                                        isError={this.checkIsError(errors.conveyor_priority, touched.conveyor_priority)}
                                                        options={this.state.dropdownOptions.conveyor_priority}
                                                        placeholder={"Select Conveyor Priority"}
                                                        className={'conveyor_priority'}
                                                        errorMessage={errors.conveyor_priority}
                                                        withIcon={false}
                                                        setFieldValue={setFieldValue}
                                                        {...getFieldProps('conveyor_priority')}
                                                      />
                                                    </Grid>,
                                                  <></>
                                                )}
                                                {renderBaseOnConditions(
                                                  values.product_type !== 1,
                                                  <Grid item xs={12} sm={12} md={6} >
                                                  <InputLabel className="label">Slot Space</InputLabel>
                                                  <RadioDropdownWeb
                                                      data-test-id="slotSpaceDropdown"
                                                      fieldLabel={'Slot Space'}
                                                      fieldName={'slot_space'}
                                                      isError={this.checkIsError(errors.slot_space, touched.slot_space)}
                                                      options={this.state.dropdownOptions.slot_space}
                                                      placeholder={"Select Slot Space"}
                                                      className={'slot_space'}
                                                      errorMessage={errors.slot_space}
                                                      withIcon={false}
                                                      setFieldValue={setFieldValue}
                                                      {...getFieldProps('slot_space')}
                                                    />
                                                  </Grid>,
                                                  <></>
                                                )}
                                                <Grid item xs={12} sm={12} md={6} >
                                                    <InputLabel className="label lableLast">Image</InputLabel>
                                                    <CommonIcomSearchComponent
                                                        isError={this.checkIsError(errors.image, touched.image)}
                                                        value={[]}
                                                        optionList={this.state.dropdownOptions.image}
                                                        debouncedFunction={(value:string) => this.handleDebounceImage(value)}
                                                        handleScroll={this.handleGetImageScroll}
                                                        handleSelectOptions={(value:{ id: string | number, option: string, image: string })=>this.handleImageSelect(value,setFieldValue)}
                                                        selectedItem={this.state.selectedImage}
                                                        listBoxHeight={"170px"}
                                                    />
                                                    {this.finalErrorMessage(errors.image, touched.image)}

                                                </Grid>
                                                <Hidden xsDown smDown>
                                                    <Grid item md={6} lg={6} xl={6} >
                                                    </Grid>
                                                </Hidden>
                                            </Grid>
                                        </CustomPaper>
                                    <Box style={{marginTop: '48px', marginBottom: '40px', gap: "10px 0"}}>
                                            <Button
                                                className="cancelBtn"
                                                data-test-id="btnCancel"
                                                onClick={() => this.handleBackToProductList()}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className="addBtn"
                                                data-test-id='btn-submit'
                                                type="submit"
                                            >
                                                {this.checkAddorSaveButton(isEdit)}
                                            </Button>
                                        </Box>
                                    </form>
                                )}
                            </Formik>
                    <Snackbar
                        data-test-id="snackbar"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.openSnackbar}
                        autoHideDuration={1700}
                        onClose={() => this.handleCloseSuccessSnack()}
                    >
                        <Alert data-test-id="alert-snackbar" severity={"success"} >
                            {this.state.successSnackBarMessage}
                        </Alert>
                    </Snackbar>
                    <Snackbar
                        data-test-id="snackbar"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={this.state.errorSnakbar}
                        autoHideDuration={2000}
                        onClose={this.handleCloseError}
                    >
                        <Alert
                            data-test-id="alert-snackbar"
                            severity={"error"}
                        >
                            {this.state.snakbarErrorMsg}
                        </Alert>
                    </Snackbar>
                        </Box>
                    </AddWrapper>
                     }
                </>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const productDetailsWebStyle = {
    fieldLabel: {
        marginBottom: "2px",
        fontSize: "16px",
        color: "#334155",
        fontWeight: 600,
        fontFamily: 'Montserrat'
    },
    fieldSet: {
        padding: "12px 8px"
    },
    formControl: {
        width: '100%',
        border: '1px solid #ECECEC',
        borderRadius: '8px',
        marginTop: '10px'
    },
    radioGroup: {
        display: "flex",
        alignItems: "center"
    },
    radioIcon: {
        display: "inline-block",
        borderRadius: "51%",
        width: 20,
        height: 20,
        border: "1px solid #64748C",
    },
    radioCheckedIcon: {
        display: "inline-block",
        width: 20,
        borderRadius: "51%",
        height: 20,
        border: "6px solid #204B9D",
        color: "#204b9c",
    }
};

const AddWrapper = styled("div")({
   "& .fullWidth": {
    maxWidth: "100% !important"
   },
   "& .parentMarginBottom":{
    marginBottom:"13px !important"
   },
   "& .lableLast": {
    marginBottom: "13px !important"
   },
    "& .error": {
        color: "rgb(244, 67, 54)"
    },
    "& .label": {
        marginBottom: "2px",
        fontSize: "16px",
        color: colors().charcoal,
        fontWeight: 600,
        fontFamily: 'Montserrat',
        "@media only screen and (max-width: 1024px)": {
            fontSize: '13px',
        },
    },
    "& .MuiInputBase-root": {
        "@media only screen and (max-width: 1024px)": {
            maxHeight:"fit-content"
        },
    },
    "& .measurementstyle": {
        padding: "12px"
    },
    "& .addBtn": {
        backgroundColor: colors().cyancobaltblue,
        color: colors().white,
        width: '128px',
        height: '56px',
        marginLeft: "32px",
        textTransform: "none",
        border: "0",
        "@media (max-width: 1024px)": {
            width: '128px',
            height: '44px',
     padding:"10px 0"
        },
        "&:hover": {
            backgroundColor: colors().cyancobaltblue,
            color: colors().white
        },
        "@media (max-width: 768px)": {
            padding: "10px 0",
        },
    borderRadius:"8px",
    },
    "& .cancelBtn": {
     borderRadius:"8px",
        backgroundColor: colors().antiFlashWhite,
        color: colors().slateGray,
        width: '128px',
        height: '56px',
        textTransform: 'none',
        border: "0",
    padding:"16px",
        "@media (max-width: 1024px)": {
            width: '128px',
            height: '44px',
      padding:"10px 0"
        },
        "@media (max-width: 768px)": {
            padding: "10px 0",
        },
    },
})


 const StyledTextField = styled(TextField)({
    borderRadius: 8,
    border: "1px solid var(--border, #ECECEC)",
    marginTop: "10px",
    "& .MuiInputBase-input": {
        color: colors().charcoal,
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 600,
      },
    "@media only screen and (max-width: 1024px)": {
      "& .MuiInputBase-input": {
        fontSize: "12px",
        padding: "14.9px 14px",
      },
    },
  });

const StyledEndText = styled('span')({
    fontSize: "16px",
    color: colors().slateGray,
    fontWeight: 700,
    fontFamily: 'Montserrat',
})

// Customizable Area End
