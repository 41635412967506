import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { debounce, isNumber,uniqBy } from "lodash";

// Customizable Area Start
type Option = {
  id: string;
  option: string;
}
import { apiCall, handleLogout, makeApiMessage, updateMultiSelectData } from "../../../components/src/common";
export interface IOptionList {
  id: number,
  name?: string,
  option: string
}

export interface IDropdownResponse {
  id: number,
  name: string
}

interface ServicePrice {
  id?: number;
  _destroy: boolean;
  service_id: number;
  service_name: string;
  sqm: number,
  price: string;
  sqm_price: number,
  weight: number,
  weight_price: number,
}

import { GetIconsResponse } from "packages/blocks/RolesPermissions2/src/utils";
import { IFormValue } from "./CfProductDetails.web";
import { FormikValues } from "formik";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}
interface ImageInterface {
  id:number,
  option:string,
  image:string
};

interface ServiceDropdownAttributes {
  id: number;
  name: string;
  icon: string;
  name_translation: string;
}

interface DropDownInterface {
  id: string;
  type: string;
  attributes: ServiceDropdownAttributes;
}
interface Service {
  service_id: number;
  service_name: string;
  price: number | string; // Updated to allow number or string
  _destroy: boolean;
  sqm: number;
  sqm_price: number;
  weight:number;
  weight_price: number;
}
interface ProductType {
  id: number;
  value: string;
}

interface ParentDropdownAttributes {
  id: number;
  product_name: string;
  product_second_name: string;
  name: string;
  product_type: ProductType;
}

interface ParentDropdown {
  id: string;
  type: string;
  attributes: ParentDropdownAttributes;
}

interface Product {
  price_list: string;
  product_name: string;
  product_second_name: string;
  section: number[];
  service: number[];
  product_type: string;
  parent: string;
  measurement: string;
  pieces: string;
  price: Service[];
  minimum_washing_cycle: number; // Changed to number
  maximum_washing_cycle: number; // Changed to number
  preference: number; // Changed to number
  conveyor_bag: number; // Changed to number
  conveyor_priority: number; // Changed to number
  slot_space: number; // Changed to number
  image: number; // Changed to number
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  addProductInitialvalues: any;
  staticOptions: any;
  dropdownOptions: any;
  sectionSelectedData:any;
  serviceSelectedData:any;

  isSelectAll: boolean;
  openSnackbar: boolean;
  getCatalogueId: any;
  formValues: any;
  defaultPriceService: string;
  errorSnakbar: boolean;
  snakbarErrorMsg: string;
  successSnackBarMessage: string;
  measurmentSelectedData:IOptionList[];
  printTagSwitch:boolean
  sectionPage: number;
  ServicePage:number;
  parentListPage:number;
  iconAutoCompleteValue: string;
  sectionAutoCompleteValue: string;
  serviceAutoCompleteValue: string;

  isSectionSelectAll: boolean;
  isServiceSelectAll: boolean;

  selectedSectionsForList: Option[]
  selectedServiceForList: Option[]
  sectionLists: Option[],
  serviceLists: Option[],
  selectedImage: { id: string | number, option: string, image: string }
  productNextPage:number;
  currentIconData:GetIconsResponse[];
  responseIconList:GetIconsResponse[];
  sectionSelectedIds:number[]|null;
  responseServiceList:{ id: string | number, option: string }[]
  responseParentList:{ id: string | number, option: string }[]
  currentServiceData:{ id: string | number, option: string }[]
  currentParentData:{ id: string | number, option: string }[]
  currentSectionData:{ id: string | number, option: string }[]
  responseSectionList:{ id: string | number, option: string }[]
  editServiceDetail: [{
    id:number,
    service_name: string,
    service_id: string
    price: string
    sqm: string
    sqm_price: string
    weight: string
    weight_price: string
    _destroy: boolean,
  }];
  parentValue:{ id: string | number, option: string },
  selectedSectionIDs:number[]|string[],
  isLoading: boolean;
  saveBtnLoading: boolean;
  tempPriceValues: Array<ServicePrice>;
  isParentProductType: boolean;
  autoCopmpleteValue: string;
  parentProductlistDropPage: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfProductDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPriceListApiCallId: string = "";
  addProductApiCallId: string = "";
  EditProductApiCallId: string = "";
  getProductDetailsApiCallId: string = "";
  getSectionListApiCallId: string = "";
  getPreferenceListApiCallId: string = "";
  getImagesListApiCallId: string = "";
  getParentListApiCallId: string = "";
  getServiceListApiCallId: string = "";
  getConveyerDropdownOptionId: string = "";
  getConveyerPrioritiesDropdownOptionId: string = "";
  getSpaceSlotsDropdownOptionId: string = "";
  getProductTypesDropdownOptionId: string = "";
  authToken: string = "";
  disableLoadMoreSection = false;
  disableLoadMoreService=false

  async componentDidMount() {
    const authToken = localStorage.getItem("token");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
    }
    if(this.props.navigation.getParam('productId') !== undefined){
      this.getProductDetails();
  }
    this.getPriceList();
    this.getSectionList();
    this.getPreferenceList();
    this.getServiceList([]);
    this.getParentList();
    this.getEnumDropdownOptions("conveyor_bag");
    this.getEnumDropdownOptions("conveyor_priority");
    this.getEnumDropdownOptions("slot_space");
    this.getEnumDropdownOptions("product_type");
  }
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      sectionLists: [],
      serviceLists: [],
      enableField: false,
      // Customizable Area Start
      addProductInitialvalues: {
        print_tag:false,
        price_list: '',
        product_name: '',
        product_second_name: '',
        section: [],
        service: [],
        product_type: "",
        parent: "",
        measurement: [],
        pieces: '',
        sqm: '',
        weight: '',
        price: [{
          service_name: '',
          service_id: "",
          price: '',
          sqm: '',
          sqm_price: '',
          weight: '',
          weight_price: '',
          _destroy: false,
        }],
        minimum_washing_cycle: '',
        maximum_washing_cycle: '',
        preference: '',
        conveyor_bag: '',
        conveyor_priority: '',
        slot_space: '',
        image: '',
      },
      staticOptions: [
        {id: 1, option: 'test1'},
        {id: 2, option: 'test2'},
        {id: 3, option: 'test3'},
        {id: 4, option: 'test4'},
      ],
      dropdownOptions: {
        priceList: [],
        section: [],
        service: [],
        product_type: [],
        parent: [],
        measurement: [
          {id: 'Quantity', option: 'Quantity'},
          {id: 'SQM', option: 'SQM'},
          {id: 'Weight', option: 'Weight'}
        ],
        preference: [],
        conveyor_bag: [],
        conveyor_priority: [],
        slot_space: [],
        image: [],
      },
      sectionSelectedData: [],
      serviceSelectedData: [],
      isSelectAll: false,
      isServiceSelectAll: false,
      openSnackbar: false,
      getCatalogueId: 0,
      formValues: '',
      defaultPriceService: '',
      errorSnakbar: false,
      snakbarErrorMsg: '',
      successSnackBarMessage: '',
      measurmentSelectedData: [],
      printTagSwitch: false,
      sectionPage: 1,
      ServicePage:1,
      parentListPage:1,
      iconAutoCompleteValue: '',
      sectionAutoCompleteValue: '',
      serviceAutoCompleteValue:"",
      isSectionSelectAll: false,
      saveBtnLoading:false,
      selectedSectionsForList: [],
      selectedServiceForList: [],
      selectedImage: {} as { id: string | number, option: string, image: string },
      productNextPage:1,
      currentIconData:[],
      responseIconList:[],
      sectionSelectedIds:null,
      responseServiceList:[],
      responseParentList:[],
      currentServiceData:[] as { id: string | number, option: string }[],
      currentSectionData:[] as { id: string | number, option: string }[],
      currentParentData:[] as { id: string | number, option: string }[],
      responseSectionList:[],
      editServiceDetail: [{
        id:0,
        service_name: '',
        service_id: "",
        price: '',
        sqm: '',
        sqm_price: '',
        weight: '',
        weight_price: '',
        _destroy: false,
      }],
      parentValue:{} as { id: string | number, option: string },
      selectedSectionIDs:[],
      isLoading: false,
      tempPriceValues: [],
      isParentProductType: false,
      autoCopmpleteValue: '',
      parentProductlistDropPage: 1
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.getPricelistApiCallResponse(apiRequestCallId, responseJson);
      
      this.getSectionListApiCallResponse(apiRequestCallId, responseJson)

      this.getPreferenceListApiCallResponse(apiRequestCallId, responseJson)
    
      this.getImagesListApiCallResponse(apiRequestCallId, responseJson)

      this.getParentListApiCallResponse(apiRequestCallId, responseJson)
    
      this.getServiceListApiCallResponse(apiRequestCallId, responseJson)
    
      this.addProductApiCallResponse(apiRequestCallId, responseJson)
    
      this.EditProductApiCallResponse(apiRequestCallId, responseJson)
    
      this.getProductDetailsApiCallResponse(apiRequestCallId, responseJson);

      this.getEnumDropdownOptionsResponse(message);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  checkGetResponse(responseJson: any) {
    if (responseJson && !responseJson.errors) {
      return true
    }
    else {
      handleLogout(this.props.navigation, responseJson?.errors);
      return false
    }
  }

  getPricelistApiCallResponse(apiRequestCallId: any, responseJson: any) {
    let isEditForm = this.props.navigation.history.location.pathname?.includes("ProductListAdd");
    if (apiRequestCallId === this.getPriceListApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({
          isLoading : false
        })
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            priceList: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.name } })
          }
        }));
        this.setState(() => ({
          addProductInitialvalues: {
            ...this.state.addProductInitialvalues,
            price_list: isEditForm ? responseJson?.data?.filter((list:any) => {return list.attributes.is_master_price_list})[0]?.id : '',
          }
        }), () => {
          if(isEditForm === false) this.getParentList();
          else this.getImagesList()
        });
        
      }
    }
  }

  getSectionListApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getSectionListApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        const sectionWholeData = [...this.state.responseSectionList,...responseJson.data]
        const parsedSectionList:{id:number|string,option:string}[] = sectionWholeData.map((data: GetIconsResponse)=>{
          return {id:data.attributes.id,option:data.attributes.name}
        })
        this.setState(() => ({
          currentSectionData:responseJson.data?.map((item:DropDownInterface) => { return { id: item.attributes.id, option: item.attributes.name } }),
          responseSectionList:sectionWholeData,
          dropdownOptions: {
            ...this.state.dropdownOptions,
            section: parsedSectionList
          }
        }))
      }
    }
  }

  getPreferenceListApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getPreferenceListApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState(() => ({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            preference: responseJson.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.preference_first_name, image: item?.attributes?.gallery_icon?.image } })
          }
        }))
      }
    }
  }

  getImagesListApiCallResponse(apiRequestCallId: any, responseJson: any) {
    let isAddForm = this.props.navigation.history.location.pathname?.includes("ProductListAdd");
    if (apiRequestCallId === this.getImagesListApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        const iconWholeDataValue = uniqBy([...this.state.responseIconList,...responseJson.data], "id")
    
        const parsedIconListData:ImageInterface[] = iconWholeDataValue.map((data: GetIconsResponse)=>{
          return {id:data.attributes.id,image:data.attributes.image,option:data.attributes.name}
        })
        if (isNumber(this.state.selectedImage.id) && parsedIconListData.every(item => item.id !== this.state.selectedImage.id)) {
          parsedIconListData.unshift(this.state.selectedImage as ImageInterface)
        }
        this.setState(() => ({
          currentIconData:responseJson.data,
          responseIconList:iconWholeDataValue,
          dropdownOptions: {
            ...this.state.dropdownOptions,
            image: uniqBy(parsedIconListData,"id")
          }
        }))
      } 
    }
  }
  getParentListApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getParentListApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        const parentWholeData = [...this.state.responseParentList,...responseJson.data]
        const parsedParentList:{id:number|string,option:string}[] = parentWholeData.map((data: ParentDropdown)=>{
          return {id:data.attributes.id,option:data.attributes.product_name}
        })
        this.setState(() => ({
          currentParentData:responseJson.data?.map((item:DropDownInterface) => { return { id: item.attributes.id, option: item.attributes.name } }),
          responseParentList:parentWholeData,
          dropdownOptions: {
            ...this.state.dropdownOptions,
            parent: uniqBy(parsedParentList, "id")
          }
        }))
      }
    }
  }

  getServiceListApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getServiceListApiCallId) {
      if (this.checkGetResponse(responseJson)) {        
        const serviceWholeDataValue = [...this.state.responseServiceList,...responseJson.data]
        const parsedServiceListData:{id:number|string,option:string}[] = serviceWholeDataValue.map((data: GetIconsResponse)=>{
          return {id:data.attributes.id,option:data.attributes.name}
        })
        this.setState(() => ({
          currentServiceData:responseJson.data?.map((item:DropDownInterface) => { return { id: item.attributes.id, option: item.attributes.name } }),
          responseServiceList:serviceWholeDataValue,
          dropdownOptions: {
            ...this.state.dropdownOptions,
            service: parsedServiceListData
          }
        }))
      }
    }
  }

  errorMessgaeCreate = (responseJson: any) => {
    const error_key = Object.keys(responseJson.errors)[0];
    return responseJson.errors[error_key][0];
  }

  addProductApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.addProductApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({
          openSnackbar: true,
          successSnackBarMessage: "Product added successfully.",
        });
        setTimeout(() => {
          this.setState({saveBtnLoading:false})
          this.props.navigation.navigate("ProductList")
        }, 1000)
      } else {
        this.setState({ 
          saveBtnLoading:false,
          errorSnakbar: true,
          snakbarErrorMsg: this.errorMessgaeCreate(responseJson),
          addProductInitialvalues:this.state.formValues 
        })
      }
    }
  }

  EditProductApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.EditProductApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        this.setState({
          openSnackbar: true,
          successSnackBarMessage: "Product edited successfully.",
        });
        setTimeout(() => {
          this.setState({saveBtnLoading:false})
          this.props.navigation.navigate("ProductList")}
          , 1000)
      }
      else {
        this.setState({ 
          saveBtnLoading:false,
          errorSnakbar: true,
          snakbarErrorMsg: this.errorMessgaeCreate(responseJson),
        })
      }
    }
  }

  checkForDefaultPrice(responseJson: any) {
    if(responseJson.data?.attributes?.catalogue_variants[0]?.attributes?.catalogue_variants_services?.data.length > 0) {
    responseJson.data?.attributes?.catalogue_variants[0]?.attributes?.catalogue_variants_services?.data.forEach(
      (item: any) => {
        if (item.attributes.default) {
          this.setState({
            defaultPriceService: item.attributes.service.data.attributes.id,
          });
        }
      }
    );
    if (this.state.defaultPriceService == "") {
      this.setState({
        defaultPriceService:
          responseJson.data?.attributes?.catalogue_variants[0]?.attributes
            ?.catalogue_variants_services?.data[0].attributes.service.data
            .attributes.id,
      });
    }
    } else {
      return;
    }
  }
handleSetParent = (parentId:string) => { return parentId ? parentId : ''}

parseCurrentImage = (imageData?: {id: number; name: string; image: string}) => imageData ? {
  id : imageData.id,
  option: imageData.name,
  image: imageData.image
}
:
{
  id: "",
  option: "",
  image: ""
}

  handleReturnIsParentType = (typeId: number) => {
    if(typeId == 1) {
      return true;
    } else {
      return false
    };
  }

  getProductDetailsApiCallResponse(apiRequestCallId: any, responseJson: any) {
    if (apiRequestCallId === this.getProductDetailsApiCallId) {
      if (this.checkGetResponse(responseJson)) {
        
        // Make service price default in edit product
          this.checkForDefaultPrice(responseJson);

        let newPrice: any = [], newServices: any = [], newServicesData: any = [];
        const selectedSection = responseJson.data?.attributes?.sections?.data?.map((item: {attributes:{id:number,section_name:string}}) => { return {id: item.attributes.id, option: item.attributes.section_name} })
        const sectionIds =  responseJson.data?.attributes?.sections?.data?.map((item: {attributes:{id:number}}) => { return item.attributes.id })
        const currentImage = this.parseCurrentImage(responseJson.data?.attributes?.catalogue_variants[0]?.attributes?.image)

        responseJson.data?.attributes?.catalogue_variants[0]?.attributes?.catalogue_variants_services?.data.map((item: any) => {
          newPrice.push({
            price: item.attributes.price,
            service_id: item.attributes.service.data.attributes.id,
            service_name: item.attributes.service.data.attributes.name,
            sqm: Number(item.attributes.sqm),
            sqm_price: Number(item.attributes.sqm_price),
            weight: Number(item.attributes.weight),
            weight_price: Number(item.attributes.weight_price),
            id: item.attributes.id,
            _destroy: false
          })
          newServices.push(item.attributes.service.data.attributes.id)
          newServicesData.push({ id: item.attributes.service.data.attributes.id, option: item.attributes.service.data.attributes.name })
        });
        this.setState({selectedSectionsForList: selectedSection,selectedServiceForList: newServicesData})
        if(responseJson.data?.attributes?.product_type?.value ==="Sub-Product" && this.state.dropdownOptions.parent.length===0){
          this.setState({
            parentValue:{id:responseJson.data?.attributes?.parent?.data?.id*1,option:responseJson.data?.attributes?.parent?.data?.attributes?.product_name},
          })
          
          this.getParentList(responseJson.data?.attributes?.catalogue_variants[0]?.attributes?.price_list?.id)
        }
        this.setState(() => ({
          selectedSectionIDs:responseJson.data?.attributes?.sections?.data?.map((item: any) => { return item.attributes.id }),
          addProductInitialvalues: {
            ...this.state.addProductInitialvalues,
            price_list: responseJson.data?.attributes?.catalogue_variants[0]?.attributes?.price_list?.id,
            product_name: responseJson.data?.attributes?.product_name,
            product_second_name: responseJson.data?.attributes?.product_second_name,
            product_type: responseJson.data?.attributes?.product_type?.id,
            parent: this.handleSetParent(responseJson.data?.attributes?.parent?.data?.id) ,
            measurement: responseJson.data?.attributes?.catalogue_variants[0]?.attributes?.measurement_type,
            pieces: responseJson.data?.attributes?.catalogue_variants[0]?.attributes?.pieces,
            price: newPrice,
            minimum_washing_cycle: responseJson.data?.attributes?.minimum_washing_cycle,
            maximum_washing_cycle: responseJson.data?.attributes?.maximum_washing_cycle,
            preference: responseJson.data?.attributes?.preference_id,
            conveyor_bag: responseJson.data?.attributes?.conveyor_bag?.id,
            conveyor_priority: responseJson.data?.attributes?.conveyor_priority?.id,
            slot_space: responseJson.data?.attributes?.slot_space?.id,
            image: currentImage.id,
            section: responseJson.data?.attributes?.sections?.data?.map((item: any) => { return item.attributes.id }),
            service: newServices,
            print_tag: responseJson.data?.attributes?.is_print_tag,
          },
          selectedImage: currentImage,
          isParentProductType: this.handleReturnIsParentType(responseJson.data?.attributes?.product_type?.id)
        }),()=> {
          this.getImagesList()
          this.getServiceList(sectionIds)
        })        
        this.setState({
          printTagSwitch: responseJson.data?.attributes?.is_print_tag,
          sectionSelectedData: responseJson.data?.attributes?.sections?.data?.map((item: any) => { return { id: item.attributes.id, option: item.attributes.section_name } }),
          serviceSelectedData: newServicesData,
          getCatalogueId: responseJson.data?.attributes?.catalogue_variants[0]?.id,
          measurmentSelectedData: responseJson.data?.attributes?.catalogue_variants[0]?.attributes?.measurement_type.map((item: any) => { return { id: item, option: item } }),
          editServiceDetail:newPrice,
        }, () => {
          if (this.state?.sectionSelectedData?.length === this.state?.dropdownOptions?.section?.length) {
            this.setState({ isSelectAll: true })
        }
          if (this.state?.serviceSelectedData?.length === this.state?.dropdownOptions?.service?.length) {
            this.setState({ isServiceSelectAll: true })
        }
        })
      }
    }
  }

  handleBackToProductList = () => {
    this.props.navigation.navigate("ProductList")
  }

  getPriceList() {

    let apiUrl = `${configJSON.getPriceListApiEndPoint}?dropdown=true&price_list_to_copy_dropdown=true&token=${this.authToken}`;
    const getAccount = apiCall({
      httpBody: {},
      header: {},
      url: apiUrl,
      httpMethod: configJSON.getPriceListApiMethod,
    });

    this.getPriceListApiCallId = getAccount.messageId;
    this.setState({
      isLoading : true
    })
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleHttpBodyObj = (values:any) => {
    console.log("Values::", values)
    const restObj = {
      product_name: values?.product_name,
      product_second_name: values?.product_second_name,
      product_type_id: values?.product_type ? values?.product_type : 0,
      parent_id: values?.parent,
      minimum_washing_cycle: values?.minimum_washing_cycle,
      maximum_washing_cycle: values?.maximum_washing_cycle,
      preference_id: values?.preference,
      conveyor_bag_id: values?.conveyor_bag,
      conveyor_priority_id: values?.conveyor_priority,
      slot_space_id: values?.slot_space,
      category_ids: values?.section,
      is_print_tag: values?.print_tag ? true : false,
    }
    return restObj;
  }

  handleAddProduct(values:any) {
    this.setState({saveBtnLoading:true,formValues: values});
    values.price.forEach((item: any) => {
      if (item.service_id == this.state.defaultPriceService) {
        item["default"] = true;
      } else {
        item["default"] = false;
      }
    });

    let httpBody = {
      data: {
        attributes: {
          ...this.handleHttpBodyObj(values),
          catalogue_variants_attributes: [
            {
              price_list_id: values?.price_list,
              measurement_type: values?.measurement,
              image_id: values?.image,
              pieces: !values.measurement.includes('Quantity') ? "" : values?.pieces,
              active: true,
              catalogues_services_attributes: values?.price
            }
          ]
        }
      }
    };

    let headers:any = { "Content-type": "application/json" }

    let apiUrl = `${configJSON.getProductListApiEndPoint}?token=${this.authToken}`;
    const getAccount = apiCall({
      httpBody: httpBody,
      header: headers,
      url: apiUrl,
      httpMethod: configJSON.addProductListApiMethod,
    });

    this.addProductApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }
  
  handleEditProduct(values:any) {
    this.setState({saveBtnLoading:true})
    let getProductEditId: any = this.props.navigation.getParam('productId');
    values.price.forEach((item: any) => {
      if (item.service_id == this.state.defaultPriceService) {
        item["default"] = true;
      } else {
        item["default"] = false;
      }
    });

    const { tempPriceValues } = this.state;
    if(values.product_type == 1) {
      const newArray = tempPriceValues.map((price: ServicePrice) => {
        const newInputValue = {
          _destroy: true,
          id: price.id,
          service_id: price.service_id,
          service_name: price.service_name,
          sqm: price.sqm,
          price: price.price,
          sqm_price: price.sqm_price,
          weight: price.weight,
          weight_price: price.weight_price,
        }
        return newInputValue;
      });
      values.price = newArray
    }

    let httpBody = {
      data: {
        attributes: {
          ...this.handleHttpBodyObj(values),
          catalogue_variants_attributes: [
            {
              id: this.state.getCatalogueId,
              price_list_id: values?.price_list,
              measurement_id: values?.measurement,
              measurement_type: values?.measurement,
              image_id: values?.image,
              pieces:  !values.measurement.includes('Quantity') ? "" : values?.pieces,
              active: true,
              catalogues_services_attributes: values?.price
            }
          ]
        }
      }
    };

    let headers:any = {
      "Content-type": "application/json" 
    }
    const token = localStorage.getItem("token");

    let apiUrl = `${configJSON.getProductListApiEndPoint}/${getProductEditId}?token=${token}`;
    const getAccount = apiCall({
      httpBody: httpBody,
      header: headers,
      url: apiUrl,
      httpMethod: configJSON.editProductListApiMethod,
    });

    this.EditProductApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getProductDetails() {
    let getProductEditId: any = this.props.navigation.getParam('productId');
    let selectedPricelist = localStorage.getItem("selectedPricelistId");

    let apiUrl = `${configJSON.getProductListApiEndPoint}/${getProductEditId}?price_list_id=${selectedPricelist}&token=${this.authToken}`;
    const getAccount = apiCall({
      httpBody: {},
      header: {},
      url: apiUrl,
      httpMethod: configJSON.getProductListApiMethod,
    });

    this.getProductDetailsApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getSectionList() {
    let apiUrl = `${configJSON.getSectionListApiEndPoint}?token=${this.authToken}&page_no=${this.state.sectionPage}&dropdown=true`;
    const getAccount = apiCall({
      httpBody: {},
      header: {},
      url: apiUrl,
      httpMethod: configJSON.getSectionListApiMethod,
    });

    this.getSectionListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getPreferenceList() {

    let apiUrl = `${configJSON.getPreferenceListApiEndPoint}?dropdown=true`;
    let headers = {
      token: this.authToken
    }
    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: apiUrl,
      httpMethod: configJSON.getPreferenceListApiMethod,
    });

    this.getPreferenceListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getImagesList() {
    let apiUrl = configJSON.getImagesListApiEndPoint+ `?page_no=${this.state.productNextPage}`;
    let headers = {
      token: this.authToken
    }
    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: apiUrl,
      httpMethod: configJSON.getImagesListApiMethod,
    });

    this.getImagesListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.selectedSectionIDs.length!==this.state.selectedSectionIDs.length){
      this.setState({
        parentListPage:1,
        responseParentList:[]
      })
      this.getParentList()
    }
  }
  
  getParentList(PriceId?:string) {
    const parentListId = this.state.addProductInitialvalues?.price_list?this.state.addProductInitialvalues?.price_list:PriceId
    let filterQuery
    if(this.state.selectedSectionIDs.length===0){
      filterQuery="filter_by[section_ids]=[]"
    }else if(this.state.selectedSectionIDs[0]==="All"){
      filterQuery="filter_by[section_ids]=[All]"
    }else{
      filterQuery=`filter_by[section_ids]=${this.state.selectedSectionIDs}`
    }

    const searchQuery = this.state.autoCopmpleteValue ? `&filter_by[query]=${this.state.autoCopmpleteValue}` : '';

    let apiUrl = `${configJSON.getParentListApiEndPoint}?token=${this.authToken}&price_list_id=${parentListId}&page_no=${this.state.parentListPage}&${filterQuery}&dropdown=true` + searchQuery;
    
    const getAccount = apiCall({
      httpBody: {},
      header: {},
      url: apiUrl,
      httpMethod: configJSON.getParentListApiMethod,
    });

    this.getParentListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  getServiceList(selectedData:number[]|string[]|unknown[]) {
  let sectionSelected
  if(selectedData.length===0){
    sectionSelected="&filter_by[query]=&filter_by[section_ids][]="
  }else if (selectedData[0]==="All"){
    sectionSelected=""
  }else {
    sectionSelected = `&filter_by[query]=&filter_by[section_ids][]=${selectedData}`
  }
    let apiUrl = `${configJSON.getServiceListApiEndPoint}?page_no=${this.state.ServicePage}&dropdown=true${sectionSelected}`;

    let headers = {
      token: this.authToken
    }

    const getAccount = apiCall({
      httpBody: {},
      header: headers,
      url: apiUrl,
      httpMethod: configJSON.getServiceListApiMethod,
    });

    this.getServiceListApiCallId = getAccount.messageId;
    runEngine.sendMessage(getAccount.id, getAccount);
  }

  handleMeasurementMultiSelect(getData: {id: number, option: string}) {
    let idCheck = getData?.id;
    let newArray;
    if(this.state.measurmentSelectedData.some((object: {id: number, option: string}) => object.id === idCheck)) {
      newArray = this.state.measurmentSelectedData?.filter((objc: {id: number, option: string}) => objc.id !== idCheck);    
      this.setState({measurmentSelectedData: newArray});
    }
    else 
    {
      this.setState({ measurmentSelectedData: [...this.state.measurmentSelectedData, getData] },
        () => {
          if (this.state?.measurmentSelectedData?.length === this.state?.dropdownOptions?.measurement?.length) {
            this.setState({ isSelectAll: true })
          }
        })
    }
  }


  setTrueValue(getData: {id: number, option: string}, values: {
    price: {
      _destroy: boolean, id?: number, price: string, service_id: number, service_name: string
    }[]
  }) {
    return values?.price.forEach((item: { _destroy: boolean, id?: number, price: string, service_id: number, service_name: string}) => { 
      if (item.service_id === getData.id) { item._destroy = true } })
  }

  handleServiceSelect = (event: React.ChangeEvent<{value: string}>, setFieldValue: Function) => {
    const { defaultPriceService, dropdownOptions } = this.state
    setFieldValue('service', event.target.value)
    if(!defaultPriceService || !Array(...event.target.value).includes(defaultPriceService) || !Array(...event.target.value).includes('selectAll'))
      this.setState({defaultPriceService: event.target.value[0]})
    if(Array(...event.target.value).includes('selectAll'))                                                      
      this.setState({defaultPriceService: dropdownOptions.service[0].id })
  }

  handleMeasurementSelect = (event: React.ChangeEvent<{value: string}>, setFieldValue: Function) => {
    setFieldValue('measurement', event.target.value)
  }

  addingNewPriceTextField(values: any, getData: {id: number, option: string}) {
    if (values?.price[0]?.service_id === "") {
      this.setTrueValue(getData, values);
      values.price = [{ service_name: getData?.option, service_id: getData.id, [getData.option.replace(" ", "_")]: "", price: "", _destroy: false }]
    }
    else {
      return this.addingNewPriceTextField1(values, getData)
    }
  }

  addingNewPriceTextField1(values: {price: {
    _destroy: boolean, id?: number, price: string, service_id: number, service_name: string
  }[]}, getData: {id: number, option: string}) {
    if(values?.price.some((object:any) => object.service_id === getData.id)) {
      return values?.price.forEach((item: any) => { if (item.service_id === getData.id) { item._destroy = false } })
    }
    else {
      this.setTrueValue(getData, values);
      return values.price.push({ service_name: getData.option, service_id: getData.id, [getData.option.replace(" ", "_")]: "", price: "", _destroy: false })
    }
  }


  handleProductSelectAll(values?: any) {
    if (this.state.isSelectAll) {
      this.setState({ isSelectAll: false, sectionSelectedData: [] })
      this.setState(() => ({
        addProductInitialvalues: {
          price_list: values.price_list,
          product_name: values.product_name,
          product_second_name: values.product_second_name,
          section: [],
          service: values.service,
          product_type: values.product_type,
          parent: values.parent,
          measurement: values.measurement,
          pieces: values.pieces,
          price: values.price,
          minimum_washing_cycle: values.minimum_washing_cycle,
          maximum_washing_cycle: values.maximum_washing_cycle,
          preference: values.preference,
          conveyor_bag: values.conveyor_bag,
          conveyor_priority: values.conveyor_priority,
          slot_space: values.slot_space,
          image: values.image,
        }
      }))
    }
    else {
      this.setState({ isSelectAll: true, sectionSelectedData: this.state.dropdownOptions.section })
      this.setState(() => ({
        addProductInitialvalues: {
          price_list: values.price_list,
          product_name: values.product_name,
          product_second_name: values.product_second_name,
          section: this.state.dropdownOptions.section?.map((item: any) => { return item.id }),
          service: values.service,
          product_type: values.product_type,
          parent: values.parent,
          measurement: values.measurement,
          pieces: values.pieces,
          price: values.price,
          minimum_washing_cycle: values.minimum_washing_cycle,
          maximum_washing_cycle: values.maximum_washing_cycle,
          preference: values.preference,
          conveyor_bag: values.conveyor_bag,
          conveyor_priority: values.conveyor_priority,
          slot_space: values.slot_space,
          image: values.image,
        }
      }))
    }
  }
  
  handleServiceSelectAll(values?: any) {
    let newUpdateArray = this.state.dropdownOptions.service?.map((item:any) => {
      return {service_id: item.id, service_name: item.option, price: "", _destroy: false}
    })
    if (this.state.isServiceSelectAll) {
      this.setState({ isServiceSelectAll: false, serviceSelectedData: [] })
      this.setState(() => ({
        addProductInitialvalues: {
          price_list: values.price_list,
          product_name: values.product_name,
          product_second_name: values.product_second_name,
          section: values.section,
          service: [],
          product_type: values.product_type,
          parent: values.parent,
          measurement: values.measurement,
          pieces: values.pieces,
          price: [{
            service_name: '',
            service_id: '',
            price: '',
            _destroy: false
          }],
          minimum_washing_cycle: values.minimum_washing_cycle,
          maximum_washing_cycle: values.maximum_washing_cycle,
          preference: values.preference,
          conveyor_bag: values.conveyor_bag,
          conveyor_priority: values.conveyor_priority,
          slot_space: values.slot_space,
          image: values.image,
        }
      }))
    }
    else {
      this.setState({ isServiceSelectAll: true, serviceSelectedData: this.state.dropdownOptions.service })
      this.setState(() => ({
        addProductInitialvalues: {
          price_list: values.price_list,
          product_name: values.product_name,
          product_second_name: values.product_second_name,
          section: values.section,
          service: this.state.dropdownOptions.service?.map((item: any) => { return item.id }),
          product_type: values.product_type,
          parent: values.parent,
          measurement: values.measurement,
          pieces: values.pieces,
          price: newUpdateArray,
          minimum_washing_cycle: values.minimum_washing_cycle,
          maximum_washing_cycle: values.maximum_washing_cycle,
          preference: values.preference,
          conveyor_bag: values.conveyor_bag,
          conveyor_priority: values.conveyor_priority,
          slot_space: values.slot_space,
          image: values.image,
        }
      }))
    }
  }

  handleProductTypeSelect = (
    event: React.ChangeEvent<{value: number}>,
    setFieldValue: Function,
    values: FormikValues
  ) => {
    setFieldValue('product_type', event.target.value);
    this.setState({
      isParentProductType: event.target.value == 1 ? true : false
    });
    if(event.target.value == 1) {
      this.setState({
        tempPriceValues: values.price,
      }, () => {
        setFieldValue('measurement', []);
        setFieldValue('pieces', "");
        setFieldValue('price', []);
        setFieldValue('print_tag', false);
        setFieldValue('maximum_washing_cycle', '');
        setFieldValue('minimum_washing_cycle', '');
        setFieldValue('preference', '');
        setFieldValue('conveyor_bag', '');
        setFieldValue('conveyor_priority', '');
        setFieldValue('slot_space', '');

        this.setState({
          measurmentSelectedData: [],
          serviceSelectedData: [],
          selectedServiceForList: []
        });
      });
    }
    if(event.target.value == 2 && this.state.dropdownOptions.parent.length === 0) this.getParentList();
  }

  handleCloseSuccessSnack = () => {
    this.setState({ openSnackbar: false })
  }




  debouncedFunction = debounce(
    (newInputValue: string, inputFunction: (inputValue: string) => void) => inputFunction(newInputValue),
        700,
        { maxWait: 2000 }
    );

  handleSectionMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }, setFieldValue: Function) => {
    let updateStateData: Partial<S> = {}
    let fieldValue: Array<string> = []
    let selectedData:Array<number> = []
    if (option.id == "-1") {
      updateStateData = this.state.isSectionSelectAll ?
        { ...updateStateData, isSectionSelectAll: false, sectionSelectedData: [], selectedSectionsForList: [] }
        :
        {
          ...updateStateData,
          isSectionSelectAll: true,
          sectionSelectedData: [{ id: "-1", option: "Select All" }],
          selectedSectionsForList: [{ id: "-1", option: "Select All" }, ...this.state.dropdownOptions.section]
        };
      fieldValue = updateStateData.isSectionSelectAll ? ["All"] : []
      this.setState({
        sectionSelectedData:["All"],
        ServicePage:1
      })
      if(fieldValue.length==0){
        this.setState({
          ServicePage:1,
          responseServiceList:[],
          selectedServiceForList:[],
          serviceSelectedData:[],
          selectedSectionIDs:[],
          parentListPage:1,
          parentValue:{id:'',option:""}
        })
        setFieldValue("service",[])
        setFieldValue("parent", "")
        this.getServiceList([])
      }else{
        this.setState({
          ServicePage:1,
          responseServiceList:[],
          selectedServiceForList:[],
          serviceSelectedData:[],
          selectedSectionIDs:["All"],
          parentListPage:1,
          parentValue:{id:'',option:""}
        })
        this.getServiceList(["All"])
        this.setState(updateStateData as Pick<S, keyof S>, () => {
          setFieldValue("section", "All")
          setFieldValue("parent", "")
        })
      }

    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item) => item.id != option.id) : [...value, option]
      updateStateData = updateMultiSelectData(newItems, this.state.dropdownOptions.section, 'isSectionSelectAll', 'sectionSelectedData', 'selectedSectionsForList')
      fieldValue = newItems.map((item) => item.id)
      selectedData=newItems.map((item) =>{
        return Number(item.id);
      })
      setFieldValue("parent", "")
      this.setState({
        parentValue:{id:'',option:""},
        sectionSelectedData:newItems,
        ServicePage:1,
        responseServiceList:[],
        selectedSectionIDs:selectedData,
        serviceSelectedData: [],
        selectedServiceForList: [],
        parentListPage:1
      })
      this.getServiceList(selectedData);
    }
    this.setState({
      sectionSelectedIds:selectedData
    })
    this.setState(updateStateData as Pick<S, keyof S>, () => {
      setFieldValue("section", fieldValue)
      setFieldValue("service", [])
    })
  };

  handleServiceMultiSelect = (value: { id: string, option: string }[], option: { id: string, option: string }, setFieldValue: Function,values:Product) => {
    let serviceupdateStateData: Partial<S> = {}
    let fieldValue: Array<string> = []
    if (option.id == "-1") {
      serviceupdateStateData = this.state.isServiceSelectAll ?
        { ...serviceupdateStateData, isServiceSelectAll: false, serviceSelectedData: [], selectedServiceForList: [] }
        :
        {
          ...serviceupdateStateData,
          isServiceSelectAll: true,
          serviceSelectedData: [{ id: "-1", option: "Select All" }],
          selectedServiceForList: [{ id: "-1", option: "Select All" }, ...this.state.dropdownOptions.service]
        };
      fieldValue = serviceupdateStateData.isServiceSelectAll ? ["All"] : []
      const serviceList = this.state.dropdownOptions.service?.map((item:any) => {
        const isExistService = this.state.editServiceDetail.filter((price:any)=>price.service_id === item.id)
        if(isExistService.length > 0){
          return {id:isExistService[0].id,service_id: item.id,weight:isExistService[0].weight,weight_price:isExistService[0].weight_price, sqm:isExistService[0].sqm, sqm_price: isExistService[0].sqm_price, service_name: item.option, price: isExistService[0].price, _destroy: false}
        }
        return this.getExistServiceItem(values,item)
      })
      this.setState(() => ({
        addProductInitialvalues: {
          product_name: values.product_name,
          product_second_name: values.product_second_name,
          measurement: values.measurement,
          pieces: values.pieces,
          price: serviceList,
          price_list: values.price_list,
          service: this.state.dropdownOptions.service?.map((item: any) => { return item.id }),
          maximum_washing_cycle: values.maximum_washing_cycle,
          preference: values.preference,
          conveyor_bag: values.conveyor_bag,
          conveyor_priority: values.conveyor_priority,
          slot_space: values.slot_space,
          image: values.image,
          product_type: values.product_type,
          parent: values.parent,
          minimum_washing_cycle: values.minimum_washing_cycle,
          section: values.section,
    
        }
      }))
    } else {
      const isValueContained = value.some((item) => item.id == option.id)
      serviceupdateStateData.isServiceSelectAll = false
      value = value.filter((item) => item.id !== "-1")
      const newItems = isValueContained ? value.filter((item:any) => item.id != option.id) : [...value, option]
      serviceupdateStateData.serviceSelectedData = newItems
      serviceupdateStateData.selectedServiceForList = newItems
      fieldValue = newItems.map((item:any) => item.id)
      let serviceList = newItems?.map((item:any) => {
        const isExistSingleService = this.state.editServiceDetail.filter((price2:{service_id:string|number})=>price2.service_id === item.id)
        if(isExistSingleService.length > 0){
          return {id:isExistSingleService[0].id,service_id: item.id,weight:isExistSingleService[0].weight,weight_price:isExistSingleService[0].weight_price, sqm:isExistSingleService[0].sqm, sqm_price: isExistSingleService[0].sqm_price, service_name: item.option, price: isExistSingleService[0].price, _destroy: false}
        }
       return this.getExistServiceItem(values,item)
      })

    serviceList = this.getUnselectItem(serviceList,newItems)
    
         
  this.setState(() => ({
    addProductInitialvalues: {
      service: this.state.dropdownOptions.service?.map((item: any) => { return item.id }),
      price: serviceList,
      section: values.section,
      price_list: values.price_list,
      product_name: values.product_name,
      product_second_name: values.product_second_name,
      product_type: values.product_type,
      parent: values.parent,
      measurement: values.measurement,
      pieces: values.pieces,
      minimum_washing_cycle: values.minimum_washing_cycle,
      maximum_washing_cycle: values.maximum_washing_cycle,
      preference: values.preference,
      conveyor_bag: values.conveyor_bag,
      conveyor_priority: values.conveyor_priority,
      slot_space: values.slot_space,
      image: values.image,

    }
  }))
    }    
    setTimeout(()=>{
      this.setState(serviceupdateStateData as Pick<S, keyof S>, () => {
          setFieldValue("service", fieldValue)
      })
    })
  };

  getExistServiceItem = (values:Product,item:{id:string,option:string}) => {
    const isValueAvailable = values.price.filter((price1:{service_id:string|number})=>price1.service_id === item.id)
    if(isValueAvailable.length > 0){
      return {service_id: item.id, service_name: item.option, weight:isValueAvailable[0].weight,weight_price:isValueAvailable[0].weight_price, sqm: isValueAvailable[0].sqm, sqm_price: isValueAvailable[0].sqm_price,price: isValueAvailable[0].price, _destroy: false}
    }else{
      return {service_id: item.id, service_name: item.option, price: "", sqm: "", sqm_price: "",weight:"",weight_price:"", _destroy: false}
    }
  }

  getUnselectItem = (serviceList:any,newItems:{id:string,option:string}[]) => {
    let isEdit:string = this.props.navigation.history.location.pathname;
      
    if(isEdit.includes("Products-ProductListEdit")){
      const unselectedItems = this.state.editServiceDetail.filter(
        (item:any) => !newItems.some((newItem:any) => newItem.id === item.service_id)
      ).map((item:any) => ({ ...item, _destroy: true }));
      return [...serviceList, ...unselectedItems]
    }else{
      return serviceList
    }
  }

  handleCloseError = () => {
    this.setState({ errorSnakbar: false }) 
  }

  isOnlyPriceField = (values:IFormValue) => {
    return values.measurement.length === 0 || (!values.measurement.includes('SQM') && !values.measurement.includes('Weight'))  
  }

  handleSelectParent = (value:{ id: string | number, option: string },setFieldValue:Function) => {
    setFieldValue("parent",value.id)
    this.setState({
      parentValue:value
    })
  }
  handleScrollParentDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreService) return;
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30&& this.state.currentParentData.length !== 0) {
      this.setState((prevState) => ({ parentListPage: prevState.parentListPage + 1 }), () => {
        this.getParentList()
      })
    }
  };
  handleScrollSectionDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreSection) return;
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30  && this.state.currentSectionData.length !== 0) {
      this.setState((prevState) => ({ sectionPage: prevState.sectionPage + 1 }), () => {
        this.getSectionList()
      })
    }
  };
  handleImageSelect = (value:{ id: string | number, option: string, image: string },setFieldValue: Function) => {
    this.setState({
      selectedImage:value,
    })
    setFieldValue("image",value.id)
  }
  handleScrollServiceDropdown = (event: React.SyntheticEvent) => {
    if (this.disableLoadMoreService) return;
    const checkListboxNode = event.currentTarget;
    const boxPosition = checkListboxNode.scrollTop + checkListboxNode.clientHeight;

    if (checkListboxNode.scrollHeight - boxPosition <= 1.30&& this.state.currentServiceData.length !== 0) {
      this.setState((prevState) => ({ ServicePage: prevState.ServicePage + 1 }), () => {
        this.getServiceList(["All"])
      })
    }
  };



  handleChangesStatus = (active: boolean,setFieldValue: Function) => {
    setFieldValue('print_tag', active)
    this.setState({printTagSwitch: active})
   };
   
  handleGetImageScroll = (event: React.SyntheticEvent) => {
    const listBoxNode = event.currentTarget;
    const position = listBoxNode.scrollTop + listBoxNode.clientHeight;
    
    if (listBoxNode.scrollHeight - position <= 1.30 && this.state.currentIconData.length !== 0) {
      this.setState({ productNextPage: this.state.productNextPage + 1 }, () => {
        this.getImagesList()
      })
    }
  };

  handleDebounceImage = (value: string) => {
    if(!value){
      this.setState({selectedImage:{} as { id: string | number, option: string, image: string }})
    }
  };

  handleDropdownOptionReturn = (response: IDropdownResponse[]) => {
    const newArray = response.map((response) => {
      return {
        id: response.id,
        option: response.name
      }
    });
    return newArray;
  };

  getEnumDropdownOptions = (enumMessage: string) => {
    let apiString = "?dropdown_type=" + enumMessage;

    const apiUrl =
      configJSON.ApiUrls.getDropdownOptionApiEndPoint + apiString

    let message = makeApiMessage({
      url: apiUrl,
      method: "GET",
    });

    if(enumMessage === "conveyor_bag") {
      this.getConveyerDropdownOptionId = message.messageId;
    } else if(enumMessage === "conveyor_priority") {
      this.getConveyerPrioritiesDropdownOptionId = message.messageId;
    } else if(enumMessage === "slot_space") {
      this.getSpaceSlotsDropdownOptionId = message.messageId;
    } else if(enumMessage === "product_type") {
      this.getProductTypesDropdownOptionId = message.messageId;
    }

    runEngine.sendMessage(message.id, message);
  };

  getEnumDropdownOptionsResponse = (message: Message) => {
    let apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiRequestCallId == this.getConveyerDropdownOptionId) {
      if(responseJson) {
        const conveyer_bag_options = this.handleDropdownOptionReturn(responseJson);
        this.setState({
          dropdownOptions: {
            ...this.state.dropdownOptions,
            conveyor_bag: conveyer_bag_options
          }
        });
      } 
    } else if(apiRequestCallId == this.getConveyerPrioritiesDropdownOptionId) {
      const conveyer_priorities = this.handleDropdownOptionReturn(responseJson);
      this.setState({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          conveyor_priority: conveyer_priorities
        }
      });
    } else if(apiRequestCallId == this.getSpaceSlotsDropdownOptionId) {
      const slot_space = this.handleDropdownOptionReturn(responseJson);
      this.setState({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          slot_space: slot_space
        }
      });
    } else if(apiRequestCallId == this.getProductTypesDropdownOptionId) {
      const product_type = this.handleDropdownOptionReturn(responseJson);
      this.setState({
        dropdownOptions: {
          ...this.state.dropdownOptions,
          product_type: product_type
        }
      });
    }
  }

  handleAutoCompleteChange = (getValue: string) => {
    if (getValue === this.state.autoCopmpleteValue) return;
    if(getValue.length < 1 || getValue.length > 2) {
      this.setState({
        autoCopmpleteValue: getValue,
        parentListPage: 1,
      }, () => this.getParentList());
    }
  }

  // Customizable Area End
}