Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
const urlConfig = require("../../../framework/src/config");

exports.stringsListPage_tab_store = "Store";
exports.stringsListPage_tab_dispatched = "Dispatched";
exports.stringsListPage_tab_adjustment = "Adjustment";
exports.stringsListPage_tab_loadingArea = "Loading Area";
exports.stringsListPage_tab_sortingArea = "Shorting Area";
exports.stringsListPage_tab_washing = "Washing & Pressing";
exports.stringsListPage_tab_receivingArea = "Receiving Area";

exports.stringsListPage_pcs = "Pcs";
exports.stringsListPage_notes = "Notes";
exports.stringsListPage_adjustmentReason = "Reason";
exports.stringsListPage_adjustmentNote = "Comment";
exports.stringsListPage_order = "Order";
exports.stringsListPage_print = "Print";
exports.stringsListPage_select = "Select";
exports.stringsListPage_updateOrder = "Update Order";
exports.stringsListPage_cancel = "Cancel";
exports.stringsListPage_submit = "Update";
exports.stringsListPage_orderId = "Order ID";
exports.stringsListPage_customer = "Customer";
exports.stringsListPage_selectAll = "Select All";
exports.stringsListPage_select_store = "Select Store";
exports.stringsListPage_orderCreated = "Order Created";
exports.stringsListPage_noRecordsFound = "No Records Found";

exports.stringsListPage_table_id = "ID";
exports.stringsListPage_table_tag = "Tag";
exports.stringsListPage_table_pcs = "Pcs";
exports.stringsListPage_table_qty = "Qty";
exports.stringsListPage_table_item = "Item";
exports.stringsListPage_table_unitprice = "Unit Price";
exports.stringsListPage_table_totalprice = "Total Price";
exports.stringsListPage_table_notes = "Notes";
exports.stringsListPage_table_adjust = "Adjust";
exports.stringsListPage_table_clean = "Clean";
exports.stringsListPage_table_reqadjust = "Request Adjustment";
exports.stringsListPage_table_cancel = "Cancel";
exports.stringsListPage_table_orderId = "Order ID :";
exports.stringsListPage_table_parentOrderId = "Parent Order ID :";
exports.stringsListPage_table_addNote = "Add Comment";
exports.stringsListPage_table_totalPcs = "Total Pcs :";
exports.stringsListPage_table_detailsnotes = "Notes :";
exports.stringsListPage_table_customerComplaint = "Customer Complaint :";
exports.stringsListPage_table_total = "Total Amount : ";
exports.stringsListPage_table_storeName = "Store Name :";
exports.stringsListPage_table_totalItems = "Total Item :";
exports.stringsListPage_table_moveToNext = "Move To Next";
exports.stringsListPage_table_select = "Adjustment Reason";
exports.stringsListPage_table_sortingArea = "Shorting Area";
exports.stringsListPage_table_nextWillBe = "Next Status will be";
exports.stringsListPage_pleaseSelectStores = "Please select store";
exports.stringsCreditNotePage_CreateCreditNoteHeader = "Credit Note";
exports.stringsCreditNotePage_CancelButton = "Cancel";
exports.stringsCreditNotePage_NextButton = "Next";
exports.stringsCreditNotePage_CreateCreditNoteButton = "Create Credit Note";
exports.stringsCreditNotePage_CreditNote_Add_Note = "Add Note";
exports.stringsCreditNotePage_AdjustMentReason = "Adjustment Reason";
exports.stringsCreditNotePage_table_heading = "Order Details";
exports.stringsCreditNotePage_AdjustMentDetails = "Adjustment Details";
exports.stringsCreditNotePage_AddedNewUpcharge = "Added New Upcharge";
exports.stringsCreditNotePage_ConfirmationText =
  "I confirm that the adjustments and refund details are accurate.";

exports.txtTableCustomerName = "Customer Name :";
exports.txtTableCustomerMobileNo = "Customer Mobile No :";
exports.txtTableBusiness = "Business :";
exports.txtTableOrderCreationDate = "Order Creation Date :";
exports.txtTablePriceList = "Price List :";
exports.txtTableDiscount = "Discount :";
exports.txtTablePromocodeApplied = "Promocode Applied :";

exports.txtCategory = "Category";
exports.txtUpcharge = "Upcharge";
exports.txtFullUndone = "Full Undone";
exports.txtReviewOrder = "Review order";
exports.txtNewOrderDetails = "New Order Details";
exports.txtCreateNewOrder = "Create New Order";
exports.txtAddReason = "Add reason";
exports.txtSelectUpcharge = "Select Upcharge";
exports.txtOrderCancelled = "Order Cancelled";
exports.txtDone = "Done";

exports.txtItemNameService = "Item Name  |  Service";

exports.getAdjustmentReasonList =
  "bx_block_plants/plant_dashboards/adjustment_reason_list";
exports.getOrderItemsList =
  "bx_block_order_management/orders/get_order_items?id=";
exports.getStoreListEndPoint =
  "bx_block_store_management/store_managements?dropdown=true";
exports.plantOrderListEndPoint =
  "bx_block_plants/plant_dashboards/plant_orders?filter_by[status_plant]=";
exports.plantOrdersEndPoint =
"bx_block_plants/plant_dashboards/plant_orders";
exports.plantOrderUpdateEndPoint =
  "bx_block_plants/plant_dashboards/update_status";
exports.plantOrderFilterSuggestionEndPoint =
  "bx_block_plants/plant_dashboards/order_filter";
exports.plantCustomerFilterSuggestionEndPoint =
  "bx_block_plants/plant_dashboards/order_filter?customer_name=";
exports.getStoreManagementDetails = 
  "bx_block_store_management/store_managements"

exports.httpGetMethod = "GET";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfplantdashboard2";
exports.labelBodyText = "cfplantdashboard2 Body";

exports.btnExampleTitle = "CLICK ME";

exports.test="Test";
exports.test1="Test1";
exports.testing="testing";

exports.test2="Test2";
exports.test3="Test3";

exports.plantInformation = "Plant Information";
exports.plant = "Plant";
exports.plants = "Plants";
exports.add = "Add";
exports.region = "Region";
exports.selectRegion = "Select Region";
exports.noPermissions = "No Permission";
exports.cancelText = "Cancel";
exports.regionListApiEndPoint = "bx_block_regions/regions";
exports.plantDetailEndPoint = "bx_block_plants/plants";
exports.plantEndPoint = "bx_block_plants/plants";
exports.addPlantSection = "Add Plant Section";
exports.saveText = "Save";
exports.fieldRequiredValidText = "This field is required";
exports.removeBtnText = "Remove";
exports.addText = "Add";
exports.validationApiContentType = "application/json";
exports.APIBaseURL = urlConfig.baseURL;
exports.token = "token";
exports.popoverTest = "popoverTest";
exports.plantListApi = "bx_block_plants/plants";
exports.plantNameSuggestionListApi =
  "bx_block_plants/plants/plant_filter?plant_name=";
exports.plantRegionSuggestionListApi =
  "bx_block_plants/plants/plant_filter?region_name=";
exports.blank = "----";
exports.backText = "Back";
exports.updateText = "Update";
exports.adjustmentErrorMsg = "Adjustment reason is required";
exports.adjustmentErrorPara = "Please select one order for Adjustment";
exports.enterValidNumberError = "Please enter valid number";
exports.selectDate = "Select Date";
exports.testCoverage="testCoverage1";
exports.testCoverage2="testCoverage2";

exports.adjustmentPopup_full_undone = "Full Undone";
exports.adjustmentPopup_add_upcharge = "Add upcharge";
exports.adjustmentPopup_add_quantity = "Increase Quantity";
exports.adjustmentPopup_minus_quantity = "Decrease Quantity";
exports.adjustmentPopup_change_product = "Change Product";
exports.adjustmentPopup_remove_product = "Remove Product";
exports.adjustmentPopup_partial_undone = "Partial Undone";
exports.adjustmentPopup_add_new_product = "Add New Product";
exports.adjustmentPopup_change_upcharge = "Change Upcharge";
exports.adjustmentPopup_remove_upcharge = "Remove Upcharge";

exports.adjustmentPopup_undone = "Undone";
exports.adjustmentPopup_cancel = "Cancel";
exports.adjustmentPopup_confirm = "Confirm";
exports.adjustmentPopup_business = "Business: ";
exports.adjustmentPopup_discount = "Discount: ";
exports.adjustmentPopup_order_id = "Order ID: ";
exports.adjustmentPopup_store_name = "Store Name: ";
exports.adjustmentPopup_price_list = "Price List: ";
exports.adjustmentPopup_adjust_order = "Adjust Order";
exports.adjustmentPopup_edit_upcharge = "Edit Upcharge";
exports.adjustmentPopup_select_reason = "Select Reason";
exports.adjustmentPopup_edit_products = "Edit Products";
exports.adjustmentPopup_customer_name = "Customer Name: ";
exports.adjustmentPopup_adjustment_reason = "Adjustment Reason";
exports.adjustmentPopup_promocode_applied = "Promocode Applied: ";
exports.adjustmentPopup_adjustment_request = "Adjustment Request";
exports.adjustmentPopup_adjustment_quantities = "Adjust Quantities";
exports.adjustmentPopup_customer_mobile_no = "Customer Mobile No.: ";
exports.adjustmentPopup_order_creation_date = "Order Creation Date: ";

exports.editOrderPopup_btn_back = "Back";
exports.editOrderPopup_btn_undone = "Undone";
exports.editOrderPopup_btn_continue = "Continue";
exports.editOrderPopup_change_product = "Change Product";

exports.editOrderPopup_title_increase_quantity = "Increase Quantity";
exports.editOrderPopup_title_decrease_quantity = "Decrease Quantity";
exports.editOrderPopup_title_remove_upcharge = "Remove Upcharge";
exports.editOrderPopup_title_add_new_product = "Add New Product";
exports.editOrderPopup_title_change_product = "Change Product";
exports.editOrderPopup_title_remove_product = "Remove Product";
exports.editOrderPopup_title_partial_undone = "Partial Undone";
exports.editOrderPopup_title_edit_upcharge = "Edit Upcharge";
exports.editOrderPopup_title_cancel_order = "Cancel Order";
exports.editOrderPopup_title_add_upcharge = "Add Upcharge";
exports.editOrderPopup_title_full_undone = "Full Undone";
exports.editOrderPopup_title_edit_order = "Edit Order";

exports.editOrderPopup_text_service = "Service: ";
exports.editOrderPopup_text_category = "Category: ";
exports.editOrderPopup_text_upcharge = "Upcharge: ";
exports.editOrderPopup_text_price = "Price: ";
exports.editOrderPopup_text_change = "Change";
exports.editOrderPopup_text_remove = "Remove";

exports.editOrderPopup_text_adjust_quantities = "Adjust Quantities";
exports.editOrderPopup_text_edit_products = "Edit Products";
exports.editOrderPopup_text_edit_upcharge = "Edit Upcharge";
exports.editOrderPopup_text_undone = "Undone";
exports.editOrderPopup_text_cancel = "Cancel";
exports.editOrderPopup_text_order_details = "Order Details";
exports.editOrderPopup_text_upcharge = "Upcharge";

exports.editOrderPopup_text_total_items = "Total Items";
exports.editOrderPopup_text_sub_total = "Sub Total";
exports.editOrderPopup_text_tax = "Tax";
exports.editOrderPopup_text_total = "Total";
exports.editOrderPopup_text_payment_method = "Payment Method";

exports.editOrderPopup_button_add = "Add";
exports.editOrderPopup_button_edit = "Edit";
exports.editOrderPopup_button_remove = "Remove";
exports.editOrderPopup_button_change = "Change";

exports.select_sub_product = "Select Sub-product";

exports.getCreateNewOrderEndpoint =
  "bx_block_order_management/order_adjustments";
exports.getOrderByIdEndpoint = "bx_block_order_management/orders/";
exports.deleteOrderByIdEndpoint = "bx_block_order_management/order_adjustments/";
exports.getCatalogueListApi = "bx_block_catalogue/catalogues/products_list";
exports.getOrderByIdEndpoint = "bx_block_order_management/orders/";
exports.getOrderItemsByIdEndpoint =
  "bx_block_order_management/orders/get_order_items?id=";
exports.cancelOrUndoneAdjustOrderEndpoint =
  "bx_block_order_management/order_adjustments/cancel_order";

exports.upcharge_dropdown_select_placeholder = "Select Upcharges";

exports.btn_details_text = "Details";
exports.cancelOrderPopup_message_text =
  "Order #$$$ has been cancelled and moved to dispatch area";
exports.cancelOrderPopup_message_text2 =
  " and new order is moved to receiving area";
exports.getProductPreferences =
  "bx_block_profile_bio/customer_preferences/product_preferences";
exports.createPreferenceApiEndPoint =
  "bx_block_profile_bio/customer_preferences/update_preferences";

exports.getCustomerPreferenceEndpoint =
  "bx_block_profile_bio/customer_preferences";

exports.updateOrderEndPoint = "bx_block_order_management/orders/";
exports.updateCustomerPreferenceEndpoint =
  "bx_block_profile_bio/customer_preferences/update_preferences";

exports.upcharge_text_specifications = "Specifications";
exports.upcharge_text_preference = "Preference";
exports.upcharge_text_upcharge = "Upcharge";
exports.upcharge_text_continue = "Continue";

exports.stringsListPageTableId = "ID";
exports.credit_note_button = "Credit Note";
exports.credit_note_view_button = "View Credit Order";
exports.cancelled_order_text = "Cancelled Order";
exports.chooseMenuText = "Choose Item";
exports.textChoseText = "Select Item"

exports.review_order_text_tax = "Tax";
exports.review_order_text_discount = "Discount";
exports.review_order_text_sub_total = "Subtotal";
exports.review_order_text_total_items = "Total Items";
exports.review_order_text_total_amount = "Total Amount";
exports.review_order_text_promocode_discount = "Promocode Discount";
exports.confirmAdjustOrderEndPoint =
  "bx_block_order_management/order_adjustments/confirm_adjustment";
exports.gerSectionListEndPoint = 
  'bx_block_categories/categories?dropdown=true'
exports.createCreditNoteEndPoint = "bx_block_refundmanagement/refund_orders";
exports.getCreditNoteEndpoint =
  "bx_block_refundmanagement/refund_orders/get_order_refund?order_id=";
exports.checkCanceledOrderEndPoint =
  "bx_block_order_management/order_adjustments/verify_adjustment?order_id=";
exports.cfplant = "cfplant"
exports.permissionMessage = "Currently , You don't have permission to access this. Please contact Administrator.";
exports.noPrintFile = "Unable to fetch file to print";
exports.btnSubmitTestId = "btn-submit";
exports.plantTable = "Plant table"
exports.btnSubmitStore = "btnSubmitStore";
exports.getStoreListRegion =
  "bx_block_store_management/store_managements?dropdown=true&region=&allow_access=true";
exports.storeRegion = "storeRegion";
exports.fixAdjustmentState = "fixAdjustmentState";
exports.storeRegionTest = "storeRegionTest";
exports.fixDispacth = "fixDispacth";
exports.HandlePlantUser = "Plant User Role";
exports.getStoreListEndPointAsPerRegion =
  "bx_block_store_management/store_managements?dropdown=true&region";

exports.metalProgettiSolutionEndPoint = "bx_block_cfmetalprogettisolutionintegration2/metalprogetti_solution_integrations";

exports.orderItemPreferenceUpdateApiEndPoint = "bx_block_order_management/orders/update_order_item_preferences";
exports.orderSendToCleanEndPoint = 
  "bx_block_order_management/orders/update_status_to_ready?allow_access=true&for_plant=true"
exports.store_tab_id = 1;
exports.receiving_area_tab_id = 2;
exports.washing_and_pressing_tab_id = 3;
exports.shorting_area_tab_id = 4;
exports.loading_area_tab_id = 5;
exports.dispatched_tab_id = 6;
exports.adjustment_tab_id = 7;

exports.connectionErrorMessage = "Cannot connect with QZ Tray";
exports.loading = "Loading......."

exports.moveToNextLabelTestId = "moveToNextLabel";
exports.tabsTestId = "tabGroup"
exports.TabSelection = "Restrict Tab Selection"

exports.updateOutFileApi = "bx_block_cfmetalprogettisolutionintegration2/metalprogetti_solution_integrations/update_out_files";
// Customizable Area End