Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.examplePostAPiMethod = "POST";
exports.exampleGetAPiMethod = "GET";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SalesReporting";
exports.labelBodyText = "SalesReporting Body";
exports.bodyText = "bodyText";
exports.titleText = "titleText";
exports.inputText = "inputTextTitle";
exports.customizeText = "Customize";

exports.btnExampleTitle = "CLICK ME";
exports.stringsListPage_selectAll = "Select All";
exports.stringsListPage_select_store = "Select Store";
exports.stringsListPage_all_stores = "All Stores";
exports.stringsListPage_select_region = "Select Region";
exports.salesReportingEndPoint= {
  "Orders Sales Report": "bx_block_salesreporting/generate_excel_report",
  "Orders Revenue Report": "bx_block_salesreporting/order_revenue_report",
  "Laundry Sales & Revenue Report": "bx_block_salesreporting/generate_excel_for_sales_and_revenue_report",
  "Daily Sales Report": "bx_block_salesreporting/salesreportings/generate_excel_daily_sales_report",
  "Trend products Sales Report": "bx_block_salesreporting/salesreportings/trend_product_sales",
  "Adjustment Report": "bx_block_salesreporting/adjustment_report/generate_adjustment_report",
  "Consolidated Sales & Revenue Report": "bx_block_cffinancereport2/generate_finance_report",
  "Section & Service Sales Report": "bx_block_salesreporting/generate_excel_for_section_sales_report"
}
exports.reportType = {
  "Laundry Sales & Revenue Report": "Sales & Revenue Report",
  "Consolidated Sales & Revenue Report": "Finance Report"
}
exports.getAllRegionEndPoint = "bx_block_regions/regions?dropdown=true&allow_access=true"
exports.getDailySalesFiltersEndPoints = "bx_block_salesreporting/salesreportings/filter_columns_category_wise"
exports.getSectionSalesFiltesEndPoints = "bx_block_salesreporting/filter_columns_for_section_sales"
exports.getStoreListEndPoint =
  "bx_block_store_management/store_managements?dropdown=true&allow_access=true";
exports.top = "top";
exports.totalForEachColumn = "total_for_each_column"
exports.left = "left";
exports.bottom = "bottom";
exports.right = "right";
exports.center = "center";
exports.token = "token";
exports.toDatePlaceholder = "To Date";
exports.fromDataPlaceholder = "From Date";
exports.exportText = "Export";
exports.selectAll = "Select All";
exports.GroupId = "Group Ids";
// exports.activeReports = ['Orders Sales Report', 'Orders Revenue Report', 'Laundry Sales & Revenue Report', 'Trend products Sales Report', 'Daily Sales Report', 'Adjustment Report', 'Consolidated Sales & Revenue Report' ]
exports.directFields = ['Orders Sales Report', 'Orders Revenue Report', 'Trend products Sales Report']
exports.revenueFields = ['Laundry Sales & Revenue Report', 'Consolidated Sales & Revenue Report', 'Adjustment Report', 'Daily Sales Report', 'Section & Service Sales Report']
exports.dynamicFilterFields = ['Section & Service Sales Report', 'Daily Sales Report']


exports.test = "test"
// Customizable Area End-