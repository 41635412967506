import React from "react";

// Customizable Area Start
import {
  Box,
  TableContainer,
  TablePagination,
  IconButton,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  styled,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Styles } from "@material-ui/styles";
import { withStyles, Theme } from "@material-ui/core/styles";
import OrganizationListController, {
  Props,
  configJSON,
} from "./OrganizationListController.web";
import SortingTableHeader from "../../../components/src/SortingTableHeader";
import SortingTableCell from "../../../components/src/SortingTableCell";
import FilterPopover from "../../../components/src/FilterPopover";
import ConfirmationModal from "../../../components/src/customComponents/ConfirmationModal.web";
import FilterIcon from "@material-ui/icons/FilterList";
import { CustomPopover } from "../../../components/src/customComponents/CustomTags.web";
import { colors } from "../../utilities/src/Colors";
import { renderBaseOnConditions, sortStringCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
// Customizable Area End

export class OrganizationList extends OrganizationListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { permissionStatus } = this.state;
    const sortingData = this.state.sortingData;
    const open = Boolean(this.state.openAction);
    const popOverId = open ? "simple-popover" : undefined;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <Box className={classes.mainContainer}>
          <Box>
          <TopHeader>
            <OrgHeading>{configJSON.organization}</OrgHeading>
            <Box className={classes.filtergroup}>
              <Box
                data-test-id='filterBtn'
                onClick={(event) => this.openOrgPopver(event)}
                className={sortStringCondition(
                  this.state.isAppliedFilter,
                  "filterButton active-border",
                  "filterButton"
                )}
              >
                <FilterIcon color={this.handleReturnColorType()} />
                {renderBaseOnConditions(
                  this.state.isAppliedFilter,
                  <div className="filterActiveIcon"></div>,
                  <></>
                )}
              </Box>
              <FilterPopover
                data-test-id='filterPopver'
                anchor={this.state.filterAnchor}
                onClose={() => this.closeOrgPopver()}
                filters={this.state.filters}
                onAutoCompleteChange={this.handleFilterAutocompleteChange}
                onClearFilter={this.handleClearFilter}
                onFilterChange={this.handleFilterChange}
              />
              {
                renderBaseOnConditions(
                  permissionStatus.createPermission,
                  <AddOrganizationBtn
                    data-test-id='addOrganizationBtn'
                    onClick={() => this.navigateAddOrganizationPage()}
                  >
                    {configJSON.addOrganization}
                  </AddOrganizationBtn>,
                  <></>
                )
              }
            </Box>
          </TopHeader>
          <TableContainer component={Paper}>
            <Table aria-label='customized table'>
              <TableHead>
                <TableRow style={{ background: "#204B9C", color: "#FFFFFF" }}>
                  <SortingTableHeader
                    data-test-id='nameSortingHeader'
                    sortingData={sortingData}
                    title={configJSON.orgName}
                    sortingKey='name'
                    {...this.sortingProps}
                    width='39%'
                    type='left'
                  />
                  <SortingTableHeader
                    sortingData={sortingData}
                    title={configJSON.orgTaxNumber}
                    sortingKey='tax_number'
                    {...this.sortingProps}
                    width='39%'
                  />
                  <SortingTableHeader
                    sortingData={sortingData}
                    title='Status'
                    sortingKey=''
                    {...this.sortingProps}
                    width='15%'
                    sortingDisabled
                  />
                  <SortingTableHeader
                    sortingData={sortingData}
                    title=''
                    sortingKey=''
                    {...this.sortingProps}
                    width='7%'
                    type='action'
                    sortingDisabled
                  />
                </TableRow>
              </TableHead>
              <TableBody data-test-id='tableData'>
                {this.state.organizations.map((item: any) => {
                  return (
                    <TableRow key={item?.id} data-test-id='tableRow'>
                      <SortingTableCell
                        type='left'
                        width='39vw'
                        content={item.attributes.name}
                      />
                      <SortingTableCell
                        width='39vw'
                        content={
                          item.attributes.tax_number
                            ? item.attributes.tax_number
                            : "---"
                        }
                      />
                      <SortingTableCell
                        width='15vw'
                        type='right'
                        content={
                          item.attributes.active ? "Active" : "Deactivated"
                        }
                      />
                      <SortingTableCell
                        data-test-id='moreBtnTableCell'
                        width='7vw'
                        type='action'
                        content={
                          <IconButton
                            data-test-id={`moreButton-${item.id}`}
                            className={classes.blackColor}
                            id={"IconButton" + item?.id}
                            aria-describedby={popOverId}
                            onClick={(event) => this.handlepopver(event, item)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                        }
                      />
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            {this.state.organizations.length === 0 && (
              <Box className={classes.NoRecord}>{configJSON.noRecordFound}</Box>
            )}
          </TableContainer>
          <CustomPopover
            data-test-id='actionPopup'
            id={popOverId}
            open={open}
            anchorEl={this.state.openAction}
            onClose={() => this.handleCloseActionList()}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {
              renderBaseOnConditions(
                (permissionStatus.viewPermission ||
                  permissionStatus.editPermission ||
                  permissionStatus.deactivatePermission || 
                  permissionStatus.activatePermission as boolean
                ),
                <div className={classes.orgPopoverContainer}>
                  {
                    renderBaseOnConditions(
                      permissionStatus.viewPermission,
                      <Box
                        data-test-id='viewButton'
                        onClick={() => this.handleViewOrganizationDetails()}
                        className={classes.orgPopoverButton}
                      >
                        View
                      </Box>,
                      <></>
                    )
                  }
                  {
                    renderBaseOnConditions(
                      permissionStatus.editPermission,
                      <Box
                        data-test-id='editButton'
                        onClick={() => this.handleEditOrganizationDetails()}
                        className={classes.orgPopoverButton}
                      >
                        Edit
                      </Box>,
                      <></>
                    )
                  }
                  {
                    renderBaseOnConditions(
                      permissionStatus.deactivatePermission && this.state.organization.attributes.active,
                      <Box
                        data-test-id='deactiveButton'
                        onClick={() =>
                          this.handleDeactiveOrganizationDetails(
                            this.state.organization.attributes
                          )
                        }
                        className={classes.orgPopoverButton}
                      >
                        {"Deactive"}
                      </Box>,
                      <></>
                    )
                  }
                   {
                    renderBaseOnConditions(
                      permissionStatus.activatePermission as boolean && !this.state.organization.attributes.active,
                      <Box
                        data-test-id='activeButton'
                        onClick={() =>
                          this.handleDeactiveOrganizationDetails(
                            this.state.organization.attributes
                          )
                        }
                        className={classes.orgPopoverButton}
                      >
                        {"Active"}
                      </Box>,
                      <></>
                    )
                  }
                </div>,
                <div className={classes.orgPopoverContainer}>
                  <p className={classes.noPermissions}>No Permissions</p>
                </div>
              )
            }
          </CustomPopover>
          <TablePagination
            onPageChange={(event, page) => {
              this.handlePageChange(page);
            }}
            rowsPerPageOptions={[10]}
            count={this.state.meta.total_count}
            component='div'
            data-test-id='pagination'
            page={this.state.page}
            rowsPerPage={this.state.pageSize}
          />
          </Box>
        </Box>
        <ConfirmationModal
          data-test-id='deactiveModel'
          open={this.state.openDeactiveModel}
          handleClose={this.handleCloseConfirmModal}
          handleConfirm={this.handleDeactivate}
          title='Confirm Deactivate'
          message={{
            id: this.state.deActivatedItem.id,
            title: this.state.deActivatedItem.name,
          }}
          confirmText='Deactivate'
          dynamic={true}
        />
        <ConfirmationModal
          data-test-id='confirmDeactiveModel'
          open={this.state.deactivated}
          handleClose={this.handleCloseConfirmModal}
          handleConfirm={this.handleGoBackToListing}
          title={configJSON.organizationDeactivateMessage}
          message={{
            id: this.state.deActivatedItem.id,
            title: this.state.deActivatedItem.name,
          }}
          confirmText={configJSON.confirmButtonText}
          displayItem={true}
        />
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const TopHeader = styled("div")({
  padding: "32px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "@media only screen and (max-width: 600px)": {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  "& .filterButton": {
    position: "relative",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid #ECECEC",
    cursor: "pointer",
    marginRight: "24px",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
      width: "44px",
      height: "44px",
      padding: "9px",
    },
  },
  "& .active-border": {
    border: "1px solid #204B9C",
  },
  "& .filterActiveIcon": {
    position: "absolute",
    background: "#204B9C",
    top: "-5px",
    right: "-5px",
    minWidth: "15px",
    minHeight: "15px",
    borderRadius: "50%"
  },
});

export const OrgHeading = styled("p")({
  fontSize: "24px",
  lineHeight: "30px",
  fontWeight: 600,
  color: colors().black,
  "@media only screen and (max-width: 600px)": {
    marginBottom: "20px",
  },
  "@media only screen and (max-width: 1024px)": {
    fontSize: "16px",
    fontWeight: 600,
    color: colors().black,
  },
});

export const AddOrganizationBtn = styled("button")({
  padding: "16px 20px",
  background: "#204B9C",
  borderRadius: "8px",
  textTransform: "capitalize",
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "24px",
  color: "rgb(255, 255, 255)",
  outline: "none",
  border: "none",
  cursor: "pointer",
  "&.secondary": {
    background: colors().antiFlashWhite,
    color: colors().slateGray
  },
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px",
    width: "160px",
    height: "44px",
    padding: "10px 20px",
  },
});

export const useStyles = () => {
  return {
    mainContainer: {
      width: "100%",
      fontFamily: "Montserrat, sans-serif",
      "@media (max-width: 600px)": {
        width: 'calc(100% - 74px)'
      }
    },
    filtergroup: {
      display: "flex",
    },
    NoRecord: {
      fontSize: "16px",
      textAlign: "center",
      fontWeight: 600,
      width: "100%",
      paddingTop: 16,
      paddingBottom:16
    },
    orgPopoverContainer: {
      paddingTop: "16px",
      paddingBottom: "16px",
      borderRadius: "8px",
    },
    noPermissions: {
      padding: "0 8px"
    },
    orgPopoverButton: {
      width: "118px",
      height: "33px",
      paddingLeft: "24px",
      backgroundColor: "#FFFFFF",
      color: "#4D4D4D",
      fontSize: "14px",
      fontWeight: 500,
      display: "flex",
      alignItems: "center",
      paddingTop: 0,
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#204B9C",
        color: "#FFFFFF",
      },
    },
    blackColor: {
      color: "#000000 !important",
    },
  }
};

export default withStyles(useStyles as Styles<Theme, {}, string>)(OrganizationList);
// Customizable Area End
