import React from "react";

// Customizable Area Start
import {
    Button,
    Box,
    styled,
    TablePagination,
    Typography,
    IconButton,
    CircularProgress
} from "@material-ui/core";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { colors } from "../../utilities/src/Colors";
import {
    TableRow,
    TableCell,
    OrderTable,
    RowContainer,
} from "../../../components/src/OrderTable/src";
import TrucatedTextWithTooltip from "../../../components/src/commonComponents/TruncatedTextWithTooltip.web";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import Loader from "../../../components/src/Loader.web";

const IconSortingUp = require("../../../components/src/assets/sort/sort top.png");
const IconSortingNormal = require("../../../components/src/assets/sort/sort.png");
const IconSortingDown = require("../../../components/src/assets/sort/sort down.png");
// Customizable Area End

// Customizable Area Start
import StoreKeeperController, {
    Props,
    configJSON,
    TransferRequest
} from "./StoreKeeperController.web";
import { handleMomentDateFormat } from "./utils";
// Customizable Area End

export default class IncomingPayments extends StoreKeeperController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    incomingPayTableHeaderRender() {
        const { sortingData } = this.state;
        return (
            <RowContainer header>
                <TableRow
                    columns={this.getTableRowColumns(true)}
                    className='table-cols'
                >
                    {configJSON.incomingTableHeader.map((data:{label:string,sortingProp:string}) => (
                        <TableCell
                            content={data.label}
                            contentFontSize="13px"
                            header
                            className='align-item-center p5'
                            sortingData={sortingData}
                            sortingIcons={[
                                IconSortingNormal,
                                IconSortingUp,
                                IconSortingDown,
                            ]}
                            sortingKey={data.sortingProp}
                            sortingDisabled={false}
                            {...this.sortingProps}
                        />))}
                </TableRow>
            </RowContainer>
        )
    }

  renderIncomingPayTableBody() {
    const { listData, isListingDataLoading } = this.state;
    if (isListingDataLoading) {
      return (
        <RowContainer>
          <Box style={{ textAlign: "center", height: "50px" }}>
            <CircularProgress />
          </Box>
        </RowContainer>
      );
    } else {
        return (
            <RowContainer>
                {listData.length === 0 && (
                    <TableRow
                        key={"NoDataTableRow"}
                        columns='1fr'
                        className='table-rows-norecord'
                    >
                        <TableCell
                            className='w-100'
                            type='none'
                            content={
                                <Box className='no_records'>
                                    {configJSON.stringsListPage_noRecordsFound}
                                </Box>
                            }
                        />
                    </TableRow>
                )}
                
                {listData.map((data) => (
                <Box className="mainrow">
                    <TableRow
                        key={data.id}
                        className='table-rows'
                        columns={this.getTableRowColumns(true)}
                    >
                        {[
                            data.attributes.transfer_id,
                            data.attributes.transfer_to.store_name,
                            `${data.attributes.transfer_to.store_id}`,
                            handleMomentDateFormat(data.attributes.created_at,"DD-MM-yyyy h:mm A"),
                            data.attributes.transfer_from.full_name,
                            data.attributes.transfer_to.store_name,
                            data.attributes?.driver?.full_name,
                            `${data.attributes.currency} ${data.attributes.amount}`,
                            this.renderConfirmAmount(data),
                            <Typography className="lable-14-400 redFont">{`${data.attributes.currency} ${data.attributes.variance_amount}`}</Typography>,
                            this.renderReasonColumn(data)
                        ].map((value, index, array) => (<TableCell
                            className="p5"
                            style={{ background: sortCondition(data.attributes.status === "completed", "#f7f4e7", "") as string}}
                            contentFontSize="14px"
                            type={this.getType(index, array.length)}
                            content={value}
                            breakWord={true}
                            handleClick={()=>this.handleRedirectDetail(data.attributes,index)}
                        />))}
                    </TableRow>
                </Box>
                ))}
            </RowContainer>
        )
    }
  }

    renderConfirmAmount = (data: TransferRequest) => {
        const { confirmAmtEditID, confirmAmtValue } = this.state;
        if (confirmAmtEditID != data.id) return (
            <div className="confirmAmtCont">
                <Typography className="lable-14-400">
                    {`${data.attributes.currency} ${data.attributes.confirm_amount}`}
                </Typography>
                {data.attributes.status !== "completed" && <button data-test-id="directConfirmBtn" onClick={(event)=>this.handleDirectConfirmAmt(event,data)} className="confirmAmtBtn">Confirm</button>}
            </div>
        )
        return (
            <Box display={"flex"}>
                <input data-test-id='confirm-amount-input' type="number" value={confirmAmtValue} onChange={this.hanldeChangeConfirmAmt} className="confAmountEditInput" />
                <Button data-test-id="submitEditCnfBtn" onClick={() => this.handleSubmitConfAmtEdit(data)} className="confAmountSubmitBtn">Submit</Button>
            </Box>
        )
    }

    renderReasonColumn = (data: TransferRequest) => {
        const { confirmAmtEditID } = this.state;
        const { permissionStatus } = this.props;
        if(!permissionStatus) return;
        return (
            <Box>
                <TrucatedTextWithTooltip classNames="lable-14-400" text={data.attributes.reason} charCount={10} maxWidth="95px"/>
                <Box className="varActionContainer">
                    <IconButton data-test-id={"editBtnCnfAmt"+data.id} onClick={() => this.handleConfirmAmtEdit(data)} className={`tableBtn p0 ${sortCondition(confirmAmtEditID != data.id && permissionStatus.CashReceivePermission as boolean && data.attributes.status == "pending","","visibleNone")}`}><EditOutlinedIcon /></IconButton>
                </Box>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        return (
            <StyledWrapper>
            <Loader loading={this.state.loading} isAllowed={true} hideBackgroundColor={true} />
                <Box>
                    <Box className="tableContainer">
                        <OrderTable minWidth={1280} minWidth1024={887} minWidth1280={1109} minWidth1366={1184}>
                            {this.incomingPayTableHeaderRender()}
                            {this.renderIncomingPayTableBody()}
                        </OrderTable>
                    </Box>
                    {(
                        <TablePagination
                            data-test-id='pagination'
                            rowsPerPageOptions={[10, 20, 30]}
                            component='div'
                            count={this.state.listMetaData.total_count}
                            rowsPerPage={this.state.pageSize}
                            page={this.state.page - 1}
                            onRowsPerPageChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                this.handleTableRowsPerPage(event)
                            }}
                            onPageChange={(event, page) => {
                                this.handlePageChange(page + 1);
                            }}
                        />
                    )}
                </Box>
            </StyledWrapper>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export const StyledWrapper = styled(Box)({
    "& .visibleNone":{
        visibility: "hidden"
    },
    "& .no_records": {
        fontWeight: 700,
        fontSize: 16,
        textAlign: "center",
        width: "100%",
    },
    "& .w-100": {
        width: "100%",
    },
    "& .table-rows-norecord > div": {
        textAlign: "left",
        fontSize: "12px",
        textTransform: "capitalize",
        minHeight: "50px",
        fontWeight: "500 !important",
    },
    "& .lable-16-700": {
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 700
    },
    "& .lable-14-400": {
        fontSize: "14px",
        fontFamily: "Montserrat",
        fontWeight: 400
    },
    "& .redFont": {
        color: "red"
    },
    "& .align-item-center": {
        alignItems: "center",
    },
    "& .p5": {
        padding: "5px"
    },
    "& .p0": {
        padding: "0"
    },
    "& .tableBtn": {
        background: "rgba(32, 75, 156, 0.1)",
        width: "32px",
        height: "32px",
        borderRadius: "8px",
        "& .MuiSvgIcon-root": {
            color: "#4D4D4D"
        }
    },
    "& .tableContainer": {
        marginTop: "20px"
    },
    "& .varActionContainer": {
        marginTop: "10px",
        display: "flex",
        gap: "10px"
    },
    "& .confAmountEditInput": {
        border: "1px solid #ECECEC",
        width: "101px",
        height: "44px",
        padding: "11px 8px",
        boxSizing: "border-box",
        borderRadius: "8px 0px 0px 8px",
        "-moz-appearance": "textfield",
        '&:focus': {
            outline: "none"
        },
        "@media (max-width: 1280px)": {
            fontSize: "14px",
            width: "70px",
            height: "36px"
        },
        "@media (max-width: 1024px)": {
            fontSize: "12px",
            width: "65px",
            height: "32px"
        }
    },
    "& .mainrow":{
        cursor: "pointer"
    },
    "& .confAmountSubmitBtn": {
        fontSize: "16px",
        fontFamily: "Montserrat",
        fontWeight: 700,
        "width": "93px",
        "height": "44px",
        "padding": "10px 16px 10px 16px",
        "borderRadius": "0px 8px 8px 0px",
        "background": "#204B9C",
        color: "#fff",
        textTransform: "none",
        "@media (max-width: 1280px)": {
            fontSize: "14px",
            width: "70px",
            height: "36px"
        },
        "@media (max-width: 1024px)": {
            fontSize: "12px",
            width: "65px",
            height: "32px"
        }
    },
    "& .confirmAmtCont": {
        justifyContent: "space-between",
        flexDirection: "column",
        height: "100%",
        gap:"10px",
        display: "flex",
        width: "100%"
    },
    "& .confirmAmtBtn": {
        background: colors().cyancobaltblue,
        outline: "none",
        padding: "3px 6px",
        width: "fit-content",
        fontSize: "13px",
        fontFamily: "Montserrat",
        borderRadius: "8px",
        border: "none",
        color: colors().background
    },
})
// Customizable Area End
