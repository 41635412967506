import React, { useState } from "react";
import {
  styled,
  Dialog,
  DialogContent,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

import {
  iconLabelBlue,
  iconLabelGray,
  iconRoundCheck,
  iconRecipetBlue,
  iconRecipetGray,
  // Customizable Area Start
  // Customizable Area End
} from "./assets";

// Customizable Area Start
import { getEnvironment } from "../../../framework/src/Utilities";
const configJSON = require("./config");
const Strings = configJSON.PrintDialog;
// Customizable Area End

interface IAction {
  label: string;
  action: string;
  icon: string;
  activeIcon: string;
  // Customizable Area Start
  // Customizable Area End
}

const actions: Array<IAction> = [
  {
    label: Strings.printTag,
    action: "tag",
    icon: iconLabelGray,
    activeIcon: iconLabelBlue,
  },
  {
    label: Strings.printReceipt,
    action: "receipt",
    icon: iconRecipetGray,
    activeIcon: iconRecipetBlue,
  },
  // Customizable Area Start
  // Customizable Area End
];

interface Props {
  onCancel: Function;
  onSubmit: Function;
  isSendToDriver?: boolean;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
const sendToDriverActions: Array<IAction> = [
  {
    label: Strings.printDriverReceipt,
    action: "receipt",
    icon: iconRecipetGray,
    activeIcon: iconRecipetBlue,
  },
];
// Customizable Area End

function PrintModal(props: Props) {
  // Customizable Area Start
  const [selected, setSelected] = useState<string>("");
  // Customizable Area End

  // Customizable Area Start
  return (
    <StyledDialog open={true}>
      <DialogContent>
        <Container>
          <Title>{Strings.selectFromBelow}</Title>
          {(props.isSendToDriver ? sendToDriverActions : actions).map((item: IAction) => {
            const isSelected = selected === item.label;
            const displayIcon = isSelected ? item.activeIcon : item.icon;
            return (
              <div className='text-center' key={"modal-actions-" + item.action}>
                <SelectButton
                  data-test-id={"select-button-" + item.action}
                  onClick={() => setSelected(isSelected ? "" : item.label)}
                  className={isSelected ? "active" : ""}
                >
                  <img src={displayIcon} />
                  <span>{item.label}</span>
                  {isSelected && <img src={iconRoundCheck} />}
                </SelectButton>
              </div>
            );
          })}

          <ButtonContainer>
            <CancelButton
              data-test-id='btn-cancel'
              onClick={() => props.onCancel()}
            >
              {Strings.cancel}
            </CancelButton>
            <SubmitButton
              data-test-id='btn-submit'
              onClick={() => props.onSubmit(selected)}
              disabled={!selected}
            >
              {Strings.submit}
            </SubmitButton>
          </ButtonContainer>
        </Container>
      </DialogContent>
    </StyledDialog>
  );
  // Customizable Area End
}

// Customizable Area Start
const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: 24,
  },
  "& .MuiDialogContent-root": {
    padding: 0,
  },
  "& .text-center": {
    textAlign: "center",
  },
});

const SelectButton = styled("div")({
  gap: 16,
  padding: 16,
  minWidth: 300,
  fontWeight: 600,
  borderRadius: 12,
  marginBottom: 16,
  cursor: "pointer",
  alignItems: "center",
  display: "inline-flex",
  border: "1px solid #DEDEDE",
  "& > span": {
    flexGrow: 1,
    textAlign: "left",
  },
  "&:hover": {
    backgroundColor: "#204B9C1A",
  },
});

const ButtonContainer = styled("div")({
  gap: 24,
  marginTop: 16,
  display: "flex",
  alignItems: "center",
});

const CancelButton = styled("button")({
  padding: 16,
  fontSize: 16,
  minWidth: 186,
  border: "none",
  fontWeight: 700,
  borderRadius: 8,
  color: "#64748B",
  cursor: "pointer",
  lineHeight: "24px",
  alignItems: "center",
  display: "inline-flex",
  justifyContent: "center",
  backgroundColor: "#F1F5F9",
});

const SubmitButton = styled(CancelButton)({
  color: "#fff",
  backgroundColor: "#204B9C",
  "&:disabled, &[disabled]": {
    opacity: 0.7,
  },
});

const Container = styled("div")({
  padding: 32,
  "& .active": {
    color: "#204B9C",
    backgroundColor: "#204B9C1A",
  },
});

const Title = styled("h3")({
  margin: 0,
  padding: 0,
  fontSize: 24,
  fontWeight: 600,
  paddingBottom: 32,
  lineHeight: "29px",
  textAlign: "center",
  fontFamily: "Montserrat",
});

// Customizable Area End

export default PrintModal;
