import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  styled,
  AppBar,
  Tabs,
  Tab,
  TextareaAutosize,
  FormControlLabel,
  Dialog,
  Select,
  CircularProgress,
  Divider,
  DialogContent,
  DialogActions,
  Snackbar
} from "@material-ui/core";
import { Autocomplete, Alert } from "@material-ui/lab";

import { DatePicker } from "@material-ui/pickers";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { iconCheckBox, iconCheckBoxChecked, MinusIcon, PlusIcon, productAddIcon } from "./assets";

import PageContainer from "../../../blocks/navigationmenu/src/PageContainer.web";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import Print from "../../print/src/Print.web";

import AutocompleteSelect from "../../../components/src/AutocompleteSelect.web";

import { colors } from "../../utilities/src/Colors";
import CatalogueCard from "../../../blocks/catalogue/src/CatalogueCard.web";
import ShoppingCart, {
  MainButton,
} from "../../../blocks/shoppingcart/src/ShoppingCart.web";
import { CatalogueCardSkeleton } from "../../../components/src/customComponents/CatalogueCardSkeleton.web";
import { CatalogueList } from "../../../components/src/Interface";
import {
  StyledTextField
} from "../../../components/src/customComponents/CustomTags.web";
import { renderBaseOnConditions } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { TextLabel, ErrorLabel } from '../../promocodes/src/PromoCodeCreate.web'
import { CustomMenuProps } from "../../ProductDescription/src/HomeCleaningSettingsList.web";
import PreferenceItem from "../../../components/src/customComponents/PreferenceItem.web";
import CarpetAndFurnitureProductDialogue from "../../../components/src/commonComponents/CarpetAndFurnitureProductDialogue";
import EditPreference from "../../../blocks/ProductDescription/src/EditPreference.web";

const muiStyle = {
  muiIcon: { padding: "4px" },
  muiCheckIcon: { position: "relative" as const, top: "4px" },
};

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
  props: {
    MuiCheckbox: {
      icon: <img src={iconCheckBox} style={muiStyle.muiIcon} />,
      checkedIcon: (
        <img src={iconCheckBoxChecked} style={muiStyle.muiCheckIcon} />
      ),
    },
  },
});
const productType = {
  NORMAL: "noraml",
  SUBPRODUCT: "Sub-Product",
  PARENT: "Parent",
};
// Customizable Area End

import OrderCreationController, {
  IFormValues,
  // Customizable Area Start
  Props,
  configJSON,
  // Customizable Area End
} from "./OrderCreation2Controller.web";
import { ICartProductItem, validationSchemaForAddProduct } from "./utils";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import SameProductDifferentServiceModal from "../../../components/src/customComponents/SameProductDifferentServiceModal.web";
import { Formik, FormikErrors, FormikProps } from "formik";
import AddProductModal from "../../../blocks/catalogue/src/AddProductModal.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";

export default class OrderCreation extends OrderCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAfterPermissionComponent() {
    const { userData, storeList } = this.state;
    const dropDownId =
      userData?.attributes?.employee_proifle?.data?.attributes?.store_management
        ?.store_id;

    return (
      <div className='branchDropDown'>
        <AutocompleteSelect
          data-test-id='branchDropDown'
          placeholder='Select branch'
          fieldName={"branch"}
          options={storeList}
          value={String(dropDownId)}
          disableClear={true}
          onChange={(_event: React.ChangeEvent, value: { id: number }) => {
            this.handleStoreChange();
            this.updateEmployeeCurrentStore(value.id);
          }}
          handleScroll={this.handelStoreDropdownScroll.bind(this)}
          debouncedFunction={this.debouncedStoreSearch}
          onBlur={() => this.debouncedStoreSearch("")}
          listBoxStyle={{ maxHeight: 310 }}
        />
      </div>
    );
  }

  renderProductsList = () => {
    return (
      <CatalogueScrollBox
        onScroll={this.handleCatalogueListScroll}
        data-test-id='catalogueCardScroll'
      >
        <CatalogProductGrid>
          {this.state.cataloguesList?.map((catalogue) => {
            const catalogueId = catalogue.attributes.id;
            let selectedProductData = this.getCatalogueDataBasedOnCondition(catalogueId);

            const selectedServiceId =
              selectedProductData?.service_id || undefined;
            let selectedProductQuantity = 0;
            if (
              catalogue.attributes?.product_type?.value === productType.PARENT
            ) {
              selectedProductQuantity =
                this.state.cartProducts
                  ?.filter(
                    (product: ICartProductItem) =>
                      product.catalogue_id === catalogueId &&
                      product.category_id === this.state.tabsId
                  )
                  ?.reduce((accu: number, product: { quantity: number }) => {
                    return accu + product.quantity;
                  }, 0) || 0;
            } else {
              selectedProductQuantity = selectedProductData?.quantity || 0;
            }
            return (
              <>
                <CatalogueCard
                  navigation={this.props.navigation}
                  data-test-id='product-card-component'
                  key={catalogue.id}
                  id='catalogueCard'
                  tabsId={this.state.tabsId}
                  catalogue={catalogue}
                  selectedServiceId={selectedServiceId}
                  selectedProductQuantity={selectedProductQuantity}
                  addQuantityClickHandler={this.addQuantityClickHandler}
                  removeQuantityClickHandler={this.removeQuantityClickHandler}
                  serviceClickHandler={this.serviceClickHandler}
                  parentProductId={this.state.clickedParentCatalougeId}
                  isQuickDrop={this.state.isQuickDrop}
                />
              </>
            );
          })}
          {this.state.isCatalogueCardLoading &&
            Array(18)
              .fill(1)
              .map(() => <CatalogueCardSkeleton />)}
          {this.state.cataloguesList?.length < 1 &&
            !this.state.isCatalogueCardLoading &&
            configJSON.noProductAvail}
          {this.state.userData?.attributes?.employee_proifle?.data?.attributes?.store_management?.attributes?.sections?.length &&
          <div
            data-test-id='add-product-card'
            onClick={() => this.handleAddProductClick()}
            className="addProductBtnContainer"
          >
            <div className="addProductBtnPlusContainer">
              <div className="addProductBtnPlus">+</div>
            </div>
            <div className="addProductBtnText">{configJSON.addProduct}</div>
          </div>
          }
        </CatalogProductGrid>
      </CatalogueScrollBox>
    );
  };

  renderCart = () => {
    const { selectedMainTab } = this.state;
    return (
      <ShoppingCart
        navigation={this.props.navigation}
        id={"shoppingcart"}
        currentUser={this.state.userData}
        selectedMainTab={selectedMainTab}
      />
    );
  };

  getMainOrderTypeAppBar() {
    const { selectedMainTab } = this.state;
    return (
      <StyledMainAppBar position='static' data-test-id='main-order-type-tabs'>
        <Tabs
          variant='scrollable'
          scrollButtons='on'
          value={selectedMainTab}
          // onChange={(_event: React.ChangeEvent<{}>, value: string) =>
          //   this.onSendAction(Actions.CHANGE_MAIN_TAB, value)
          // }
        >
          {this.getOrderTypeList().map(({ label, value }) => (
            <Tab label={label} value={value} key={"order-type-item-" + value} />
          ))}
        </Tabs>
      </StyledMainAppBar>
    );
  }

  renderSubProductModal() {
    const {
      subProductModalOpen,
      tabsId,
      clickedParentCatalougeId,
      cartProducts,
    } = this.state;

    return (
      <SubProductModal
        open={subProductModalOpen}
        maxWidth='md'
        data-test-id='sub-product'
      >
        <DialogContent className='p-24'>
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <p className='subproduct-heading'>
              {configJSON.NewStrings.select_sub_product}
            </p>
            <CatalogProductGrid className='sub-product-grid'>
              {this.state.cataloguesList
                ?.find(
                  (catalog: CatalogueList) =>
                    catalog.attributes.id ===
                    this.state.clickedParentCatalougeId
                )
                ?.attributes.sub_products.data?.map(
                  (catalogue: CatalogueList) => {
                    const catalogueId = catalogue?.attributes?.id;
                    let selectedProductData = this.getCatalogueDataBasedOnCondition(catalogueId);

                    const selectedServiceId =
                      selectedProductData?.service_id || undefined;
                    let selectedProductQuantity = 0;
                    selectedProductQuantity =
                      selectedProductData?.quantity || 0;

                    return (
                      <>
                        <CatalogueCard
                          data-test-id='product-card-component'
                          navigation={this.props.navigation}
                          id='catalogueCard'
                          key={catalogue.id}
                          tabsId={tabsId}
                          catalogue={catalogue}
                          selectedServiceId={selectedServiceId}
                          selectedProductQuantity={selectedProductQuantity}
                          addQuantityClickHandler={this.addQuantityClickHandler}
                          removeQuantityClickHandler={
                            this.removeQuantityClickHandler
                          }
                          serviceClickHandler={this.serviceClickHandler}
                          parentProductId={clickedParentCatalougeId}
                        />
                      </>
                    );
                  }
                )}
            </CatalogProductGrid>
            <DialogActions className='dialogActionWrapper'>
              <MainButton
                data-test-id='btnSubCancel'
                className='buttonSecondary'
                onClick={this.handleCloseSubProductDialog}
              >
                {configJSON.cancel}
              </MainButton>
              <MainButton
                data-test-id='btnSubNext'
                onClick={this.handleSubmitSubProductDialog}
              >
                {configJSON.NewStrings.continue}
              </MainButton>
            </DialogActions>
          </Box>
        </DialogContent>
      </SubProductModal>
    );
  }

  renderCarpetAndFurnitureSQMWeightDialog = () => {
    return (
      <CarpetAndFurnitureProductDialogue 
        productPopup={this.state.productPopup}
        carpetFurnitureProductPopupData={this.state.carpetFurnitureProductPopupData}
        selectedService={this.state.selectedService}
        handleSubmitCarpetAndFurnitureProduct={(items: IFormValues[]) => this.handleSubmitCarpetAndFunrnitureProduct(items)}
        closeCarpetAndFurnitureModal={() => this.onCloseCareptAndFurnitureProductPopup()}
      />
    )
  }

  renderPreferencesModal = () => {
    const { preferenceDataList, selectedService, preferenceModalVisible } =
      this.state;
    return (
      <PreferenceItem
        data-test-id='preferenceItem'
        preferenceData={preferenceDataList}
        selectedService={selectedService}
        handleClose={this.handleClosePreferenceModal}
        data={[]}
        headerTitle={"Create Item Preference"}
        saveForFuture={true}
        open={preferenceModalVisible}
        responseData={{
          id: "11",
          type: "preference",
          attributes: {
            id: 11,
            name: "Preference 1",
            product_second_name: "Product 1",
            specifications: [],
            upcharges: [],
            preferences: [],
            services: [],
          },
        }}
        onCreate={(data) => this.handleCreatePreferencesOrder(data)}
      />
    );
  };

  returnErrorSnackBar() {
    const { errorSnackbarOpen, errorMessage, snackbarSeverity } = this.state;
    return (
      <CustomSnackbar
        data-test-id='custom-errror-snackbar'
        open={errorSnackbarOpen}
        onClose={() => this.handleSnackbarClose()}
        errorMessage={errorMessage}
        autoHideDuration={3000}
        severity={snackbarSeverity}
        hideIcon={true}
      />
    );
  }

  renderSameProductDifferenceServiceModal() {
    const { sameProductDifferentServiceModalVisible } = this.state;
    return (
      <SameProductDifferentServiceModal
        open={sameProductDifferentServiceModalVisible}
        handleClose={this.sameProductDiffServiceModalClose}
        handleConfirmClick={this.sameProductDiffServiceConfirmClick}
      />
    );
  }

  renderAddTemproryProduct = () => {
    return (
      <AddProductModal
        data-test-id='addProductModal'
        navigation={this.props.navigation}
        id={"AddProductModal"}
        open={this.state.addProductPopup}
        handleClose={() => {
          this.handleAddProductPopupClose();
        }}
        getProductInfo={this.handleGetTemporaryProduct}
        categoryId={this.state.tabsId}
        storeId={
          this.state?.userData?.attributes?.employee_proifle?.data
            ?.attributes?.store_management?.store_id as number
        }
        customerId={Number(this.state.selectedCustomer?.id as string)}
      />
    )
  }

  renderEditPreferenceModa() {
    const { laundryOrder, isEditPreferencesPopupOpen } = this.state;
    return (
      <EditPreference
        navigation={undefined}
        id={""}
        laundryOrder={laundryOrder}
        open={isEditPreferencesPopupOpen}
        handleClosePopup={this.handleCloseEditPreferencesPopup}
        handleOnSavePreference={this.handleSavePreference}
        isCfPlantAdjustmentscreen={false}
      />
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      preferenceModalVisible,
      errorSnackbarOpen,
      sameProductDifferentServiceModalVisible,
      isEditPreferencesPopupOpen,
      permissionStatus
    } = this.state;

    // Customizable Area End
    return (
      // Customizable Area Start
      <MainWrapper>
        <ThemeProvider theme={theme}>
          <CustomLoader loading={this.state.isLoading} />
            
            {permissionStatus.createPermission && <Box style={{height:"100%"}}>
              <CataloguesFlex style={{height:"100%"}}>
                <CatalogueVflexBox>
                  {this.getMainOrderTypeAppBar()}
                  {this.getOrderRenderBasedOnOrderType(
                    <>
                      <h1>{configJSON.homeCleaningFacilityText}</h1>
                    </>,
                    <>
                      <StyledAppBar position='static'>
                        <Tabs
                          value={this.state.tabsId}
                          scrollButtons='on'
                          variant='scrollable'
                          onChange={this.tabSectionChangeHandler}
                        >
                          {this.state.userData?.attributes?.employee_proifle?.data?.attributes?.store_management?.attributes?.sections?.map(
                            (section: { id: number; name: string }) => {
                              return (
                                <Tab
                                  label={section?.name}
                                  value={section?.id}
                                  key={section?.id}
                                  className={this.state.highlightedSectionIds.includes(section.id) ? "highlightSection" : ""}
                                />
                              );
                            }
                          )}
                        </Tabs>
                      </StyledAppBar>

                      {this.renderProductsList()}
                    </>
                  )}
                </CatalogueVflexBox>
                {this.renderCart()}
              </CataloguesFlex>
            </Box>}
            {!permissionStatus.createPermission && <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={!permissionStatus.createPermission}
            >
              <Alert severity='warning'>{configJSON.permissionMessage}</Alert>
            </Snackbar>}
            
        </ThemeProvider>
        {this.renderCarpetAndFurnitureSQMWeightDialog()}
        {this.renderSubProductModal()}
        {preferenceModalVisible && this.renderPreferencesModal()}
        {errorSnackbarOpen && this.returnErrorSnackBar()}
        {sameProductDifferentServiceModalVisible &&
          this.renderSameProductDifferenceServiceModal()}
        {this.state.addProductPopup && this.renderAddTemproryProduct()}
        {isEditPreferencesPopupOpen && this.renderEditPreferenceModa()}
      </MainWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CataloguesFlex = styled(Box)({
  display: "flex",
  gap: "16px",
  flex: "1 1 0",
  padding: "0 0 12px 0",
  justifyContent:"space-between"
});

const CatalogueVflexBox = styled(Box)({
  flex: "1 1 0",
  display: "flex",
  flexDirection: "column",
  maxWidth: "calc(100% - 360px)",
  "@media only screen and (max-width: 1280px)": {
    maxWidth: "calc(100% - 340px)",
  },
  "@media only screen and (max-width: 1024px)": {
    maxWidth: "calc(100% - 300px)",
  },
});

const CatalogueScrollBox = styled(Box)({
  flex: "1 1 0",
  overflow: "auto",
});

const StyledTextArea = styled(TextareaAutosize)({
  padding: 8,
  width: "100%",
  borderRadius: 8,
  border: `1px solid ${colors().lightborder}`,
});

const StyledDialog = styled(Dialog)({
  "& .MuiDialog-paper": {
    borderRadius: 24,
    "& .MuiDialogContent-root": {
      padding: "24px 32px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: colors().lightborder,
    },
    "& .MuiTextField-root": {
      paddingTop: 0,
    },
    "& .link-button": {
      gap: 8,
      fontSize: 16,
      display: "flex",
      fontWeight: 700,
      cursor: "pointer",
      alignItems: "center",
      color: colors().cyancobaltblue,
    },
    "& .button-container": {
      display: "flex",
      paddingTop: 32,
      paddingBottom: 24,
      alignItems: "center",
      justifyContent: "center",
      "@media only screen and (max-width: 425px)": {
        flexFlow: "column-reverse",
        gap: 16,
      },
    },
    "& .dialog-title": {
      fontSize: 24,
      fontWeight: 600,
      paddingBottom: 32,
      textAlign: "center",
      textTransform: "capitalize",
    },
  },
});

const PlantOrderCustomerFormContainer = styled("div")({
  gap: 12,
  display: "grid",
  alignItems: "center",
  gridTemplateColumns: "80px 1fr",
  "@media only screen and (max-width: 625px)": {
    gridTemplateRows: "1fr 1fr",
    gridTemplateColumns: "1fr",
  },
  "@media only screen and (max-width: 1024px)": {
    gridTemplateColumns: "56px 1fr",
    gap: 8,
    "& input": {
      fontSize: 11,
    },
  },
  "& > div": {
    gap: 12,
    display: "flex",
    alignItems: "center",
    "@media only screen and (max-width: 1024px)": {
      gap: 8,
    },
  },
  "& .MuiOutlinedInput-root": {
    width: "100%",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: colors().lightborder,
  },
  "& .countryInputDigit": {
    width: "100%",
    "& .MuiOutlinedInput-input": {
      padding: "10px 8px",
    },
    "& input": {
      fontWeight: 500,
    },
    "& .MuiOutlinedInput-root": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    height: "unset",
  },
  "& .customerButton": {
    width: 36,
    height: 36,
  },
  "& .MuiAutocomplete-root, & .MuiTextField-root": {
    paddingTop: 0,
  },
  "& .MuiAutocomplete-root .MuiOutlinedInput-root": {
    minWidth: 175,
    padding: 0,
    paddingRight: 30,
    "& input": {
      fontWeight: 500,
      padding: "10px 8px !important",
    },
  },
});

const PlantOrderItemContainer = styled("div")({
  padding: 16,
  borderRadius: 12,
  boxShadow: "0px 2px 8px 0px #00000014",
  "@media only screen and (max-width: 1024px)": {
    fontSize: "13px !important",
  },
  "& .emptyCart": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "&.dialog": {
    minWidth: 872,
    marginBottom: 32,
    "@media only screen and (max-width: 1024px)": {
      minWidth: "unset",
    },
    "& .hide-768": {
      "@media only screen and (max-width: 768px)": {
        display: "none",
      },
    },
    "& .suffix": {
      fontSize: 16,
      fontWeight: 600,
    },
    "& .pt-32": {
      paddingTop: 32,
    },
    "& .dialog-container": {
      gap: 32,
      display: "flex",
      flexFlow: "column",
    },
    "& .flex": {
      display: "flex",
      paddingBottom: 0,
    },
    "& .align-center": {
      alignItems: "center",
    },
    "& .space-between": {
      justifyContent: "space-between",
    },
    "& .grid": {
      display: "grid",
    },
    "& .col-2": {
      gridTemplateColumns: "1fr 1fr",
      "@media only screen and (max-width: 768px)": {
        gridTemplateColumns: "1fr",
      },
    },
    "& .gap-2": {
      gap: 12,
      gridGap: 12,
    },
  },
  "& .border-box": {
    borderRadius: 12,
    padding: 16,
    border: `1px solid ${colors().lightborder}`,
  },
  "& .detail-container": {
    gap: 16,
    fontSize: 14,
    display: "flex",
    fontWeight: 500,
    paddingBottom: 16,
    "& .right-section": {
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "flex-end",
      gap: 10,
    },
    "& .price-box": {
      display: "flex",
      justifyContent: "end",
      gap: 5,
      "& > .price": {
        minWidth: 80,
        textAlign: "right",
      },
    },
    "& .delete": {
      gap: 4,
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      "& span": {
        display: "none",
      },
    },
    "@media only screen and (max-width: 768px)": {
      flexFlow: "column",
      alignItems: "flex-start",
      "& > .price": {
        width: "100%",
        textAlign: "left",
        paddingBottom: 12,
        borderBottom: `1px dotted ${colors().lightborder}`,
      },
      "& .delete span": {
        display: "block !important",
      },
    },
    "@media only screen and (max-width: 1024px)": {
      fontSize: 13,
    },
  },
  "& .cart-item > div": {
    marginBottom: 16,
    borderBottom: `1px solid ${colors().lightborder}`,
  },
  "& .cart-item > div:last-child": {
    marginBottom: 0,
    border: 0,
  },
  "& .cart-item > div:last-child > div:last-child": {
    paddingBottom: 0,
  },
  "& .pcs": {
    width: "fit-content",
    height: "30px",
    background: "#EC4E20",
    color: "#FFF",
    textAlign: "center" as const,
    lineHeight: "30px",
    minWidth: "95px",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    gap: 8,
    fontWeight: 400,
    borderRadius: 4,
    justifyContent: "center",
    backgroundColor: colors().orange,
    "@media only screen and (max-width: 1024px)": {
      minWidth: 80,
      height: 24,
      padding: 0,
    },
  },
  "& .details": {
    flexGrow: 1,
    color: colors().darkjunglegreen,
    textTransform: "capitalize",
  },
  "& .product-name": {
    fontSize: 14,
    fontWeight: 500,
    paddingBottom: 12,
    textTransform: "capitalize",
    color: colors().darkliver,
    "@media only screen and (max-width: 1024px)": {
      fontSize: 13,
    },
  },
  "& .flex": {
    display: "flex",
    paddingBottom: 16,
    justifyContent: "space-between",
    fontSize: 15,
    "@media only screen and (max-width: 1024px)": {
      fontSize: 13,
    },
  },
  "& .title": {
    fontWeight: 600,
  },
  "& .link": {
    cursor: "pointer",
    color: colors().orange,
    textDecoration: "underline",
  },
});

const MainWrapper = styled("div")({
  fontFamily: "Montserrat",
  height: "100%",
  "& .mb-24": {
    marginBottom: "24px",
  },
  "& .buttonWrapper": {
    minWidth: "100px",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  "& .branchDropDown": {
    minWidth: "200px",
    margin: "0 12px",
  },
  "& .font-600": { fontWeight: 600 },
  "& .mr-8": { marginRight: 8 },
  "&. .qtyNum": { padding: "0 10px" },
  "& .w-full-80": { width: "80%" },
  "& .cursor-pointer": { cursor: "pointer" },
  "& .customerButton": {
    padding: 0,
    backgroundColor: "#204B9C",
    minWidth: "36px",
    height: "36px",
    borderRadius: "8px",
  },
  "& .cartPaymentOption": {
    marginTop: "24px",
    marginBottom: 0,
  },
  "& .mobileBlock": {
    display: "flex",
    justifyContent: "space-berween",
  },
  "& .cartSubtotalWrapper": {
    display: "flex",
    width: "100%",
    cursor: "pointer",
  },
  "& .cartTotalWrapper": {
    backgroundColor: "#EC4E20",
    borderRadius: "8px",
    padding: "16px 24px",
    color: "#FFF",
    marginTop: "20px",
  },
  "& .backInner": {
    width: "100%",
    display: "flex",
  },
  "& .cartSubtotalPrice": {
    marginLeft: "auto",
    fontWeight: 600,
  },
  "& .cartTotalListPrice": {
    fontWeight: 400,
    marginLeft: "auto",
    marginRight: "25px",
  },
  "& .countryInput": {
    width: "72px",
    marginRight: "12px",
    padding: 0,
  },
  "& .countryInput .MuiOutlinedInput-input": {
    padding: 10,
  },
  "& .phone-number .MuiAutocomplete-inputRoot": {
    padding: 1,
  },
  "& .phone-number .MuiInputBase-input": {
    fontSize: "14px",
    fontWeight: 500,
  },
  "& .deleteIcon": {
    cursor: "pointer",
    width: "16px",
    height: "16px",
  },
  "& .dialogTitle": {
    margin: "auto",
    paddingTop: "32px",
    paddingBottom: "24px",
  },
  "& .dialogActionWrapper": {
    justifyContent: "center",
    paddingTop: "24px",
    paddingBottom: "32px",
  },
  "& .cartItemQuantityIcon": {
    cursor: "pointer",
    width: "16px",
    height: "16px",
    color: "white",
  },
  "& .emptyCartIcon": {
    width: "93px",
    height: "74px",
  },
  "& .cartProductNote": {
    width: "100%",
    padding: "8px",
    maxWidth: "100%",
    borderRadius: "8px",
    border: "1px solid #ECECEC",
  },
  "& .sideCart": {
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid #ECECEC",
  },
  "& .cartItemContainer": {
    borderRadius: "12px",
    boxShadow: "0px 2px 4px 4px rgba(0, 0, 0, 0.08)",
    padding: "20px 16px",
  },
  "& .editAllBtn": {
    padding: "8px 10px",
    borderRadius: "8px",
    background: "rgba(32, 75, 156, 0.10)",
    color: "#204B9C",
    cursor: "pointer",
    fontSize: "13px",
    fontWeight: 600,
  },
  "& .cartHeaderContainer": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .cartProductDetailPrice": {
    color: "#4D4D4D",
    fontSize: "12px",
    fontWeight: 500,
    letterSpacing: "-0.35px",
    opacity: 0.6,
  },
  "& .cartProductQuantityContainerMain": {
    maxWidth: "81px",
    flex: "0 0 81px",
    padding: "10px 8px",
  },
  "& .cartProductQuantityContainer": {
    height: "30px",
    borderRadius: "7px",
    background: "#EC4E20",
    color: "#FFF",
    textAlign: "center" as const,
    lineHeight: "30px",
  },
  "& .selectDataLabel": {
    color: colors().black,
    fontWeight: 600,
    fontSize: 14,
  },
  "& .cartProductDetailTotal": {
    color: colors().viewTextColor,
    fontSize: "14px",
    fontWeight: 400,
    whiteSpace: "nowrap" as const,
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  "& .cartProductQuantityContainerSecond": {
    height: "30px",
    borderRadius: "7px",
    background: "#EC4E20",
    color: "#FFF",
    textAlign: "center" as const,
    lineHeight: "30px",
    minWidth: "95px",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .cartProductDetailContainer": {
    display: "flex",
    flexDirection: "column" as const,
    margin: "0 8px 0 0",
    alignSelf: "baseline",
  },
  "& .mainCartDetailConatiner": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
  },
  "& .cartLeftSection": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  "& .cartRightSection": {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  "& .cartProductDetailContainerMain": {
    flex: "2",
    padding: "10px 0px",
  },
  "& .clearAllBtnContainer": {
    display: "flex",
    justifyContent: "flex-end",
  },
  "& .greyPoint": {
    backgroundColor: "#DEDEDE",
    width: 6,
    height: 6,
    borderRadius: "50%",
    marginLeft: 8,
    marginRight: 8,
  },
  "& .cartProductImgWrapperMain": {
    maxWidth: "70px",
    flex: "0 0 70px",
    padding: "10px 8px",
  },
  "& .cartProductDetailTitle": {
    display: "flex",
    alignItems: "center",
    marginRight: "12px",
  },
  "& .productNameTitle": {
    color: colors().darkjunglegreen,
    fontSize: "14px",
    fontWeight: 600,
    whiteSpace: "nowrap" as const,
  },
  "& .upchargeTitle": {
    color: colors().darkliver,
    fontSize: "12px",
    fontWeight: 500,
    whiteSpace: "nowrap" as const,
  },
  "& .dateSelectWrapper": {
    padding: "16px 0",
    display: "flex",
    gap: "16px",
    "@media only screen and (max-width:1024px)": {
      flexDirection: "column",
    },
  },
  "& .cartProductImg": {
    width: "100%",
    height: "100%",
  },
  "& .cartProductImgWrapper": {
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #ECECEC",
    height: "54px",
  },
  "& .cartProductContainer": {
    display: "flex",
    alignItems: "center",
    marginBottom: "24px",
  },
  "& .clearAllBtn": {
    color: "#ec4e20",
    borderBottom: "1px solid #ec4e20",
    cursor: "pointer",
    marginTop: 16,
    fontWeight: 600,
  },
  "& .cartServiceTitle": {
    color: colors().cyancobaltblue,
    fontSize: "14px",
    fontWeight: 600,
    lineheight: "18px",
    marginTop: "24px",
  },
  "& .emptyCartWrapper": {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
  },
  "& .cartProductsContainer": {
    overflowY: "auto" as const,
    maxHeight: 540,
  },
  "& .addProductBtnText": {
    color: "#204B9C",
    fontSize: 13,
    fontWeight: 600,
    marginTop: 8,
    "@media only screen and (max-width: 1024px)": {
      fontSize: 10,
    },
  },
  "& .cartTotalList": {
    display: "flex",
    width: "100%",
    marginBottom: "16px",
  },
  "& .addProductBtnPlus": {
    fontSize: 40,
    color: "#204B9C",
    fontWeight: 400,
  },
  "& .addProductBtnPlusContainer": {
    width: 48,
    height: 48,
    display: "flex",
    background: "#D4DDEC",
    justifyContent: "center",
    borderRadius: 8,
    alignItems: "center",
  },
  "& cartTotalListWrapper": {
    marginBottom: "20px",
  },
  "& .productsPage": {
    paddingRight: "32px",
    paddingTop: "56px",
    paddingLeft: "48px",
  },
  "& .dialogProducts": {
    display: "flex",
    flexWrap: "wrap" as const,
    gap: "18px",
    justifyContent: "space-evenly" as const,
  },
  "& .productsListContainer": {
    display: "grid",
    gridGap: "32px",
    gridTemplateColumns:
      "minmax(192px, 1fr) minmax(192px, 1fr) minmax(192px, 1fr)",
    "@media only screen and (min-width:1024px)": {
      gridGap: "16px",
      gridTemplateColumns: "minmax(128px, 1fr) minmax(128px, 1fr)",
    },
    "@media only screen and (min-width:1280px)": {
      gridGap: "16px",
      gridTemplateColumns: "minmax(128px, 1fr) minmax(128px, 1fr)",
    },
    "@media only screen and (max-width:800px)": {
      gridGap: "16px",
      gridTemplateColumns: "minmax(128px, 1fr) minmax(128px, 1fr)",
    },
    "@media only screen and (min-width:1400px)": {
      gridGap: "24px",
      gridTemplateColumns:
        "minmax(128px, 1fr) minmax(128px, 1fr) minmax(128px, 1fr) minmax(128px, 1fr)",
    },
  },
  "& .addProductBtnContainer": {
    height: "179px",
    width: "105px",
    borderRadius: 8,
    background: "#E8EDF5",
    display: "flex" as const,
    flexDirection: "column" as const,
    justifyContent: "center" as const,
    alignItems: "center",
    cursor: "pointer",
    "@media only screen and (max-width: 1280px)": {
      width: "95px",
    },
    "@media only screen and (max-width: 1024px)": {
      width: "75px",
    },
  },
  "& .ftWeight400": {
    fontWeight: 400,
  },
  "& .ftWeight600": {
    fontWeight: 600,
  },
});

const PaginationContainer = styled("div")({
  display: "flex",
  padding: "32px 0px",
  justifyContent: "flex-end",
});

const CatalogProductGrid = styled("div")({
  display: "flex",
  gap: "4px",
  flexWrap: "wrap",
  width: "100%",
  "&.sub-product-grid": {
    gap: "8px",
    justifyContent: "center",
  },
  "@media only screen and (max-width:1024px)": {
    gap: "2px",
  },
});

const CatalogContainer = styled("div")({
  display: "flex",
  gap: "32px",
  "@media only screen and (max-width:1280px)": {
    gap: "16px",
  },
  "@media only screen and (max-width:800px)": {
    flexDirection: "column",
  },
});

const StyledProgress = styled(CircularProgress)({
  "&.MuiCircularProgress-colorPrimary": {
    color: "white",
  },
});

const CatalogCartGrid = styled("div")({
  maxWidth: "452px",
  width: "100%",
  "@media only screen and (min-width:1366px)": {
    maxWidth: "452px",
  },
  "@media only screen and (max-width:1024px)": {
    maxWidth: "420px",
  },
  "@media only screen and (max-width:800px)": {
    maxWidth: "100%",
  },
});

const StyledFormControlLabel = withStyles({
  root: {
    "& .Mui-checked + .MuiFormControlLabel-label": {
      color: "#204B9C",
    },
    "&.for_plant_orders": {
      margin: 0,
      "& > span": {
        padding: 0,
      },
      "& .MuiTypography-root": {
        fontSize: 14,
        paddingLeft: 4,
      },
    },
  },
})(FormControlLabel);

const StyledDivider = withStyles({
  root: {
    background: colors().lightborder,
  },
})(Divider);

const ProductContainer = styled("div")({
  gap: 24,
  display: "flex",
  paddingBottom: 32,
  paddingTop: 24,
  "& .grow": {
    flexGrow: 1,
  },
  "@media only screen and (max-width:1280px)": {
    gap: "16px",
  },
  "@media only screen and (max-width:1024px)": {
    gap: "12px",
  },
  "@media only screen and (max-width:800px)": {
    flexDirection: "column",
  },
});

const ProductListContainer = styled("div")({
  display: "grid",
  gridTemplateColumns:
    "minmax(192px, 1fr) minmax(192px, 1fr) minmax(192px, 1fr)",
  "@media only screen and (min-width:1024px)": {
    gridGap: "12px",
    gridTemplateColumns:
      "minmax(128px, 1fr) minmax(128px, 1fr) minmax(128px, 1fr)",
  },
  "@media only screen and (min-width:1280px)": {
    gridGap: "16px",
    gridTemplateColumns:
      "minmax(128px, 1fr) minmax(128px, 1fr) minmax(128px, 1fr)",
  },
  "@media only screen and (max-width:800px)": {
    gridGap: "16px",
    gridTemplateColumns: "minmax(128px, 1fr) minmax(128px, 1fr)",
  },
  "@media only screen and (min-width:1366px)": {
    gridGap: "24px",
    gridTemplateColumns:
      "minmax(128px, 1fr) minmax(128px, 1fr) minmax(128px, 1fr)",
  },
});

const ProductItemContainer = styled("div")({
  boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
  border: `1px solid ${colors().lightborder}`,
  borderRadius: 8,
  padding: 14,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .image-container": {
    width: "129px",
    height: "129px",
    borderRadius: "8px",
    "@media only screen and (max-width:1160px)": {
      width: "76px",
      height: "76px",
    },
  },
  "& img": {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  },
  "& .heading": {
    fontSize: 18,
    fontWeight: 500,
    marginTop: "14px",
    textAlign: "center",
    color: "#1A1A1A",
    lineHeight: "22px",
    "@media only screen and (max-width:1280px)": {
      fontSize: "16px",
    },
    "@media only screen and (max-width:1024px)": {
      fontSize: "13px",
    },
  },
  "& .actionButtons": {
    gap: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      cursor: "pointer",
    },
    "& .count": {
      fontSize: 14,
    },
  },
});

const ProductCartContainer = styled("div")({
  gap: 16,
  padding: 16,
  borderRadius: 8,
  display: "flex",
  flexFlow: "column",
  border: `1px solid ${colors().lightborder}`,
  "& .cartProductNote": {
    margin: 0,
    fontSize: 14,
  },
  "@media only screen and (max-width: 768px)": {
    minWidth: "unset",
    maxWidth: "unset",
  },
  "@media only screen and (max-width: 1024px)": {
    padding: 12,
  },
});

const StyledSelectPaymentMethod = styled(Select)({
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: colors().lightborder,
  },
  "&.MuiOutlinedInput-root": {
    fontSize: 14,
    borderRadius: 8,
    color: colors().slateGray,
  },
  "& .MuiSelect-root": {
    padding: "10px 8px",
    paddingRight: 32,
  },
});

const PlantOrderSummaryContainer = styled("div")({
  fontSize: 14,
  fontWeight: 500,
  borderRadius: 8,
  padding: "16px 24px",
  color: colors().white,
  backgroundColor: colors().orange,
  "& hr": {
    backgroundColor: colors().white,
    height: 1,
    border: 0,
    marginBottom: 24 - 8,
  },
  "& .flex": {
    gap: 8,
    display: "flex",
    alignItems: "center",
    paddingBottom: 24,
    "& > div:first-child": {
      flexGrow: 1,
    },
  },
  "& .total": {
    fontWeight: 600,
    paddingBottom: 0,
  },
  "& svg": {
    cursor: "pointer",
  },
  "& .caret-icon": {
    marginRight: -6,
  },
});

const StyledAutoComplete = styled(Autocomplete)({
  flexGrow: 1,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    fontSize: 14,
  },
});

const StyledAppBar = withStyles({
  root: {
    color: colors().darkliver,
    margin: "24px 0",
    backgroundColor: colors().white,
    borderBottom: `1px solid ${colors().lightborder}`,
    boxShadow: "none",
    "& .MuiTab-root": {
      textTransform: "none",
      maxWidth: "unset",
      fontSize: 18,
      minWidth:"fit-content",
      "@media only screen and (max-width: 1024px)": {
        fontSize: 16,
      },
    },
    "& .MuiTabs-flexContainer": {
      // justifyContent: "space-between"
    },
    "& .Mui-selected": {
      color: colors().cyancobaltblue,
      fontWeight: 700,
    },
    "& .MuiTabs-indicator": {
      backgroundColor: `${colors().cyancobaltblue} !important`,
    },
    "& .MuiPaper-elevation": {
      boxShadow: "none",
    },
    "& .highlightSection": {
      backgroundColor: "lightgrey"
    }
  },
})(AppBar);

const StyledMainAppBar = withStyles({
  root: {
    margin: 0,
    "& .MuiTab-root": {
      textTransform: "none",
      maxWidth: "unset",
      fontSize: 20,
      marginRight: "30px",
      "@media only screen and (max-width: 1024px)": {
        fontSize: 18,
      },
      "@media only screen and (max-width: 768px)": {
        fontSize: 16,
      },
    },
  },
})(StyledAppBar);

const OrderSummaryContainer = styled("div")({
  gap: 16,
  padding: 16,
  fontSize: 14,
  display: "flex",
  borderRadius: 12,
  flexDirection: "column",
  background: "rgba(32, 75, 156, 0.10)",
  "& .close-icon": {
    cursor: "pointer",
    maxHeight: "25px",
  },
  "& .button-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& .close-icon": {
      marginTop: -1,
    },
    "& .button": {
      fontSize: 14,
      textTransform: "none",
    },
  },
  "& .header": {
    color: "#1A1A1A",
    fontFamily: "Montserrat",
    fontSize: 16,
    fontWeight: 600,
    fontStyle: "normal",
  },
  "& .row": {
    gridGap: 10,
    display: "grid",
    gridTemplateColumns: "128px 1px 1fr",
    "@media only screen and (max-width: 530px)": {
      gridTemplateColumns: "1fr",
      borderBottom: `1px solid ${colors().white}`,
      paddingBottom: 14,
      "& .label": {
        fontWeight: 500,
      },
      "& .dot": {
        display: "none",
      },
    },
  },
  "& .button": {
    background: "rgba(32, 75, 156, 0.10)",
    color: "rgba(32, 75, 156, 1)",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    borderRadius: 8,
  },
});

const StyledDatePicker = styled(DatePicker)({
  "& .MuiInput-root": {
    height: "100%",
  },
  "& .MuiInput-underline::before, & .MuiInput-underline::after": {
    borderBottom: "none !important",
  },
});

const StyledCalenderIcon = styled("img")({
  top: 16,
  right: 12,
  zIndex: 1,
  paddingTop: 10,
  position: "absolute",
  pointerEvents: "none",
});

const StyledOption = styled(Box)({
  "& > img": {
    mr: 2,
    flexShrink: 0,
  },
});

const StyleLable = styled("p")({
  fontSize: "1rem",
  fontFamily: "Montserrat",
  fontWeight: 400,
  "@media only screen and (max-width: 1024px)": {
    fontSize: 13,
  },
});

const PlantCartWrapper = styled("div")({
  maxWidth: "452px",
  width: "100%",
  "@media only screen and (min-width:1366px)": {
    maxWidth: "452px",
  },
  "@media only screen and (max-width:1024px)": {
    maxWidth: "352px",
  },
  "@media only screen and (max-width:800px)": {
    maxWidth: "100%",
  },
});

const SubProductModal = styled(Dialog)({
  "& .MuiDialog-paperWidthMd": {
    maxWidth: "700px",
    width: "100%",
  },
  "& .MuiDialog-paperScrollPaper": {
    borderRadius: "24px",
  },
  "& .subproduct-heading": {
    fontSize: "24px",
    fontWeight: 600,
    color: colors().viewTextColor,
    textAlign: "center",
    margin: "0 0 16px 0",
  },
  "& .dialogActionWrapper": {
    justifyContent: "center",
    paddingTop: "24px",
    paddingBottom: "32px",
  },
});
// Customizable Area End
