// Customizable Area Start
import React from "react";
import {styled, TableContainer, Table, TableHead, TableBody,TableRow,TableCell, Box} from "@material-ui/core";
import DashboardOverviewController, { OrderCountByYear, Props, categoryColumns, configJSON, totalColumns } from "./DashboardOverviewController";
import { colors } from "../../utilities/src/Colors";
import { IFilter } from "../../../components/src/FilterPopover";
import DashboardHeader from "../../../components/src/DashboardHeader";
import PrintSummaryDialog from "../../../components/src/customComponents/PrintSummaryDialog.web";
import CustomColumnChart from "../../../components/src/customComponents/CustomColumnChart.web";
import { HorizontalBarChart, DashboardAreaChart } from "../../../components/src/CustomCharts";
import DashboardTabPanel from "../../../components/src/DashboardTabPanel.web";
import ExportModal from "../../../components/src//ExportModal";
import * as assets from "./assets";
import { getGroupNameDisplay } from "../../cfsalesdashboard3/src/utils";
import { sortCondition } from "../../../blocks/utilities/src/CustomBlockHelpers";
import { storeNameBasedOnGroup } from "./utils";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class DashboardOverview extends DashboardOverviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderLegend = (color_1: string, color_2: string) => (
    <Box display="flex" alignItems="center" gridGap={8} marginTop={1} fontSize={12} lineHeight={1.5}>
      <Box borderRadius={4} style={{backgroundColor: color_1}} width={12} height={12} flexShrink={0} />
      <p>{this.state.todayLabel}</p>
      <Box borderRadius={4} style={{backgroundColor: color_2}} width={12} height={12} flexShrink={0} />
      <p>{this.state.lastYearLabel}</p>
    </Box>
  )

  renderTable = (item: OrderCountByYear) => {
    return (
      <TableWrapper>
        <p className="table-title">
          <span className="table-label">
            <img src={assets.calendarIcon} alt=""/>
            Year - {this.getYear(item.range.endDate)}
          </span>
          <span className="table-range">
            {`${item.range.startDate} to ${item.range.endDate}`}
          </span>
        </p>

        <StyledTable>
          <Table>
            <TableHead>
              <TableRow>
                {
                  categoryColumns.map((column, columnIndex) => (
                    <TableCell key={column.header} width={column.binding === "amount" ? "8%": "5%"}>
                      <p>
                        {column.header}
                      </p>
                    </TableCell>
                  ))
                }
                <TableCell colSpan={7} width="45%">
                  <p>Total</p>
                </TableCell>
                {
                  item.revenue.length > 0 && 
                  (
                    <TableCell colSpan={item.revenue.length}>
                      <p>Revenue</p>
                    </TableCell>
                  )
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                item.categories.map((category, index) => (
                  <TableRow key={index}>
                    {
                      categoryColumns.map((column) => (
                        <TableCell key={column.binding}>
                          <p className="category">{this.formatValue(category[column.binding], column.binding)}</p>
                        </TableCell>
                      ))
                    }
                    {
                      totalColumns.map(column => (
                        <TableCell key={column.binding}>
                          <p className={index ? "dark-color" : "sub-header"}>
                            {
                              index ? 
                              this.formatValue(item.total[column.binding], column.binding) 
                              : column.header
                            }
                          </p>
                        </TableCell>
                      ))
                    }
                    {
                      item.revenue.map(column => (
                        <TableCell key={column.name}>
                          <p className={index ? "dark-color" : "sub-header"}>
                            {
                              index ? 
                              this.formatValue(column.value, ""):
                              `${column.name} (SAR)`
                            }
                          </p>
                        </TableCell>
                      ))
                    }
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </StyledTable>

      </TableWrapper>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { 
      regionName,
      storeId,
      barChartXAxisProps, 
      revenueChartData, 
      overviewChartData, 
      clothes, 
      carpet,
      todayLabel,
      lastYearLabel,
      isLoadingCarpet,
      isLoadingClothes,
      isLoadingOverview,
      isLoadingRevenue,
      groupId
    } = this.state
    const barChartData = [
      {
        data: this.getSectionChartData(clothes),
        dataHeader: [],
        chartTitle: "Clothes",
        color_1: "#A666FF",
        color_2: colors().purple
      },
      {
        data: this.getSectionChartData(carpet),
        dataHeader: [],
        chartTitle: "Carpets",
        color_1: colors().orange,
        color_2: "#F6AE98"
      },
    ]
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <DashboardTabPanel navigation={this.props.navigation} id="DashboardOverview" permissionStatus={this.state.permissionStatus} />
        <StyledWrapper id={sortCondition(this.state.openExportModal, "print-wrapper-overview", undefined) as string}>
          <DashboardHeader
            isAppliedFilter={this.state.isAppliedFilter}
            regionVal={regionName}
            storeVal={storeNameBasedOnGroup(storeId)}
            isGroup={true}
            groupVal={getGroupNameDisplay(groupId)}
            handleFilterButton={this.handleFilterButton}
            handleCloseFilterButton={this.handleCloseFilterButton}
            handleFilterChange={this.handleFilterChange}
            filterAnchor={this.state.filterAnchor}
            filters={this.state.filters as IFilter[]}
            periodDateRange={this.state.periodText}
            dateRange={this.dateRange()}
            elementAfterFilter={
              <div className="switch">
                <button
                  type="button"
                  onClick={() => this.togglePrintDialog()}
                >
                  <img src={assets.printIcon} alt=""/>
                  {configJSON.printSummary}
                </button>
                <button
                  type="button"
                  onClick={() => this.handleOpenExportModal()}
                >
                  {configJSON.exportAll}
                </button>
              </div>
            }
          />
          
          <div className="main-content">
            <div className="chart-section" data-test-id={configJSON.chartSectionTestId}>
              <div className="bar-charts">
                {
                  barChartData.map((chart) => {
                    return <div className="bar-chart">
                        <p className="bar-chart-title">
                          {chart.chartTitle}
                          <span className="sub-title">&nbsp;(&nbsp;{todayLabel} vs. {lastYearLabel}&nbsp;)</span>
                        </p>
                        <HorizontalBarChart 
                          width="100%" 
                          height={80} 
                          data={chart.data} 
                          color_1={chart.color_1} 
                          color_2={chart.color_2} 
                          left={40}
                          xAxisProps={barChartXAxisProps}
                          isLoading={isLoadingCarpet || isLoadingClothes}
                        />
                        {this.renderLegend(chart.color_1, chart.color_2)}
                      </div>
                  })
                }
              </div>
              <div className="area-chart">
                <DashboardAreaChart
                  responsive
                  title={
                    <div data-test-id={configJSON.totalOverviewTitleTestId}>
                      Total Overview
                      <span className="sub-title">&nbsp;(&nbsp;{todayLabel} vs. {lastYearLabel}&nbsp;)</span>
                    </div>
                  }
                  description={configJSON.totalOverviewDesc}
                  items={
                    [
                      {
                        label: todayLabel,
                        color: colors().dueTodayColor
                      },
                      {
                        label: lastYearLabel,
                        color: colors().dueTomorrowColor
                      }
                    ]
                  }
                  data={overviewChartData}
                  tooltipLabel={[`${todayLabel} - SAR`, `${lastYearLabel} - SAR`]}
                  isLoading={isLoadingOverview}
                />
              </div>
              <CustomColumnChart
                title="Revenue"
                description={configJSON.revenueOverviewDesc}
                chartData={revenueChartData}
                barItems={[
                  {
                    label: todayLabel,
                    dataKey: "today",
                    color: colors().cyancobaltblue
                  },
                  {
                    label: lastYearLabel,
                    dataKey: "lastYear",
                    color: colors().dividerColor
                  }
                ]}
                wrapperClassName="revenue-bar-chart"
                isScrollable={revenueChartData.length > 3}
                isLoading={isLoadingRevenue}
              /> 
            </div>
            {
              this.getTableData().map((item, index) => (
                <React.Fragment key={index}>
                  {this.renderTable(item)}
                </React.Fragment>
              ))
            }
          </div>

          <PrintSummaryDialog 
            open={this.state.openPrintSummary} 
            onCancel={this.togglePrintDialog}
            onChangeDate={this.handleGetPrintSummary}
            loading={this.state.loadingReceipt}
            receiptUrl={this.state.receiptUrl} 
            isStoreSelected={Boolean(this.state.regionId && this.state.storeId)}
          />
        </StyledWrapper>
        <ExportModal isOpen={this.state.openExportModal} onCancel={this.handleOpenExportModal} onConfirm={this.handleExportConfirm} />
        </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapper = styled("div")({
  "& .custom-loading": {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 1400,
  },
  "& .circular-container": {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
  "& .headerWrapper": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginTop: 10,
    marginBottom: 12,
    alignItems: "center",
  },
  "& .rangeBox": {
    display: "flex",
    gap: 40,
  },
  "& .PageHeading": {
    fontStyle: "normal",
    fontFamily: "Montserrat",
    fontWeight: 700,
    fontSize: 16,
    lineHeight: 1.5,
    color: colors().darkjunglegreen,
    "@media only screen and (max-width: 1024px)": {
      fontSize: "16px",
    },
  },
  "& .storeval": {
    marginLeft: "50px",
  },
  "& .datePeriod": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: 1.5,
  },
  "& .d-flex": {
    display: "flex",
  },
  "& .flexstyle": {
    flexWrap: "wrap",
    gap: 20
  },
  "& .switch": {
    marginLeft: 18,
    display: "flex",
    gap: 16,
    "& > button": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: 10,
      padding: "10px 16px",
      lineHeight: 1.5,
      border: 0,
      borderRadius: 8,
      background: colors().lightgray,
      color: colors().darkliver,
      fontWeight: 600,
      fontSize:"16px",
      cursor: "pointer",
    }
    
  },
  "& .main-content": {
    paddingBottom: 20,
    marginTop: 16,
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  "& .chart-section": {
    display: "grid",
    gridTemplateColumns: "0.3fr 0.4fr 0.3fr",
    gap: 16,
    "& > div": {
      height: "100%",
      minWidth: 100
    },
    "& .sub-title": {
      fontSize: 12,
      lineHeight: 1.5,
      fontWeight: 400
    }
  },
  "& .bar-charts": {
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    gap:8,
  },
  "& .bar-chart": {
    borderRadius: 12,
    padding: 12,
    minWidth: 100,
    border: `solid 1px ${colors().lightborder}`,
    "& .bar-chart-title": {
      padding: 8,
      fontSize: 19,
      fontWeight: 500,
      lineHeight: "22.8px",
      borderBottom: `solid 1px ${colors().lightborder}`,
    }
  },
  "& .area-chart": {
    "& > div": {
      width: "100%",
      height: "100%",
      borderRadius: 12,
      border: `solid 1px ${colors().lightborder}`,
    },
    "& .chart-wrapper": {
      marginLeft: -12
    },
    "& .headerTitle": {
      paddingBottom: 8
    },
    "& .description": {
      marginBottom: 8
    }
  },
  "&#print-wrapper-overview": {
    "& .switch, .filterButtonAccount": {
      display: "none"
    },
    "& .dark-color, .category, .sub-header": {
      fontSize: "11px",
      color:"black"
    }
  },
  "& .filterButtonAccount": {
    "&.active-border": {
      borderColor: colors().cyancobaltblue,
    },
    "& .filterActiveIcon": {
      top: "-5px",
      minHeight: "15px",
      background: "#204B9C",
      position: "absolute",
      right: "-5px",
      minWidth: "15px",
      borderRadius: "50%"
    },
    cursor: "pointer",
    background: colors().white,
    color: colors().darkliver,
    height: "44px",
    width: "44px",
    position: "relative",
    fontSize: 24,
    borderRadius: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderColor: colors().lightborder,
    border: "1px solid",
    "@media only screen and (max-width: 1024px)": {
      height: 44,
      width: 44,
    }
  },
})

const StyledTable = styled(TableContainer)({
  maxHeight: 440,
  borderRadius: 12,
  backgroundColor: colors().brightgray,
  padding: "12px 0",
  "& > table": {
    borderCollapse: "separate"
  },
  "& .MuiTableCell-root": {
    borderBottom: 0,
    padding: 10,
    color: colors().darkliver,
    fontSize: 14,
    lineHeight: 1.5,
    "&:nth-child(4)": {
      borderRight: `solid 1px ${colors().dividerColor}`
    },
    "&.MuiTableCell-head": {
      fontSize: 13,
      lineHeight: "15.6px",
      fontWeight: 600,
      paddingTop: 0,
      paddingBottom: 12,
      "&:first-child": {
        color: colors().darkjunglegreen,
        fontWeight: 600
      },
      "&:nth-child(5)": {
        borderRight: `solid 1px ${colors().dividerColor}`
      },
    },
    "&.MuiTableCell-body": {
      fontWeight: 500,
      paddingBottom: 0,
      "&:first-child": {
        fontWeight: 600
      },
      "&:nth-child(11)": {
        borderRight: `solid 1px ${colors().dividerColor}`
      },
    },
    "& .sub-header": {
      fontWeight: 400,
      whiteSpace: "nowrap"
    },
    "& .dark-color": {
      color: colors().darkjunglegreen
    }
  },
})

const TableWrapper = styled("div")({
  borderRadius: 12,
  display: "flex",
  flexDirection: "column",
  gap: 16,
  "& .table-title": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `solid 1px ${colors().lightborder}`,
    padding: 12
  },
  "& .table-label": {
    display: "flex",
    gap: 8,
    alignItems: "center",
    fontSize: 19,
    fontWeight: 500,
    lineHeight: 1.5,
  },
})
// Customizable Area End